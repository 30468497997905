import { IconEdit, IconLoader, IconPlus, IconX } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useProductCategoryAPI } from 'src/API/rest.product_category'
import { useProductSubCategoryAPI } from 'src/API/rest.product_sub_category'
import { useProductAPI } from 'src/API/rest.products'
import { SubAttributeData, useSubAttributeAPI } from 'src/API/rest.subattribute'
import { ButtonIconSquare, ButtonRounded } from 'src/components/buttons'
import { InputStandard } from 'src/components/standard.input'
import { ModalStandard, ModalStandardContent, ModalStandardDescription, ModalStandardFooter, ModalStandardHeader, ModalStandardTitle } from 'src/components/standard.modal'
import { SelectStandard } from 'src/components/standard.select'
import { SwitchDemo } from 'src/components/standard.switch'
import { toast } from 'src/shadcn/ui/use-toast'
import AspModal from './component.aspModal'
import { useStatesAPI } from 'src/API/rest.states'
import { MultiselectStandard } from 'src/components/standard.multiselect'
import { MultiselectStandardOnline } from 'src/components/standard.multiselectOnline'





type Props = {

    show?: boolean,
    setShow?: (show: boolean) => void,
    refetchSubAttributes: () => void,
    mode: 'add' | 'edit',
    employeeId?: number | string | null,
    subAttributeId?: number | string | null
}
type FormData = {
    FM14_SubAttributeID: number;
    FM14_M13_ProductID: string | null;
    FM14_M12_ProductSubCategory: string | null;
    FM14_M12_ProductCategory: string | null;
    FM14_SubAttributeCode: string | null;
    FM14_SubAttributeName: string | null;
    FM14_NewStyleFlag: number;
    // FM14_ASP: number | null;
    FM14_MRP: number | null;
    FM14_ABCCategory: string | null;
    FM14_NoOfPC: number | null;
    FM14_Status: 1 | 0;
    FM14_StatusDate: string | null;
    F19_M14_SubAttributeIDRef?: string,
    // FM14_IsActive: 1 | 0
}

export type AspFormData = {
    FM19_PriceID?: number;
    FM19_M04_StateID: string;
    FM19_M14_SubAttributeID?: string;
    FM19_ASP: string;
    FM19_MRP: number | null;
    FM19_status: number;
    FM19_StatusDate?: string | null;
}

export function ModalAddSubAttribute(props: Props) {

    const { register, handleSubmit, setValue, reset, formState: { errors }, control, watch } = useForm<FormData>({
        defaultValues: {
            "FM14_SubAttributeCode": "",
            "FM14_SubAttributeName": "",
            "FM14_M12_ProductSubCategory": null,
            "FM14_M12_ProductCategory": null,
            "FM14_NewStyleFlag": 0,
            // "FM14_ASP": null,
            "FM14_MRP": null,
            "FM14_ABCCategory": "",
            "FM14_NoOfPC": null,
            "FM14_Status": 1,
            "FM14_StatusDate": "",
            FM14_M13_ProductID: null,
            // FM14_IsActive: 1
        }
    });

    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [showAspModal, setShowAspModal] = React.useState(false)


    const refstyle = watch('F19_M14_SubAttributeIDRef')
    const new_style_flag = watch('FM14_NewStyleFlag')
    const product_id = watch('FM14_M13_ProductID')
    const name = watch('FM14_SubAttributeName')
    const is_active = watch('FM14_Status')

    const [searchReferenceStyle, setSearchReferenceStyle] = useState('')

    const {
        createSubAttribute,
        deleteSubAttributeById,
        readSubAttributebyId,
        updateSubAttribute,
        subAttributes,
        isFetching
    } = useSubAttributeAPI({
        trigger: new_style_flag == 1,
        FM14_NewStyleFlag: 0,
        page_size: 50,
        search:searchReferenceStyle
    })

    const {
        isLoading: isLoadingProductsCategory,
        isFetching: isFetcingProductsCategory,
        productCategories
    } = useProductCategoryAPI({
        trigger: props.show
    })



    const {
        states,
        isLoading: isLoadingStates,
        isFetching: isFetchingStates,
        refetchStates

    } = useStatesAPI({
        trigger: props.show,
        isState: 1
    })

    const selectedCategoryId = watch('FM14_M12_ProductCategory')
    const selectedSubCategoryId = watch('FM14_M12_ProductSubCategory')


    const selectedCategory = productCategories?.find((category) => String(category.FM12_ProductCategoryID) == selectedCategoryId)

    const [asmStates, setAsmStates] = React.useState<(AspFormData & { stateName?: string })[]>([])





    const {
        products,
        isLoading: isLoadingProducts,
        isFetching: isFetcingProducts,
        refetchProducts
    } = useProductAPI({
        trigger: props.show,
        page_size: 1000
    })

    useEffect(
        () => {
            if (props.show && props.mode === 'add') {
                reset({
                    "FM14_SubAttributeCode": "",
                    "FM14_SubAttributeName": "",
                    "FM14_M12_ProductSubCategory": '',
                    "FM14_M12_ProductCategory": '',
                    "FM14_NewStyleFlag": 0,
                    // "FM14_ASP": null,
                    "FM14_MRP": null,
                    "FM14_ABCCategory": "",
                    "FM14_NoOfPC": null,
                    "FM14_Status": 1,
                    "FM14_StatusDate": "",
                    // FM14_IsActive: 1,
                    FM14_M13_ProductID: ''
                })
                setValue('FM14_M12_ProductCategory', '')
                setValue('FM14_M13_ProductID', '')
                setValue('FM14_M12_ProductSubCategory', '')



                refetchStates().then((statesData) => {

                    if (statesData.data) {
                        setAsmStates(statesData.data.map((state) => {

                            return {
                                FM19_ASP: '',
                                FM19_M04_StateID: String(state.FM04_StateID),
                                FM19_MRP: null,
                                FM19_status: 1,
                                stateName: state.FM04_StateName
                            }
                        }))
                    }

                })
            } else {
                setShowAspModal(false)
                setIsSubmitting(false)
            }
            if (props.mode === 'edit' && props.subAttributeId && props.show) {
                const loadSubAttribute = async () => {
                    if (!props.subAttributeId) {
                        return
                    }
                    const responseData = await readSubAttributebyId({ subAttributeId: props.subAttributeId })
                    const response = responseData.data
                    if (responseData.success === true) {
                        reset({
                            FM14_M13_ProductID: String(response.FM14_M13_ProductID),
                            FM14_SubAttributeCode: response.FM14_SubAttributeCode,
                            FM14_SubAttributeName: response.FM14_SubAttributeName,
                            FM14_M12_ProductSubCategory: String(response.FM14_M12_ProductSubCategory),
                            FM14_M12_ProductCategory: String(response.FM14_M12_ProductCategory),
                            FM14_NewStyleFlag: response.FM14_NewStyleFlag,
                            // FM14_ASP: response.FM14_ASP,
                            FM14_MRP: response.FM14_MRP,
                            FM14_ABCCategory: response.FM14_ABCCategory,
                            FM14_NoOfPC: response.FM14_NoOfPC,
                            FM14_Status: response.FM14_Status ? 1 : 0,
                            // FM14_IsActive: response.FM14_IsActive,
                            FM14_StatusDate: response.FM14_StatusDate,
                            F19_M14_SubAttributeIDRef: response.reference_style_table?.[0]?.F19_M14_SubAttributeIDRef ? String(response.reference_style_table?.[0]?.F19_M14_SubAttributeIDRef) : undefined
                        })

                        const states = response.ASPs?.map((state) => {
                            return {
                                FM19_ASP: String(state.FM19_ASP),
                                FM19_M04_StateID: String(state.FM19_M04_StateID),
                                FM19_MRP: state.FM19_MRP,
                                FM19_status: 1
                            }
                        }) || []


                        // setAsmStates([...states])
                        setValue('FM14_M12_ProductSubCategory', String(response.FM14_M12_ProductSubCategory))
                        await refetchProducts()
                        setValue('FM14_M13_ProductID', String(response.FM14_M13_ProductID))

                        refetchStates().then((statesData) => {

                            if (statesData.data) {
                                setAsmStates(statesData.data.map((state) => {

                                    const stateAlreadyExists = states.find((stateasm) => String(stateasm.FM19_M04_StateID) === String(state.FM04_StateID))
                                    return {
                                        FM19_ASP: stateAlreadyExists?.FM19_ASP || '',
                                        FM19_M04_StateID: String(state.FM04_StateID),
                                        FM19_MRP: null,
                                        FM19_status: 1,
                                        stateName: state.FM04_StateName
                                    }
                                }))
                            }

                        })

                    } else {
                        toast({
                            description: responseData.message,
                            title: "error",
                            // variant:''
                        })
                    }
                }

                loadSubAttribute()
            }


        }, [props.show]
    )






    const onSubmit = async (data: FormData) => {

        setIsSubmitting(true)
        data.FM14_StatusDate = null
        if (asmStates.find((state) => !state.FM19_ASP)) {
            toast({
                title: "Error",
                description: "Please enter ASP for all states",
            })
            setIsSubmitting(false)
            return;
        }

        if (props.mode === 'add') {
            const response = await createSubAttribute({
                ...data,
                ASPs: asmStates.map((state) => {
                    return {
                        FM19_ASP: Number(state.FM19_ASP),
                        FM19_M04_StateID: Number(state.FM19_M04_StateID)
                    }
                }),
                F19_M14_SubAttributeIDRef: data.F19_M14_SubAttributeIDRef ? Number(data.F19_M14_SubAttributeIDRef) : undefined
            } as SubAttributeData)
            if (response.success === true) {
                toast({
                    description: response.message,
                    // variant:''
                })
                props.setShow?.(false);
                props.refetchSubAttributes()
            } else {
                toast({
                    description: response.message,
                    title: "error",
                    // variant:''
                })
            }
            props.setShow?.(false);
        } else if (props.mode === 'edit' && props.subAttributeId) {
            const response = await updateSubAttribute({
                id: props.subAttributeId,
                subAttribute: {
                    ...data,
                    ASPs: asmStates.map((state) => {
                        return {
                            FM19_ASP: Number(state.FM19_ASP),
                            FM19_M04_StateID: Number(state.FM19_M04_StateID)
                        }
                    }),
                    F19_M14_SubAttributeIDRef: data.F19_M14_SubAttributeIDRef ? Number(data.F19_M14_SubAttributeIDRef) : undefined,
                    FM14_IsActive: data.FM14_Status ? 1 : 0
                } as SubAttributeData
            })
            if (response.success === true) {
                toast({
                    description: response.message,
                    // variant:''
                })
                props.setShow?.(false);
                props.refetchSubAttributes()
            } else {
                toast({
                    description: response.message,
                    title: "error",
                    // variant:''
                })
            }
        }
        setIsSubmitting(false)
    };

    const isAnythingLoading = isLoadingProducts || isFetcingProducts || isLoadingProductsCategory ||
        isFetcingProductsCategory || isFetchingStates || isFetching





    return (
        <>
            <AspModal
                show={showAspModal}
                setShow={setShowAspModal}
                pricelist={asmStates}
                setPricelist={setAsmStates}
            />
            <ModalStandard
                open={props.show}
                onOpenChange={props.setShow}
            >
                <ModalStandardContent onOpenAutoFocus={(e) => e?.preventDefault()} className='bg-vstargray-100'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <ModalStandardHeader >
                            <ModalStandardTitle >{props.mode === 'edit' ? 'Edit Style' : 'Add Style'}</ModalStandardTitle>
                            <ModalStandardDescription>
                                {/* loading indication */}
                                {isAnythingLoading && <div className=' px-2 p-1 rounded-md bg-vstargray-800/30 whitespace-nowrap w-min absolute left-1/2 -translate-x-1/2'>
                                    Updating...
                                </div>}

                                <form onSubmit={handleSubmit(onSubmit)} className=" pb-3">
                                    <div className='grid gap-4 py-4 xs2:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 '>

                                        <div className=" lg:col-span-1 xl:col-span-1">
                                            <InputStandard
                                                placeholder='Style Code'
                                                title='Style Code'
                                                {...register("FM14_SubAttributeCode", { required: "Style is required" })}
                                                error={errors.FM14_SubAttributeCode?.message}

                                                disabled={isAnythingLoading}
                                            />
                                        </div>
                                        <div className=" lg:col-span-1 xl:col-span-1">
                                            <InputStandard
                                                placeholder='Name'
                                                title='Style name'
                                                {...register("FM14_SubAttributeName", { required: "Style name is required" })}
                                                error={errors.FM14_SubAttributeName?.message}

                                                disabled={isAnythingLoading}
                                            />
                                        </div>
                                        <div className=" lg:col-span-1 xl:col-span-1">
                                            <MultiselectStandard
                                                title='Product'
                                                singleSelect
                                                {...register("FM14_M13_ProductID", { required: "Product Id is required" })}
                                                error={errors.FM14_M13_ProductID?.message}
                                                value={product_id ? [product_id] : []}
                                                options={products?.map((product) => ({ value: String(product.FM13_ProductID!), label: `(${product.FM13_ProductCode!})-${product.FM13_ProductDescription!}` })) || []}
                                                placeholder='Product'
                                                onValueChange={(value) => {
                                                    const product = products?.find(product => product.FM13_ProductID == Number(value.target.value))
                                                    setValue('FM14_M13_ProductID', value.target.value?.length ? value.target.value[0] : null)
                                                    setValue('FM14_M12_ProductCategory', product?.FM13_M12_ProductCategory?.id ? String(product?.FM13_M12_ProductCategory?.id) : null)
                                                    setValue('FM14_M12_ProductSubCategory', product?.FM13_M12_ProductSubCategory?.id ? String(product?.FM13_M12_ProductSubCategory?.id) : null)
                                                }}
                                            />
                                        </div>
                                        <div className=" lg:col-span-1 xl:col-span-1">
                                            <SelectStandard
                                                title='Product category'
                                                {...register("FM14_M12_ProductCategory", { required: "Product Id is required" })}
                                                error={errors.FM14_M12_ProductCategory?.message}
                                                value={selectedCategoryId || ''}
                                                options={productCategories?.filter(category => !category.FM12_ProductSubCategory).map((category) => ({ value: String(category.FM12_ProductCategoryID), label: category.FM12_ProductCategory })) || []}
                                                onChange={(value) => {
                                                    // const product  = products?.find(product => product.FM13_ProductID == Number(value.target.value))
                                                    setValue('FM14_M12_ProductCategory', value.target.value)
                                                    //     setValue('FM14_M13_ProductID', null)
                                                }}
                                                placeHolder='Product category'
                                                disabled={true}
                                            />
                                        </div>
                                        <div className=" lg:col-span-1 xl:col-span-1">

                                            <SelectStandard
                                                title='Sub category'

                                                {...register("FM14_M12_ProductSubCategory", { required: "Subcategory Id is required" })}
                                                error={errors.FM14_M12_ProductSubCategory?.message}
                                                value={selectedSubCategoryId || ''}
                                                onChange={(value) => {
                                                    // console.log('sub category inside', value.target)

                                                    // setValue('FM14_M12_ProductSubCategory', value.target.value)
                                                    // setValue('FM14_M13_ProductID', '')
                                                }}
                                                options={productCategories?.map((productCategory) => ({ label: productCategory.FM12_ProductSubCategory!, value: String(productCategory.FM12_ProductCategoryID) })).filter(categoey => categoey.label) || []}
                                                placeHolder='Sub category'
                                                disabled={true}
                                            />
                                        </div>


                                        <div className=" lg:col-span-1 xl:col-span-1">
                                            <InputStandard
                                                placeholder='mrp'
                                                title='MRP'
                                                {...register("FM14_MRP", { required: "MRP is required" })}
                                                error={errors.FM14_MRP?.message}
                                                disabled={isAnythingLoading}
                                            />
                                        </div>
                                        <div className=" lg:col-span-1 xl:col-span-1">
                                            <InputStandard
                                                placeholder='ABC Category'
                                                title='ABC Category'
                                                {...register("FM14_ABCCategory", { required: "ABC Category is required" })}
                                                error={errors.FM14_ABCCategory?.message}
                                                disabled={isAnythingLoading}
                                            />
                                        </div>
                                        <div className=" lg:col-span-1 xl:col-span-1">
                                            <InputStandard
                                                placeholder='No Of PC'
                                                title='No Of PC'
                                                {...register("FM14_NoOfPC", { required: "ABC Category is required" })}
                                                error={errors.FM14_NoOfPC?.message}
                                                disabled={isAnythingLoading}
                                            />
                                        </div>
                                        <div className=" lg:col-span-1 xl:col-span-1 flex items-end">
                                            <ButtonIconSquare variant="secondary" text="Set ASP" onClick={(e) => {
                                                e?.preventDefault();
                                                setShowAspModal(true)
                                            }} />

                                        </div>

                                    </div>

                                    <div className='grid xs2:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 '>
                                        <div className=" flex justify-center items-center lg:col-span-1 xl:col-span-1">
                                            <SwitchDemo
                                                value={new_style_flag == 1 ? true : false}
                                                {...register("FM14_NewStyleFlag")}
                                                onChange={(e) => {
                                                    setValue('FM14_NewStyleFlag', e.target.value ? 1 : 0);
                                                }}
                                                title={'Is new style'}
                                                disabled={isAnythingLoading}
                                            />
                                        </div>
                                        {
                                            new_style_flag == 1 &&
                                            <div className=" lg:col-span-1 xl:col-span-1">

                                                <MultiselectStandardOnline
                                                    title='Reference style'
                                                    {...register("F19_M14_SubAttributeIDRef", {
                                                        validate: (value) => {
                                                            if (!value && new_style_flag == 1) {
                                                                return 'Reference style is required'
                                                            }
                                                            return true
                                                        }
                                                    })}
                                                    singleSelect
                                                    error={errors.F19_M14_SubAttributeIDRef?.message}
                                                    value={refstyle ? [String(refstyle)] : []}
                                                    options={subAttributes?.map((category) => ({ value: String(category.FM14_SubAttributeID), label: `(${category.FM14_SubAttributeCode})-${category.FM14_SubAttributeName}` })) || []}
                                                    onValueChange={(value) => {
                                                        setValue('F19_M14_SubAttributeIDRef', value.target.value.length > 0 ? value.target.value[0] : '');
                                                    }}
                                                    onChangeSearch={(value) => {
                                                        setSearchReferenceStyle(value)
                                                    }}
                                                    placeholder='reference style'
                                                    disabled={isAnythingLoading}
                                                />
                                            </div>
                                        }
                                        <div className=" flex justify-center items-center lg:col-span-1 xl:col-span-1">
                                            <SwitchDemo
                                                value={is_active == 1 ? true : false}
                                                {...register("FM14_Status")}
                                                title={'is_active'}
                                                onChange={(e) => {
                                                    setValue('FM14_Status', e.target.value ? 1 : 0);
                                                }}
                                                disabled={isAnythingLoading}
                                            />
                                        </div>
                                    </div>

                                </form>
                            </ModalStandardDescription>
                        </ModalStandardHeader>
                        <ModalStandardFooter>
                            <ButtonIconSquare
                                variant='white'
                                icon={<IconX />}
                                text='close'
                                onClick={(e) => { e?.preventDefault(); props.setShow?.(false) }}
                                disabled={isSubmitting}
                            />
                            <ButtonIconSquare
                                variant='secondary'
                                icon={isSubmitting ? <IconLoader className=' animate-spin ' /> : props.mode === 'add' ? <IconPlus /> : <IconEdit />}
                                text={props.mode === 'add' ? 'Add' : 'Edit'}
                                type="submit"
                                disabled={isSubmitting}
                            />
                        </ModalStandardFooter>
                    </form>
                </ModalStandardContent>
            </ModalStandard>
        </>
    )
}
