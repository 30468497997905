import { IconLoader, IconSearch, IconX } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useProductCategoryAPI } from 'src/API/rest.product_category'
import { useProductSubCategoryAPI } from 'src/API/rest.product_sub_category'
import { useProductAPI } from 'src/API/rest.products'
import { ButtonIconSquare } from 'src/components/buttons'
import { ModalStandard, ModalStandardContent, ModalStandardDescription, ModalStandardFooter, ModalStandardHeader, ModalStandardTitle } from 'src/components/standard.modal'
import { MultiselectStandard } from 'src/components/standard.multiselect'
import { MultiselectStandardOnline } from 'src/components/standard.multiselectOnline'
import { SelectStandard } from 'src/components/standard.select'

type Props = {
    show?: boolean,
    setShow?: (show: boolean) => void,
    ProductCategory: number[],
    productSubCategory: number[],
    productId: number[],
    status: 'All' | '1' | '0',
    search: (
        {
            productCategory,
            productSubCategory,
            productId,
            status
        }:
            {
                productCategory: number[],
                productSubCategory: number[],
                productId: number[],
                status: 'All' | '1' | '0'

            }
    ) => void
}

export function FilterModal(props: Props) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDownloadingTemplate, setisDownloadingTemplate] = useState(false);
    const [productCategory, setproductCategory] = useState<number[]>([]);
    const [productSubCategory, setproductSubCategory] = useState<number[]>([]);
    const [productId, setproductId] = useState<number[]>([]);
    const [searchProductName, setSearchProductName] = useState('');
    const [unavailableProducts, setUnavailableProducts] = useState<{ label: string, value: string }[]>([])
    const [status, setStatus] = useState<'All' | '1' | '0'>('1');


    useEffect(() => {

        const loadUnavailableProducts = async (newStyles: {
            value: string;
        }[]) => {



            const unavailableProducts = await Promise.all(
                newStyles.map(
                    async (style) => {
                        if (style.value) {
                            const productFetched = await readProductbyId({
                                productId: style.value
                            })
                            if (productFetched.success === true) {
                                return {
                                    label: `(${productFetched.data.FM13_ProductCode}) - ${productFetched.data.FM13_ProductDescription}`!,
                                    value: String(productFetched.data?.FM13_ProductID)
                                }
                            }
                        }

                        return null
                    }
                )
            )

            if (unavailableProducts.length !== 0) {
                setUnavailableProducts(unavailableProducts.filter(s => s) as { label: string, value: string }[])
            }



        }

        if (props.show) {
            setproductCategory(props.ProductCategory);
            setproductSubCategory(props.productSubCategory);
            setproductId(props.productId);
            if (props.productId) {
                loadUnavailableProducts([{
                    value: String(props.productId)
                }])
            }
        }
    }, [props.show]);



    const {
        productCategories,
        isLoading: isLoadingProductCategories,
        isError: isErrorProductCategories,
        isFetching: isFetchingProductCategories
    } = useProductCategoryAPI({
        trigger: props.show,
        page_size: 200
    });
    const {
        productSubCategories,
        isLoading: isLoadingproductSubCategories,
        isError: isErrorproductSubCategories,
        isFetching: isFetchingproductSubCategories
    } = useProductSubCategoryAPI({
        trigger: props.show,
        page_size: 200
    });

    const {
        products,
        readProductbyId,
    } = useProductAPI({
        page_size: 50,
        search: searchProductName
    })

    const realProducts = [...products?.map(product => ({ label: `(${product.FM13_ProductCode}) - ${product.FM13_ProductDescription}`, value: String(product.FM13_ProductID) })) || [], ...unavailableProducts]
    const realProductsUnique = Array.from(new Set(realProducts.map(s => s.value))).map(v => realProducts.find(s => s.value === v)!);


    const isAnythingLoading = false;

    return (
        <ModalStandard
            open={props.show}
        >
            <ModalStandardContent hideCloseButton={true} onOpenAutoFocus={(e) => e?.preventDefault()} className='bg-vstargray-100 w-min'>
                <ModalStandardHeader>
                    <ModalStandardTitle>Filter</ModalStandardTitle>
                    <ModalStandardDescription>
                        {isAnythingLoading && (
                            <div className='px-2 p-1 rounded-md bg-vstargray-800/30 whitespace-nowrap w-min absolute left-1/2 -translate-x-1/2'>
                                Updating...
                            </div>
                        )}
                        <div className='w-full grid grid-cols-1  items-center justify-center'>
                            <MultiselectStandard
                                name='product category'
                                title='product category'
                                options={productCategories?.length ? [...productCategories?.map((state) => ({ value: String(state.FM12_ProductCategoryID), label: state.FM12_ProductCategory })).filter(category => category.label)] : []}
                                placeholder='product category'
                                value={productCategory.map(String)}
                                // singleSelect
                                onValueChange={(e) => {
                                    setproductCategory(e.target.value.map(Number));
                                }}
                            />
                            <MultiselectStandard
                                name='product subcategory'
                                title='product subcategory'
                                options={productSubCategories?.length ? [...productSubCategories?.map((state) => ({ value: String(state.FM12_ProductCategoryID), label: state.FM12_ProductSubCategory })).filter(category => category.label)] : []}
                                placeholder='product subcategory'
                                value={productSubCategory.map(String)}
                                // singleSelect
                                onValueChange={(e) => {
                                    setproductSubCategory(e.target.value.map(Number));
                                }}
                            />
                            <MultiselectStandardOnline
                                name='Product'
                                title='Product'
                                options={realProductsUnique?.length ? [...realProductsUnique?.map((state) => ({ value: String(state.value), label: state.label })).filter(category => category.label)] : []}
                                placeholder='Product'
                                value={productId.map(String)}
                                // singleSelect
                                onValueChange={(e) => {
                                    setproductId(e.target.value.map(Number));
                                }}
                                onChangeSearch={(value) => {
                                    setSearchProductName(value)
                                }}
                            />
                            <SelectStandard
                                name='status'
                                title='Status'
                                options={[
                                    { value: 'All', label: 'All' },
                                    { value: '1', label: 'Active' },
                                    { value: '0', label: 'Inactive' }
                                ]}
                                onChange={(e) => {
                                    setStatus(e.target.value as any);
                                }}
                                value={status}
                            />
                        </div>
                    </ModalStandardDescription>
                </ModalStandardHeader>
                <ModalStandardFooter>
                    <ButtonIconSquare
                        variant='white'
                        icon={<IconX />}
                        text='Close'
                        onClick={(e) => { e?.preventDefault(); props.setShow?.(false); }}
                        disabled={isSubmitting}
                    />
                    <ButtonIconSquare
                        variant='secondary'
                        icon={isSubmitting ? <IconLoader className='animate-spin' /> : <IconSearch />}
                        text='Search'
                        onClick={() => {
                            props.search({
                                productCategory,
                                productSubCategory,
                                productId,
                                status
                            });
                            props.setShow?.(false);
                        }}
                        disabled={isSubmitting}
                    />
                </ModalStandardFooter>
            </ModalStandardContent>
        </ModalStandard>
    );
}
