import { IconEdit, IconLoader2, IconPlus } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { EmployeeDataResponse, useEmployeeAPI, useEmployeeASMsUnderRSMsAPI, useEmployeeSEsUnderASMsAPI } from 'src/API/rest.employee'
import { ForecastLineType, MonthCode } from 'src/API/rest.forecast_regionwise'
import { useLoadStatesFromStateMappingAPI, useStatesAPI } from 'src/API/rest.states'
import { ButtonIconSquare } from 'src/components/buttons'
import { InputStandard } from 'src/components/standard.input'
import { SelectStandard } from 'src/components/standard.select'
import { StandardTable, StandardTableHeader, StandardTableRow, TablePlater } from 'src/components/standard.table'
import { TypographyH2, TypographyH3 } from 'src/components/typography'
import { ModalAddSEAllotment } from './component.modalForecast'
import { ForecastReadType, useForecastAPI } from 'src/API/rest.forecast'
import { useGetLatestCalendarDate } from 'src/API/rest.forecast_calender'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { format, parse } from 'date-fns'
import { MultiselectStandard } from 'src/components/standard.multiselect'
import { useGetSEunderASMDbwiseTotals } from 'src/API/se_allotment'
import { toast } from 'src/shadcn/ui/use-toast'





const headers: StandardTableHeader[] = [
    { label: '', id: 'SINo' },
    { label: 'SE', id: 'SE' },
    { label: 'M1-Total', id: 'M1_Total' },
    { label: 'M1-N', id: 'M1_N' },
    { label: 'M2-Total', id: 'M2_Total' },
    { label: 'M2-N', id: 'M2_N' },
    { label: 'M3-Total', id: 'M3_Total' },
    { label: 'M3-N', id: 'M3_N' },
    { label: 'Total', id: 'Total' },
]




type data = {
    stateId: number | null,
    rsmId: number | null,
    asmId: number | null
}

export function SeAllotmentPage() {
    const [isOpen, setIsOpen] = useState(false)
    const userData = useSelector((state: RootState) => state.user.employeeDetails)
    const [data, setData] = useState<data>({
        stateId: null,
        rsmId: userData.FM03_EmployeeID!,
        asmId: null
    })

    const [showaddSE, setShowaddSE] = useState(false)

    const [mode, setMode] = useState<'add' | 'edit'>('add')
    const [selectedDate, setSelectedDate] = useState(
        ''
    );
    const dates: {
        monthCode: MonthCode;
        month: string;
    }[] = []

    const {
        isErrorlatestCalendarDate,
        isFetchedlatestCalendarDate,
        isFetchinglatestCalendarDate,
        isLoadinglatestCalendarDate,
        latestCalendarDate,
        refetchlatestCalendarDate
    } = useGetLatestCalendarDate({
        trigger: true
    })


    const {
        states,
        isLoading: isLoadingState,
        isError: isErrorState,
        isFetching: isFetchingState
    } = useLoadStatesFromStateMappingAPI({
        trigger: true,
        FM06_M03_EmployeeID: data.rsmId!
    });

    const {
        asms,
        isLoading: isLoadingAsms,
        isFetching: isFetchingAsms,
        isError: isAsmsError,
    } = useEmployeeASMsUnderRSMsAPI({
        trigger: data.stateId !== null,
        rsmId: data.rsmId!,
        stateId: data.stateId!
    })
    const {
        ses,
        isLoading: isLoadingSes,
        isFetching: isFetchingSes,
        isError: isSesError,
    } = useEmployeeSEsUnderASMsAPI({
        trigger: data.asmId ? true : false,
        asmId: data.asmId!,
        stateId: data.stateId!
    })

    const {
        forecastData: forecastLinesFetched,
        isErrorforcast,
        refetchForcastData,
        isFetchingForcastData,
        isLoadingforcast
    } = useForecastAPI({
        FT02_Date: latestCalendarDate?.date,
        FT02_M03_EmployeeIDASM: data.asmId,
        FT02_M03_EmployeeIDRSM: data.rsmId,
        FT02_ForecastLevel: 'SE-Allotment',
        trigger: data.stateId && data.asmId ? true : false,
        FT02_M04_StateID: data.stateId,

    })

    const forecastLines: ForecastReadType = forecastLinesFetched ?
        JSON.parse(JSON.stringify(forecastLinesFetched))
        : {}

    //calculating forecast dates
    const d = latestCalendarDate ? new Date(latestCalendarDate?.date) : new Date();
    for (let i = 0; i < 3; i++) {
        d.setMonth(d.getMonth() + 1);
        dates.push({
            monthCode: 'M' + (i + 1) as MonthCode,
            month: d.toISOString().substring(0, 7)
        });
    }

    const {
        DBwiseTotalUnderASM_SE,
        isFetchingDBwiseTotalUnderASM_SE,
        isErrorDBwiseTotalUnderASM_SE,
        isFetchedDbwiseTotalUnderASM_SE
    } = useGetSEunderASMDbwiseTotals({
        asmId: data.asmId!,
        date: latestCalendarDate?.date!,
        stateId: data.stateId!,
        trigger: data.stateId && data.asmId ? true : false
    })


    const [tableRows, setTableRows] = useState<StandardTableRow[]>([])

    const isAnyError = isErrorState || isAsmsError;
    const isAnyLoading = isLoadingState || isLoadingAsms || isFetchingSes
    const isAnyFething = isFetchingState || isFetchingAsms || isFetchingForcastData

    useEffect(() => {

        let mode: 'add' | 'edit' = 'add'

        if (forecastLines?.ForecastLines?.length) {
            mode = 'edit'
        } else {
            mode = 'add'
        }
        setMode(mode)
        setData({
            stateId: data.stateId,
            rsmId: userData.FM03_EmployeeID!,
            asmId: data.asmId
        })

        if (!isAnyError && !isAnyLoading && mode === 'edit') {
            const tablerows: StandardTableRow[] = ses?.map((se) => {

                let M1_N = 0, M2_N = 0, M3_N = 0

                if (isFetchedDbwiseTotalUnderASM_SE && !isErrorDBwiseTotalUnderASM_SE) {
                    M1_N = DBwiseTotalUnderASM_SE?.data.find(item => item.FT02_M03_EmployeeIDSE == se.FM03_EmployeeID && item.FT02_MonthCode === 'M1')?.FT02_ForecastValue || 0
                    M2_N = DBwiseTotalUnderASM_SE?.data.find(item => item.FT02_M03_EmployeeIDSE == se.FM03_EmployeeID && item.FT02_MonthCode === 'M2')?.FT02_ForecastValue || 0
                    M3_N = DBwiseTotalUnderASM_SE?.data.find(item => item.FT02_M03_EmployeeIDSE == se.FM03_EmployeeID && item.FT02_MonthCode === 'M3')?.FT02_ForecastValue || 0
                    M1_N = Math.round((M1_N + Number.EPSILON) * 100) / 100
                    M2_N = Math.round((M2_N + Number.EPSILON) * 100) / 100
                    M3_N = Math.round((M3_N + Number.EPSILON) * 100) / 100
                }

                if (isErrorDBwiseTotalUnderASM_SE) {
                    toast({
                        description: 'Error in fetching DBwise totals',
                        title: 'Error'
                    })
                }

                const M1_total = forecastLines?.ForecastLines?.find(item => {
                    return item.FT02_M03_EmployeeIDSE == se.FM03_EmployeeID && item.FT02_MonthCode === 'M1' && item.FT02_ForecastLevel === 'SE-Allotment'
                })
                const M2_total = forecastLines?.ForecastLines?.find(item => {
                    return item.FT02_M03_EmployeeIDSE == se.FM03_EmployeeID && item.FT02_MonthCode === 'M2' && item.FT02_ForecastLevel === 'SE-Allotment'
                })
                const M3_total = forecastLines?.ForecastLines?.find(item => {
                    return item.FT02_M03_EmployeeIDSE == se.FM03_EmployeeID && item.FT02_MonthCode === 'M3' && item.FT02_ForecastLevel === 'SE-Allotment'
                })
                if (M1_total) {
                    M1_total.FT02_ForecastValue = M1_total.FT02_ForecastValue ? (M1_total.FT02_ForecastValue / 100000) : 0
                    M1_total.FT02_ForecastValue = M1_total.FT02_ForecastValue ? (M1_total.FT02_ForecastValue + M1_N) : 0
                }
                if (M2_total) {
                    M2_total.FT02_ForecastValue = M2_total.FT02_ForecastValue ? (M2_total.FT02_ForecastValue / 100000) : 0
                    M2_total.FT02_ForecastValue = M2_total.FT02_ForecastValue ? (M2_total.FT02_ForecastValue + M2_N) : 0
                }
                if (M3_total) {
                    M3_total.FT02_ForecastValue = M3_total.FT02_ForecastValue ? (M3_total.FT02_ForecastValue / 100000) : 0
                    M3_total.FT02_ForecastValue = M3_total.FT02_ForecastValue ? (M3_total.FT02_ForecastValue + M3_N) : 0
                }

                const total = (M1_total?.FT02_ForecastValue || 0) + (M2_total?.FT02_ForecastValue || 0) + (M3_total?.FT02_ForecastValue || 0)+M1_N+M2_N+M3_N



                return {
                    id: String(se.FM03_EmployeeID),
                    datas: {
                        SE: {
                            data: se.FM03_Username,
                            id: '7'
                        },
                        M1_Total: {
                            data: M1_total?.FT02_ForecastValue || '',
                            id: '6'
                        },
                        M1_N: {
                            data: M1_N,
                            id: '1'
                        },
                        M2_Total: {
                            data: M2_total?.FT02_ForecastValue || '',
                            id: '2'
                        },
                        M2_N: {
                            data: M2_N,
                            id: '3'
                        },
                        M3_Total: {
                            data: M3_total?.FT02_ForecastValue || '',
                            id: '4'
                        },
                        M3_N: {
                            data: M3_N,
                            id: '5'
                        },
                        Total: {
                            data: total,
                            id: '8'
                        },
                    }
                }

            }) || []

            

            const M1Total = tablerows.reduce((acc, row) => {
                return acc + Number(row.datas.M1_Total.data)
            }, 0)
            const M2Total = tablerows.reduce((acc, row) => {
                return acc + Number(row.datas.M2_Total.data)
            }, 0)
            const M3Total = tablerows.reduce((acc, row) => {
                return acc + Number(row.datas.M3_Total.data)
            },0)
            const M1Ntotal = tablerows.reduce((acc, row) => {
                return acc + Number(row.datas.M1_N.data)
            }, 0)
            const M2Ntotal = tablerows.reduce((acc, row) => {
                return acc + Number(row.datas.M2_N.data)
            }, 0)
            const M3Ntotal = tablerows.reduce((acc, row) => {
                return acc + Number(row.datas.M3_N.data)
            }, 0)
            tablerows.push({
                id: 'total',
                datas: {
                    SE: {
                        data: '',
                        id: '7'
                    },
                    M1_Total: {
                        data: M1Total,
                        id: '6'
                    },
                    M1_N: {
                        data: M1Ntotal,
                        id: '1'
                    },
                    M2_Total: {
                        data: M2Total,
                        id: '2'
                    },
                    M2_N: {
                        data: M2Ntotal,
                        id: '3'
                    },
                    M3_Total: {
                        data: M3Total,
                        id: '4'
                    },
                    M3_N: {
                        data: M3Ntotal,
                        id: '5'
                    },
                    Total: {
                        data: M1Total + M2Total + M3Total+M1Ntotal+M2Ntotal+M3Ntotal,
                        id: '8'
                    },
                }
            })

            setTableRows(tablerows)

        }

        if (mode === 'add' && !isAnyError && !isAnyLoading && !isAnyFething) {
            setTableRows([])
        }

    }, [isFetchingSes, isFetchingForcastData, userData.FM03_EmployeeID, isFetchingDBwiseTotalUnderASM_SE])


    return (
        <div className=' grow  flex flex-col overflow-auto items-center '>


            <ModalAddSEAllotment
                show={showaddSE}
                setShow={setShowaddSE}
                refetchforcast={refetchForcastData}
                selectedDate={latestCalendarDate?.date || ''}
                data={data}
                MonthCode={dates}
            />
            <TypographyH2>SE Allotment</TypographyH2>

            <TablePlater className=' flex flex-col gap-2'>
                <div className='   gap-2  grid grid-cols-2 md:grid-cols-4 '>
                    <SelectStandard
                        options={states?.map(s => ({ label: s.FM04_StateName, value: String(s.FM04_StateID) })) || []}
                        placeHolder='Select State'
                        onChange={(e) => {
                            setData(prev => ({ ...prev, stateId: Number(e.target.value), asmId: null }))

                        }}
                    />
                    <MultiselectStandard
                        options={asms?.map(s => ({ label: `(${s.FM03_EmployeeCode})-${s.FM03_EmployeeName}`, value: String(s.FM03_EmployeeID) })) || []}
                        placeholder='Select ASM'
                        onValueChange={(e) => {
                            setData(prev => ({ ...prev, asmId: Number(e.target.value) }))
                        }}
                        name='ASM'
                        value={data.asmId ? [String(data.asmId)] : []}
                        singleSelect
                    />
                    {/* <InputStandard
                        type='date'
                        value={latestCalendarDate?.date || ''}
                        disabled
                    /> */}
                    <InputStandard
                        // title='Date'
                        type='month'
                        className='w-min'
                        value={latestCalendarDate?.date ? format(parse(latestCalendarDate?.date, 'yyyy-mm-dd', new Date()), 'yyyy-mm') : ''}
                        disabled
                    />
                    <ButtonIconSquare
                        text={mode === 'add' ? 'Add' : 'Edit'}
                        icon={isAnyFething || isAnyLoading ? <IconLoader2 className=' animate-spin' /> : mode === 'add' ? <IconPlus /> : <IconEdit />}
                        variant='secondary'
                        onClick={() => setShowaddSE(true)}
                        disabled={data.stateId === null || data.rsmId === null || data.asmId === null || isAnyFething || isAnyLoading}
                    />
                </div>
                {
                    data.asmId && data.stateId ?
                        <StandardTable
                            headers={headers}
                            // isLoading={isLoadingForecastCalender}
                            // isUpdating={isAnythingLoading}
                            isLoading={isLoadingforcast}
                            rows={tableRows}
                            onRetry={refetchForcastData}
                            isUpdating={isFetchingForcastData}
                        />
                        :
                        <div className=' text-center grow flex justify-center items-center'>
                            <TypographyH3>State and RSM not selected</TypographyH3>
                        </div>
                }
            </TablePlater>



        </div>
    )
}

