import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { EmployeeData } from 'src/API/rest.employee'
type initialData = {
    employeeDetails:Partial<EmployeeData>
}

const initialState:initialData = {
    employeeDetails:{}
}

export const userReducer = createSlice({
  name: 'configuration',
  initialState:initialState,
  reducers: {
    setEmployeeDetails: (state, action: PayloadAction<EmployeeData>) => {
      state.employeeDetails = action.payload
      localStorage.setItem('email', action.payload.FM03_Username)
      return state
    },
  },
})

// Action creators are generated for each case reducer function
export const { setEmployeeDetails } = userReducer.actions

export default userReducer.reducer