import { IconLoader, IconSearch, IconX } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useBranchAPI } from 'src/API/rest.branch'
import { useDepartmentAPI } from 'src/API/rest.department'
import { useDesignationAPI } from 'src/API/rest.designations'
import { ButtonIconSquare } from 'src/components/buttons'
import { ModalStandard, ModalStandardContent, ModalStandardDescription, ModalStandardFooter, ModalStandardHeader, ModalStandardTitle } from 'src/components/standard.modal'
import { MultiselectStandard } from 'src/components/standard.multiselect'

type Props = {
    show?: boolean,
    setShow?: (show: boolean) => void,
    branchId: string[],
    setbranchId: (branchId: string[]) => void,
    departmentId: string[],
    setdepartmentId: (branchId: string[]) => void,
    designationId: string[],
    setdesignationId: (branchId: string[]) => void,
    search:(
        {
            branchId,
            departmentId,
            designationId,
            
        }:
        {
            branchId: string[],
            departmentId: string[],
            designationId: string[],
        }
    )=>void
}

export function FilterModal(props: Props) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDownloadingTemplate, setisDownloadingTemplate] = useState(false);
    const [uplaodProgress, setuplaodProgress] = useState(0);
    const [branchId, setbranchId] = useState<string[]>([]);
    const [departmentId, setdepartmentId] = useState<string[]>([]);
    const [designationId, setdesignationId] = useState<string[]>([]);


    useEffect(() => {
        if (props.show) {
            setbranchId(props.branchId);
            setdepartmentId(props.departmentId);
            setdesignationId(props.designationId);
        }
    }, [props.show]);

    const handleDownloadTemplate = async () => {
        setisDownloadingTemplate(true);
        // Implement your file download logic here
        // After download is complete:
        setisDownloadingTemplate(false);
    };

    const {
        branches,
        isLoading: isLoadingBranch,
        isError: isErrorBranch,
        isFetching: isFetchingBranch
    } = useBranchAPI({
        trigger: props.show,
        page_size: 200
    });

    const {
        departments,
        isLoading: isLoadingDepartment,
        isError: isErrorDepartment,
        isFetching: isFetchingDepartment
    } = useDepartmentAPI({
        trigger: props.show,
        page_size: 200
    });
    const {
        designations,
        isLoading: isLoadingDesignations,
        isError: isErrorDesignations,
        isFetching: isFetchingDesignations
    } = useDesignationAPI({
        trigger: props.show,
        page_size: 200
    });

    const isAnythingLoading = false;
    console.log(departmentId)

    return (
        <ModalStandard
            open={props.show}
        >
            <ModalStandardContent hideCloseButton={true} onOpenAutoFocus={(e) => e?.preventDefault()} className='bg-vstargray-100 !max-w-[300px]'>
                <ModalStandardHeader>
                    <ModalStandardTitle>Filter</ModalStandardTitle>
                    <ModalStandardDescription>
                        {isAnythingLoading && (
                            <div className='px-2 p-1 rounded-md bg-vstargray-800/30 whitespace-nowrap w-min absolute left-1/2 -translate-x-1/2'>
                                Updating...
                            </div>
                        )}
                        <div className='w-full grid grid-cols-1  items-center justify-center'>
                            <MultiselectStandard
                                name='Branch'
                                title='Branch'
                                options={branches?.length ? [...branches?.map((Branch) => ({ value: String(Branch.M02_BranchID), label: Branch.M02_BranchName }))] : []}
                                placeholder='Branch'
                                value={branchId}
                                // singleSelect
                                onValueChange={(e) => {
                                    setbranchId(e.target.value.map(String));
                                }}
                            />
                            <MultiselectStandard
                                name='Department'
                                title='Department'
                                options={departments?.length ? [...departments?.map((state) => ({ value: String(state.M17_Dept_id), label: state.M17_Dept_name }))] : []}
                                placeholder='Department'
                                value={departmentId}
                                // singleSelect
                                onValueChange={(e) => {
                                    setdepartmentId(e.target.value.map(String));
                                }}
                            />
                            <MultiselectStandard
                                name='Designation'
                                title='Designation'
                                options={designations?.length ? [...designations?.map((state) => ({ value: String(state.FM05_DesignationID), label: state.FM05_DesignationName }))] : []}
                                placeholder='Department'
                                value={designationId}
                                // singleSelect
                                onValueChange={(e) => {
                                    setdesignationId(e.target.value.map(String));
                                }}
                            />
                           
                        </div>
                    </ModalStandardDescription>
                </ModalStandardHeader>
                <ModalStandardFooter>
                    <ButtonIconSquare
                        variant='white'
                        icon={<IconX />}
                        text='Close'
                        onClick={(e) => { e?.preventDefault(); props.setShow?.(false); }}
                        disabled={isSubmitting}
                    />
                    <ButtonIconSquare
                        variant='secondary'
                        icon={isSubmitting ? <IconLoader className='animate-spin' /> : <IconSearch />}
                        text='Search'
                        onClick={() => {
                            props.search({
                                branchId,
                                departmentId,
                                designationId                                
                            });
                            props.setShow?.(false);
                        }}
                        disabled={isSubmitting}
                    />
                </ModalStandardFooter>
            </ModalStandardContent>
        </ModalStandard>
    );
}
