import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { AxiosResponseCustom, useRestAPI } from "./axiosAPI"
import { useState } from "react"
import { backendPagination } from "src/types/pagination"
import { AxiosProgressEvent } from "axios"

export type SubAttributeData = {
    FM14_SubAttributeID: number;
    FM14_M13_ProductID: number | null;
    FM14_M12_ProductSubCategory: number | null;
    FM14_M12_ProductCategory: number | null;
    FM14_SubAttributeCode: string | null;
    FM14_SubAttributeName: string | null;
    FM14_NewStyleFlag: number;
    FM14_MRP: number | null;
    FM14_ABCCategory: string | null;
    FM14_NoOfPC: number | null;
    FM14_Status: number;
    FM14_StatusDate: string;
    FM14_IsActive: 1 | 0;
    ASPs: {
        FM19_M04_StateID: number;
        FM19_ASP: number;
    }[];
    F19_M14_SubAttributeIDRef?: number
}

export type SubAttributeDataResponse = {
    FM14_SubAttributeID: number,
    FM14_M13_ProductID: number;
    FM14_M12_ProductSubCategory: number;
    FM14_M12_ProductCategory: number;
    FM14_SubAttributeCode: string;
    FM14_SubAttributeName: string;
    FM14_NewStyleFlag: number;
    FM14_ASP: number;
    FM14_MRP: number;
    FM14_ABCCategory: string;
    FM14_NoOfPC: number;
    FM14_Status: boolean;
    FM14_StatusDate: string;
    FM14_IsActive: 1 | 0;
    ASPs: {
        FM19_M04_StateID: number;
        FM19_ASP: number;
        FM19_MRP: number;
    }[];
    reference_style_table: {
        F19_M14_SubAttributeID: number;
        F19_M14_SubAttributeIDRef: number;
    }[];

}

const QUERY_KEY = 'subattributes'
export function useSubAttributeAPI({
    page_size,
    page,
    retryCount,
    trigger = true,
    FM14_M13_ProductID,
    FM14_M12_ProductCategory,
    FM14_M12_ProductSubCategory,
    FM14_SubAttributeCode,
    FM14_SubAttributeName,
    FM14_NewStyleFlag,
    search,
    FM14_Status
}: {
    page_size?: number,
    page?: number,
    retryCount?: number,
    trigger?: boolean,
    FM14_M13_ProductID?: string[],
    FM14_SubAttributeCode?: string,
    FM14_SubAttributeName?: string,
    FM14_M12_ProductSubCategory?: string[],
    FM14_M12_ProductCategory?: string[],
    FM14_NewStyleFlag?: 1 | 0,
    search?: string,
    FM14_Status?: 1 | 0
}) {

    const api = useRestAPI()
    const [totalSubAttributeCount, setTotalSubAttributeCount] = useState(0)
    const queryclient = useQueryClient()
    // const [deleteRetryCount,setDeleteRetryCount] = useState(0)

    const {
        isLoading,
        isError,
        data: subAttributes,
        refetch: refetchSubAttributes,
        isFetching,
        isFetched,
        error,
        isRefetchError,
        isFetchedAfterMount,
    } = useQuery({
        queryKey: [QUERY_KEY, page_size, page, FM14_M13_ProductID, FM14_M12_ProductCategory, FM14_M12_ProductSubCategory, FM14_SubAttributeCode, FM14_SubAttributeName, FM14_NewStyleFlag, search, FM14_Status],
        queryFn: async () => {
            const response = await api.get<{ data: SubAttributeDataResponse[] } & backendPagination>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/SubAttributeV2-list/`,
                mode: 'protected',
                params: {
                    page_size,
                    page,
                    FM14_M13_ProductID:FM14_M13_ProductID?.length?`[${FM14_M13_ProductID?.join(',')}]`:undefined,
                    FM14_M12_ProductCategory:FM14_M12_ProductCategory?.length?`[${FM14_M12_ProductCategory?.join(',')}]`:undefined,
                    FM14_M12_ProductSubCategory:FM14_M12_ProductSubCategory?.length?`[${FM14_M12_ProductSubCategory?.join(',')}]`:undefined,
                    FM14_SubAttributeCode,
                    FM14_SubAttributeName,
                    FM14_NewStyleFlag,
                    search,
                    FM14_Status
                }
            })
            if (response.success === false) {
                throw response.message
            }

            setTotalSubAttributeCount(response.count)
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger,


    })

    const { mutateAsync: createSubAttribute, isPending: isCreatingSubAttribute, failureCount: createSubAttributeFailureCount } = useMutation({
        mutationFn: async (subAttribute: SubAttributeData) => {
            const response = await api.post<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/SubAttributeV2/`,
                data: subAttribute,
                mode: 'protected'
            })

            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && createSubAttributeFailureCount < 4) {

                throw response
            }

            return response
        },
        retry: 4
    })

    const { mutateAsync: updateSubAttribute, isPending: isUpdatingSubAttribute, failureCount: updateSubAttributeFailuirCount } = useMutation({
        mutationFn: async ({
            id,
            subAttribute
        }: {
            id: string | number,
            subAttribute: SubAttributeData
        }) => {
            const response = await api.put<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/SubAttributeV2-update/${id}/`,
                data: subAttribute,
                mode: 'protected'
            })

            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && updateSubAttributeFailuirCount < 4) {

                throw response
            }

            return response
        }, retry: 4
    })

    const { mutateAsync: deleteSubAttributeById, isPending: isDeletingSubAttribute, failureCount } = useMutation({
        mutationFn: async ({ subAttributeId }: { subAttributeId: string | number }) => {

            const response = await api.delete<{}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/subattributes/${subAttributeId}/`,
                mode: 'protected'
            })

            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && failureCount < 4) {

                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }

            return response
        },
        retry: 4,

    })
    const { mutateAsync: readSubAttributebyId, isPending: isReadingSubAttribute, failureCount: subAttributeReadFailureCount } = useMutation({
        mutationFn: async ({ subAttributeId }: { subAttributeId: string | number }) => {

            const response = await api.get<{ data: SubAttributeDataResponse, success: boolean, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/SubAttributeV2/${subAttributeId}/`,
                mode: 'protected'
            })

            // if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && subAttributeReadFailureCount < 4) {

            //     // setDeleteRetryCount((prev)=>prev+1)
            //     throw response
            // }

            return response
        },
        retry: 4,

    })




    return {
        isLoading,
        isError,
        subAttributes,
        refetchSubAttributes,
        totalSubAttributeCount,
        isFetching,
        isFetched,
        error,
        isRefetchError,
        isFetchedAfterMount,
        isDeletingSubAttribute,
        createSubAttribute,
        deleteSubAttributeById,
        readSubAttributebyId,
        isReadingSubAttribute,
        updateSubAttribute,
        isUpdatingSubAttribute
    }


}


export function PostSubAttributes() {

    const api = useRestAPI()

    const {
        mutateAsync: updateSubAttributeRef,
        isPending: isUpdatingSubAttribute,
        failureCount: subAttributeRefFailureCount,
        data: updateResponse
    } = useMutation({
        mutationFn: async (subAttribute: Array<{
            F19_M14_SubAttributeID: number,
            F19_M14_SubAttributeIDRef: number,
        }>) => {
            const response = await api.put<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/subattribute-ref-update/`,
                data: subAttribute,
                mode: 'protected'
            })

            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && subAttributeRefFailureCount < 4) {

                throw response
            }

            return response
        },
        retry: 4
    })

    const {
        mutateAsync: updateActiveStylesRef,
        isPending: isUpdatingActiveStyle,
        failureCount: ActiveStyleRefFailureCount,
    } = useMutation({
        mutationFn: async ({
            excelFile,
            onUploadProgress
        }: {
            excelFile: File,
            onUploadProgress?: ((progressEvent: AxiosProgressEvent) => void)
        }) => {

            const formData = new FormData();
            formData.append('file', excelFile);
            const response = await api.post<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/subattribute-active-style-upload/`,
                data: formData,
                mode: 'protected',
                onUploadProgress: onUploadProgress
            });

            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && ActiveStyleRefFailureCount < 4) {

                throw response
            }

            return response
        },
        retry: 4
    })

    const { mutateAsync: uploadNPStyleRef, isPending: isUploadingNpStyleRef, failureCount: uploadNpStyleRefFailure, isError: isUploadNpStyleRefError } = useMutation({
        mutationFn: async ({
            excelFile,
            onUploadProgress
        }: {
            excelFile: File,
            onUploadProgress?: ((progressEvent: AxiosProgressEvent) => void)
        }) => {

            const formData = new FormData();
            formData.append('file', excelFile);
            const response = await api.post<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/subattribute-ref-style-upload/`,
                data: formData,
                mode: 'protected',
                onUploadProgress: onUploadProgress
            });

            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && uploadNpStyleRefFailure < 4) {

                throw response
            }

            return response
        },
        retry: 4
    })
    const { mutateAsync: uploadMRPStyleRef, isPending: isUploadingMRPStyleRef, failureCount: uploadMRPStyleRefFailure, isError: isUploadMRPStyleRefError } = useMutation({
        mutationFn: async ({
            excelFile,
            onUploadProgress
        }: {
            excelFile: File,
            onUploadProgress?: ((progressEvent: AxiosProgressEvent) => void)
        }) => {
            const formData = new FormData();
            formData.append('file', excelFile);
            const response = await api.post<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/transactions/subattribute-mrp-upload/`,
                data: formData,
                mode: 'protected',
                onUploadProgress: onUploadProgress
            });

            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && uploadMRPStyleRefFailure < 4) {

                throw response
            }

            return response
        },
        retry: 4
    })

    const { mutateAsync: uploadASPStyleRef, isPending: isUploadingASPStyleRef, failureCount: uploadASPStyleRefFailure, isError: isUploadASPStyleRefError } = useMutation({
        mutationFn: async ({
            excelFile,
            onUploadProgress
        }: {
            excelFile: File,
            onUploadProgress?: ((progressEvent: AxiosProgressEvent) => void)
        }) => {
            const formData = new FormData();
            formData.append('file', excelFile);
            const response = await api.post<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/subattribute-asp-upload/`,
                data: formData,
                mode: 'protected',
                onUploadProgress: onUploadProgress
            });

            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && uploadASPStyleRefFailure < 1) {

                throw response
            }

            return response
        },
        retry: 1
    })

    const { mutateAsync: downloadStyleDatas, isPending: isDownloadingStyleDatas, failureCount: failureStyleDatas, isError: isErrorDownloadingStyleDatas } = useMutation({
        mutationFn: async () => {

            const response = await api.get({
                url: `${process.env.REACT_APP_BACKEND_URL}/excel-upload/subattribute-data-download/`,
                mode: 'protected',
                responseType: 'blob',
                
            })


            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && failureStyleDatas < 4) {

                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }
            if (response.success !== false) {
                const blob = new Blob([response as unknown as BlobPart], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'Styles.xlsx'; // Set the filename

                // Append to the document and trigger the download
                document.body.appendChild(link);
                link.click();

                // Clean up
                document.body.removeChild(link);
                window.URL.revokeObjectURL(link.href);
            }

            return response
        },
        retry: 4,

    })

    return {
        updateSubAttributeRef,
        isUpdatingSubAttribute,
        updateResponse,
        uploadNPStyleRef,
        isUploadingNpStyleRef,
        uploadNpStyleRefFailure,
        isUploadNpStyleRefError,
        updateActiveStylesRef,
        isUpdatingActiveStyle,
        uploadMRPStyleRef,
        uploadASPStyleRef,
        downloadStyleDatas,
        isDownloadingStyleDatas
    }

}