import { IconCloudDown, IconCloudUp, IconLoader, IconLoader2, IconPlus, IconSortDescending, IconTrashFilled, IconX } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { ButtonIconSquare } from 'src/components/buttons'
import { StandardTable, StandardTableHeader, StandardTableRow, TablePlater } from 'src/components/standard.table'
import { Pagination, PaginationKeys } from 'src/types/pagination'
import { ModalAddProduct } from './component.addModal'
import { DropdownMenuDemo, DropdownMenuMain } from './component.dropdownMenu'
import { StandardTablePagination } from '../../../../../components/standard.pagination'
import { useProductAPI, useProductAPIs } from 'src/API/rest.products'
import { toast } from 'src/shadcn/ui/use-toast'
import { StandardAlertDialog } from 'src/components/standard.alert-dialog'
import { ImportExcelModal } from './component.importExcelModal'
import { isAllowedPermission } from 'src/lib/utils'
import { useGetAllowedMenus } from 'src/API/rest.role'
import { useDebounce } from 'use-debounce'
import { InputStandard } from 'src/components/standard.input'
import { FilterModal } from './component.filterModal'


export function ProductPage() {

    const searchParams = new URLSearchParams(window.location.search)
    const currentPageFromUrl = searchParams.get(PaginationKeys.CURRENT_PAGE)
    const dataPerPage = 15
    const [pagination, setPagination] = useState<Pagination>({
        currentPage: currentPageFromUrl ? parseInt(currentPageFromUrl) : 1,
        totalPages: Math.ceil(1 / dataPerPage) || 1,
        dataPerPage: dataPerPage
    })
    const [productsRow, setProductsRow] = useState<StandardTableRow[]>([])
    const [previousDataLength, setPreviousDataLength] = useState(0)
    const [openAddModal, setOpenAddModal] = useState(false)
    const [selectedProductId, setSelectedProductId] = useState<number | null | string>()
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [mode, setMode] = useState<'add' | 'edit'>('add')
    const [openImportModal, setOpenImportModal] = useState(false)
    const [search, setSearch] = useState('')
    const [debouncedInput] = useDebounce(search, 500);
    const [openFilterModal, setOpenFilterModal] = useState(false)
    const [productCategory, setproductCategory] = useState<number[]>([]);
    const [productSubCategory, setproductSubCategory] = useState<number[]>([]);
    const [status, setStatus] = useState<'All' | '1' | '0'>('1')

    const {
        products,
        error,
        isError,
        isFetched,
        isFetching,
        isLoading,
        totalProductCount,
        isDeleting,
        refetchProducts,
        deleteProductById,

    } = useProductAPI({
        page_size: pagination.dataPerPage,
        page: pagination.currentPage,
        search: debouncedInput,
        FM13_Status: status === 'All' ? undefined : Number(status) as 1 | 0,
        FM13_M12_ProductCategory:productCategory.map(String),
        FM13_M12_ProductSubCategory:productSubCategory.map(String)
    })



    const headers: StandardTableHeader[] = [
        { label: 'Product Code', id: 'product_code', whiteSpace: 'nowrap' },
        { label: 'Product Description', id: 'product_description', whiteSpace: 'nowrap' },
        { label: 'Stock Category', id: 'stock_category', whiteSpace: 'nowrap' },
        { label: 'Sub Category', id: 'product_sub_category', whiteSpace: 'nowrap' },
        { label: 'Category', id: 'Product_category', whiteSpace: 'nowrap', },
        { label: '', id: 'actions', width: '10px' },
    ]

    const {
        downloadProductDatas,
        isDownloadingProductDatas
    } = useProductAPIs()



    const deleteProduct = async () => {

        if (selectedProductId) {
            const response = await deleteProductById({
                productId: selectedProductId
            })
            if (response.success === true) {
                toast({
                    title: 'Success',
                    description: response.message,
                })
                refetchProducts()
                setOpenDeleteModal(false)
            } else {
                toast({
                    title: 'Error',
                    description: response.message,
                })
            }
            setSelectedProductId(null)
        }

    }


    useEffect(
        () => {
            // setting prevouse data length for loading optimization
            if (isFetched && !isFetching) {
                console.log(products?.length)
                setPreviousDataLength(products?.length || 0)
            }

            //if successfully loaded
            if (isFetched && !isError) {
                setPagination({
                    currentPage: currentPageFromUrl ? parseInt(currentPageFromUrl) : 1,
                    totalPages: Math.ceil(totalProductCount / dataPerPage) || 1,
                    dataPerPage: dataPerPage
                })
                setProductsRow(products?.map(
                    (product) => {
                        return ({
                            id: String(product.FM13_ProductID),
                            datas: {
                                product_code: { id: 'product_code', data: product.FM13_ProductCode },
                                product_description: { id: 'product_description', data: product.FM13_ProductDescription },
                                stock_category: { id: 'stock_category', data: product.FM13_StockCategory },
                                new_product_flag: { id: 'new_product_flag', data: product.FM13_NewProductFlag ? 'Yes' : 'No' },
                                status: { id: 'status', data: product.FM13_Status ? 'Active' : 'Inactive' },
                                status_date: { id: 'status_date', data: product.FM13_StatusDate },
                                product_sub_category: { id: 'product_sub_category', data: product?.FM13_M12_ProductSubCategory?.name },
                                Product_category: { id: 'Product_category', data: product.FM13_M12_ProductCategory?.name },
                                actions: {
                                    id: 'actions', data: <DropdownMenuDemo
                                        rowId={String(product.FM13_ProductID)}
                                        onDeleteAction={(rowid) => {
                                            setSelectedProductId(rowid);
                                            setOpenDeleteModal(true);
                                        }}
                                        isAllowedDelete={isAllowedPermission(rolesAllowedMenus?.data, 'deleteProductID') || false}
                                        isAllowedEdit={isAllowedPermission(rolesAllowedMenus?.data, 'editProductID') || false}
                                        onEditAction={(rowid) => {
                                            setSelectedProductId(rowid);
                                            setMode('edit');
                                            setOpenAddModal(true);
                                        }}
                                    />
                                }
                            }
                        })

                    }
                ) || [])
            }
            if (isError && isFetching === false) {
                toast({
                    description: String(error),
                })

            }
        }, [isFetching]
    )

    const {
        rolesAllowedMenus,
        isFetchedGetAllowdMenus,
        isFetchingGetAllowdMenus,
        refetchRolesAllowedMenus
    } = useGetAllowedMenus({
        menuIds: [
            "createProductID",
            "editProductID",
            "deleteProductID"
        ]
    })






    return (
        <div className=' grow  flex flex-col overflow-auto'>
            <StandardAlertDialog
                title="Are you absolutely sure?"
                description="This action cannot be undone. Are you sure you want to delete this product?"
                positiveLabel="Delete"
                negativeLabel="Cancel"
                positiveAction={deleteProduct}
                negativeAction={() => setOpenDeleteModal(false)}
                positiveIcon={isDeleting ? <IconLoader2 size={16} className=" animate-spin" /> : <IconTrashFilled size={16} />}
                negativeIcon={<IconX size={16} />}
                disableNegativeButton={isDeleting}
                disablePositiveButton={isDeleting}
                show={openDeleteModal}
            />
            <ModalAddProduct
                show={openAddModal}
                setShow={setOpenAddModal}
                refetchProducts={refetchProducts}
                mode={mode}
                productId={selectedProductId}
            />
            <ImportExcelModal
                show={openImportModal}
                setShow={setOpenImportModal}
            />
            <FilterModal
                // stateId={stateId}
                // setStateId={setStateId}
                status={status}
                ProductCategory={productCategory}
                productSubCategory={productSubCategory}
                show={openFilterModal}
                setShow={setOpenFilterModal}
                search={(filter) => {
                    setproductCategory(filter.productCategory)
                    setproductSubCategory(filter.productSubCategory)
                    setStatus(filter.status)
                }}
            />
            <TablePlater className=' flex flex-col gap-2'>
                {/* Buttons section */}
                <div className=' flex justify-end w-full gap-4'>
                    <InputStandard onChange={(e) => setSearch(e.target.value)} className=' lg:w-[400px] ' placeholder='Search' />
                    <ButtonIconSquare variant="white" icon={<IconCloudUp />} text="Import" onClick={() => { setOpenImportModal(true) }} />
                    {isAllowedPermission(rolesAllowedMenus?.data, "createProductID") ? <ButtonIconSquare variant="secondary" icon={<IconPlus />} text="Add product" onClick={() => { setMode('add'); setOpenAddModal(true); }} /> : null}
                    <DropdownMenuMain
                        onIconDownloadPressed={() => { downloadProductDatas() }}
                        onFilterButtonPressed={() => setOpenFilterModal(true)}
                    />
                </div>
                <StandardTable
                    headers={headers}
                    rows={productsRow}
                    isLoading={isLoading}
                    rowsPerPrevousePage={previousDataLength}
                    isUpdating={isFetching}
                    isError={isError && isFetching === false}
                    onRetry={() => refetchProducts()}
                />
                <StandardTablePagination currentPage={pagination.currentPage} totalPages={pagination.totalPages} onChangePage={(page) => {
                    setPagination({ ...pagination, currentPage: page })
                }} />

            </TablePlater>
        </div>
    )

}



