import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function  isAllowedPermission(allowedPermissions:string[]|undefined, permission:string) {
  // return true
  return allowedPermissions?.includes(permission)
}
