import { useMutation, useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useRestAPI } from "./axiosAPI"
import { useNavigate } from "react-router-dom"


const QUERY_KEY = 'product'
export function useAuthenticationHook() {

    const api = useRestAPI()
    const navigate = useNavigate()

    const { mutateAsync: requestOTP } = useMutation({
        mutationFn: async ({ username }: { username: string }) => {
            const response = await api.post<{ email: string }, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/account/login/`,
                data: {
                    FM03_Username:username,
                }
            })


            if (response.success === false) {
                return response
            }
            return response
        },
        
    })
    
    const { mutateAsync: verifyOTP } = useMutation({
        mutationFn: async ({ email, otp,userName }: { email: string, otp: number,userName:string }) => {
            const response = await api.post<{ access: string,refresh:string }, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/account/verify-otp/`,
                data: {
                    FM03_MailID:email,
                    otp,
                    FM03_Username:userName
                }
            })


            if (response.success === false) {
                return response
            }
            localStorage.setItem('accessTocken', response.access);
            localStorage.setItem('refreshTocken', response.refresh);
            return response
        }
    })
    const { mutateAsync: logout } = useMutation({
        mutationFn: async () => {
            localStorage.removeItem('accessTocken');
            localStorage.removeItem('refreshTocken');

            navigate('/')

        }
    })

    return {
        requestOTP,
        verifyOTP,
        logout
    }


    // const {data,error,isError,isFetched,isFetching,isLoading,refetch} = useQuery({
    //     queryKey:[ QUERY_KEY],
    //     queryFn:async()=>{
    //         const response = await api.get<any>({
    //             url:'http://localhost:3005/api/products',
    //         })

    //         if(response.success===false){
    //             console.log('api error')
    //             throw response
    //         }
    //         // console.log(response)
    //         return response.products
    //     },
    //     refetchOnWindowFocus:false,
    //     enabled:false
    // })

    // const fetchProduct = async ({
    //     pageNo
    // }:{pageNo:number})=>{

    //     const response = await refetch()
    //     return response;

    // }


}
