import { useMutation, useQuery } from "@tanstack/react-query"
import { useRestAPI } from "./axiosAPI"
import { useState } from "react"
import { backendPagination } from "src/types/pagination"

export type ProductCategoryData = {
    FM12_ProductCategoryID: number,
    FM12_ProductSubCategory: string,
}

const QUERY_KEY = 'product-sub-category'
export function useProductSubCategoryAPI({
    page_size,
    page,
    retryCount,
    trigger = true
}: {
    page_size?: number,
    page?: number,
    retryCount?: number,
    trigger?: boolean
}) {

    const api = useRestAPI()
    const [totalProductCategoryCount, setTotalProductCategoryCount] = useState(0)

    const {
        isLoading,
        isError,
        data: productSubCategories,
        refetch: refetchProductSubCategories,
        isFetching,
        isFetched,
        error
    } = useQuery({
        queryKey: [QUERY_KEY, page_size, page],
        queryFn: async () => {
            const response = await api.get<{ data: ProductCategoryData[] } & backendPagination>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/product-subcategories/`,
                mode: 'protected',
                params: {
                    page_size,
                    page
                }
            })
            if (response.success === false) {
                throw response.message
            }

            setTotalProductCategoryCount(response.count)
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger

    })


    const { mutateAsync: createProductSubCategory } = useMutation({
        mutationFn: async (ProductCategory: ProductCategoryData) => {
            const response = await api.post<{ access: string, refresh: string }, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/product-subcategories/`,
                data: ProductCategory,
                mode: 'protected'
            })


            if (response.success === false) {
                return response
            }
            localStorage.setItem('accessTocken', response.access);
            localStorage.setItem('refreshTocken', response.refresh);
            return response
        }
    })


    return {
        isLoading,
        isError,
        productSubCategories,
        refetchProductSubCategories,
        totalProductCategoryCount,
        isFetching,
        isFetched,
        error,
        createProductSubCategory
    }


}

