import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import {
    CheckIcon,
    XCircle,
    ChevronDown,
    XIcon,
    WandSparkles,
} from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "src/shadcn/ui/popover";
import { Button } from "src/shadcn/ui/button";
import { cn } from "src/lib/utils";
import { Badge } from "src/shadcn/ui/badge";
import { Separator } from "src/shadcn/ui/separator";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
} from "src/shadcn/ui/command";
import { Label } from "src/shadcn/ui/label";


// Define a type for the options that requires label and value, but allows additional properties
type OptionType = {
    label: string;
    value: string;
    [key: string]: any; // This allows any additional properties
};

export const MultiselectStandard = <T extends OptionType>(props: {
    options: T[];
    onValueChange: (props: {
        target: {
            name: string,
            value: string[],
            item: T[]
        }
    }) => void;
    placeholder?: string;
    variant?: "default" | "secondary" | "destructive" | "inverted";
    animation?: number;
    maxCount?: number;
    value?: string[];
    name: string;
    error?: string,
    title?: string,
    disabled?: boolean,
    singleSelect?: boolean; // Add this prop for single select behavior
    onChangeSearch?:(value:string)=>void,
    isLoading?:boolean,
    // New pagination props
    totalOptionCount?: number; // Optional total option count
    onLoadMore?: (newPageCount: number) => void; // Callback for loading more options

}) => {

    const onchange = (value: string[]) => {
        const selectedItems = props.options.filter(option => value.includes(option.value));
        props.onValueChange({
            target: {
                name: props.name,
                value,
                item: selectedItems
            }
        })
    }

    return (
        <div className=" flex flex-col gap-1 ">
            <div className=" flex flex-col gap-2 items-start">
                {props.title ?
                    <Label htmlFor={props.name} className="text-xs font-medium" >
                        {props.title}
                    </Label>
                    : null}
                <MultiSelect
                    options={props.options}
                    onValueChange={onchange}
                    placeholder={props.placeholder}
                    variant={props.variant}
                    animation={props.animation}
                    maxCount={props.maxCount}
                    value={props.value}
                    error={props.error}
                    disabled={props.disabled}
                    singleSelect={props.singleSelect}
                    totalOptionCount={props.totalOptionCount}
                    onLoadMore={props.onLoadMore}
                    isLoading={props.isLoading}
                    
                // defaultValue={['4']}
                />
            </div>
            {props.error ? <p className="text-red-500 text-[10px]  leading-4 text-start">{props.error}</p> : null}
        </div>
    );
};



// src/components/multi-select.tsx






/**
 * Variants for the multi-select component to handle different styles.
 * Uses class-variance-authority (cva) to define different styles based on "variant" prop.
 */
const multiSelectVariants = cva(
    "m-1 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300",
    {
        variants: {
            variant: {
                default:
                    "border-foreground/10 text-foreground bg-card hover:bg-card/80",
                secondary:
                    "border-foreground/10 bg-secondary text-secondary-foreground hover:bg-secondary/80",
                destructive:
                    "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
                inverted: "inverted",
            },
        },
        defaultVariants: {
            variant: "default",
        },
    }
);

/**
 * Props for MultiSelect component
 */
interface MultiSelectProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof multiSelectVariants> {
    options: {
        label: string;
        value: string;
        icon?: React.ComponentType<{ className?: string }>;
    }[];
    onValueChange: (value: string[]) => void;
    defaultValue?: string[];
    error?: string;
    value?: string[];
    placeholder?: string;
    animation?: number;
    maxCount?: number;
    modalPopover?: boolean;
    asChild?: boolean;
    className?: string;
    singleSelect?: boolean; // Add this prop for single select behavior
    onChangeSearch?:(value:string)=>void,
    isLoading?:boolean,
    // New pagination props
    totalOptionCount?: number; // Optional total option count
    onLoadMore?: (newPageCount: number) => void; // Callback for loading more options
}

export const MultiSelect = React.forwardRef<
    HTMLButtonElement,
    MultiSelectProps
>(
    (
        {
            options,
            onValueChange,
            variant,
            defaultValue = [],
            placeholder = "Select options",
            animation = 0,
            maxCount = 3,
            modalPopover = true,
            asChild = false,
            singleSelect = false,
            className,
            totalOptionCount,
            onLoadMore,
            onChangeSearch,
            isLoading,
            ...props
        },
        ref
    ) => {
        const [defaultValues, setDefaultValues] = React.useState<string[]>(defaultValue);
        const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
        const [isAnimating, setIsAnimating] = React.useState(false);
        const [currentOptions, setCurrentOptions] = React.useState(options);
        const [currentPage, setCurrentPage] = React.useState(1);

        let EffectingValue = defaultValues;

        if (props.value) {
            EffectingValue = props.value;
        }

        const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter") {
                setIsPopoverOpen(true);
            } else if (event.key === "Backspace" && !event.currentTarget.value) {
                const newdefaultValues = [...EffectingValue];
                newdefaultValues.pop();
                setDefaultValues(newdefaultValues);
                onValueChange(newdefaultValues);
            }
        };

        const toggleOption = (option: string) => {
            const newdefaultValues = singleSelect
                ? [option]
                : EffectingValue.includes(option)
                ? EffectingValue.filter((value) => value !== option)
                : [...EffectingValue, option];

            setDefaultValues(newdefaultValues);
            onValueChange(newdefaultValues);
        };

        const handleClear = () => {
            setDefaultValues([]);
            onValueChange([]);
        };

        const handleTogglePopover = () => {
            setIsPopoverOpen((prev) => !prev);
        };

        const clearExtraOptions = () => {
            const newdefaultValues = EffectingValue.slice(0, maxCount);
            setDefaultValues(newdefaultValues);
            onValueChange(newdefaultValues);
        };

        const toggleAll = () => {
            if (singleSelect) return;

            if (EffectingValue.length === options.length) {
                handleClear();
            } else {
                const allValues = options.map((option) => option.value);
                setDefaultValues(allValues);
                onValueChange(allValues);
            }
        };

        const loadMore = () => {
            if (onLoadMore) {
                const nextPage = currentPage + 1;
                onLoadMore(nextPage);
                setCurrentPage(nextPage);
            }
        };

        // Update current options based on the new props if necessary
        React.useEffect(() => {
            setCurrentOptions(options);
            setCurrentPage(1); // Reset page when options change
        }, [options]);

        return (
            <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen} modal={modalPopover}>
                <PopoverTrigger asChild>
                    <Button
                        ref={ref}
                        {...props}
                        onClick={handleTogglePopover}
                        className={cn(
                            "flex w-full p-1 rounded-md border min-h-10 h-auto items-center justify-between bg-inherit hover:bg-vstargray-100",
                            className,
                            "bg-white",
                            props.error
                                ? "border-red-500 data-[placeholder]:text-red-400 ring-vstarPrimary-200 focus:ring-vstarPrimary-200"
                                : " border-vstargray-200 ring-vstargray-200 data-[placeholder]:text-vstargray-500"
                        )}
                    >
                        {EffectingValue.length > 0 ? (
                            <div className="flex justify-between items-center w-full">
                                <div className="flex flex-wrap items-center">
                                    {EffectingValue.slice(0, maxCount).map((value) => {
                                        const option = options.find((o) => o.value === value);
                                        return (
                                            <Badge
                                                key={value}
                                                className={cn(
                                                    isAnimating ? "animate-bounce" : "",
                                                    multiSelectVariants({ variant }),
                                                    "bg-vstargray-600 text-vstargray-100"
                                                )}
                                                style={{ animationDuration: `${animation}s` }}
                                            >
                                                {option?.label}
                                                {!singleSelect && (
                                                    <XCircle
                                                        className="ml-2 h-4 w-4 cursor-pointer"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            toggleOption(value);
                                                        }}
                                                    />
                                                )}
                                            </Badge>
                                        );
                                    })}
                                    {EffectingValue.length > maxCount && !singleSelect && (
                                        <Badge
                                            className={cn(
                                                "bg-white text-foreground border-foreground/1 hover:bg-transparent",
                                                isAnimating ? "animate-bounce" : "",
                                                multiSelectVariants({ variant })
                                            )}
                                            style={{ animationDuration: `${animation}s` }}
                                        >
                                            {`+ ${EffectingValue.length - maxCount} more`}
                                            <XCircle
                                                className="ml-2 h-4 w-4 cursor-pointer"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    clearExtraOptions();
                                                }}
                                            />
                                        </Badge>
                                    )}
                                </div>
                                <div className="flex items-center justify-between">
                                    {!singleSelect && (
                                        <>
                                            <XIcon
                                                className="h-4 mx-2 cursor-pointer text-muted-foreground"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleClear();
                                                }}
                                            />
                                            <Separator orientation="vertical" className="flex min-h-6 h-full" />
                                        </>
                                    )}
                                    <ChevronDown className="h-4 mx-2 cursor-pointer text-muted-foreground" />
                                </div>
                            </div>
                        ) : (
                            <div className="flex items-center justify-between w-full mx-auto">
                                <span
                                    className={cn(
                                        "text-sm mx-3 font-normal",
                                        props.error ? "text-red-400" : "text-vstargray-600 "
                                    )}
                                >
                                    {placeholder}
                                </span>
                                <ChevronDown className="h-4 cursor-pointer text-vstargray-600 mx-2" />
                            </div>
                        )}
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0 bg-white" align="start" onEscapeKeyDown={() => setIsPopoverOpen(false)}>
                    <Command onChange={(value:any) => onChangeSearch?.(value.target.value as string)} >
                        <CommandInput placeholder="Search..." onKeyDown={handleInputKeyDown} />
                        <CommandList className=" relative">
                            <CommandEmpty>No results found.</CommandEmpty>
                            <CommandGroup className="max-h-[200px] overflow-auto ">
                                {isLoading&&<div className=" absolute inset-0 bg-vstargray-400/10 z-10 text-center">
                                    <p>Loading...</p>
                                </div>}
                                {!singleSelect && (
                                    <CommandItem key="all" onSelect={toggleAll} className="cursor-pointer">
                                        <div
                                            className={cn(
                                                "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-vstargray-500",
                                                EffectingValue.length === options.length
                                                    ? "bg-primary text-primary-foreground"
                                                    : "opacity-50 [&_svg]:invisible"
                                            )}
                                        >
                                            <CheckIcon className="h-4 w-4 " />
                                        </div>
                                        <span>(Select All)</span>
                                    </CommandItem>
                                )}
                                {currentOptions.map((option) => {
                                    const isSelected = EffectingValue.includes(option.value);
                                    return (
                                        <CommandItem key={option.value} onSelect={() => toggleOption(option.value)} className="cursor-pointer hover:bg-vstargray-200">
                                            <div
                                                className={cn(
                                                    "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-vstargray-500",
                                                    isSelected ? "bg-vstarborder-vstargray-500 text-primary-foreground" : "opacity-50 [&_svg]:invisible"
                                                )}
                                            >
                                                <CheckIcon className="h-4 w-4 " />
                                            </div>
                                            {option.icon && (
                                                <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />
                                            )}
                                            <span>{option.label}</span>
                                        </CommandItem>
                                    );
                                })}
                            </CommandGroup>
                            {/* Load More Button */}
                            {totalOptionCount && currentOptions.length < totalOptionCount && (
                                <div className="flex justify-center">
                                    <button
                                        onClick={loadMore}
                                    >
                                        Load More
                                    </button>
                                </div>
                            )}
                        </CommandList>
                    </Command>
                </PopoverContent>
            </Popover>
        );
    }
);

MultiSelect.displayName = "MultiSelect";
