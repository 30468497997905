import { IconEdit, IconLoader, IconPlus, IconX } from '@tabler/icons-react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDistributorAPI } from 'src/API/rest.distributer'
import { useDistrictAPI } from 'src/API/rest.district'
import { useLocationAPI } from 'src/API/rest.locations'
import { useStatesAPI } from 'src/API/rest.states'
import { useZoneAPI } from 'src/API/rest.zone'
import { ButtonIconSquare } from 'src/components/buttons'
import { InputStandard } from 'src/components/standard.input'
import { ModalStandard, ModalStandardContent, ModalStandardDescription, ModalStandardFooter, ModalStandardHeader, ModalStandardTitle } from 'src/components/standard.modal'
import { MultiselectStandard } from 'src/components/standard.multiselect'
import { SelectStandard } from 'src/components/standard.select'
import { SwitchDemo } from 'src/components/standard.switch'
import { toast } from 'src/shadcn/ui/use-toast'

type Props = {

    show?: boolean,
    setShow?: (show: boolean) => void,
    refetchDistributors: () => void,
    mode: 'add' | 'edit',
    distributorId?: number | string | null
}
type DistributorData = {
    FM11_DistributorID?: number,
    FM11_M04_StateName: string | null,
    FM11_M09_LocationName: string | null,
    FM11_M10_District: string | null,
    FM11_M08_Zone: string | null,
    FM11_DistributorCode: number | null,
    FM11_DistributorName: string | null,
    FM11_Status: number,
    FM11_StatusDate: string | null
}


export function ModalAddDistributor(props: Props) {

    const { register, handleSubmit, setValue, reset, formState: { errors }, control, watch } = useForm<DistributorData>({
        defaultValues: {
            FM11_DistributorCode: null,
            FM11_DistributorName: '',
            FM11_Status: 1,
            FM11_StatusDate: '',
            FM11_M04_StateName: '',
            FM11_M09_LocationName: '',
            FM11_M10_District: '',
            FM11_M08_Zone: ''
        }
    });




    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const {
        readDistributorbyId,
        createDistributor,
        updateDistributor,
        isReadingDistributor
    } = useDistributorAPI({
        trigger: props.show,
        

    })

    const {
        states,
        isLoading: isLoadingStates,
        isError: isErrorStates,
        isFetching: isFetchingStates
    } = useStatesAPI({
        trigger: props.show

    })

    const {
        locations,
        isLoading: isLoadingLocation,
        isError: isErrorLocation,
        isFetching: isFetchingLocation
    } = useLocationAPI({
        trigger: props.show,
        page_size:1000

    })
    const selectedState = watch('FM11_M04_StateName')
    const {
        districts,
        isLoading: isLoadingDistricts,
        isError: isErrorDistricts,
        isFetching: isFetchingDistricts
    } = useDistrictAPI({
        trigger: props.show &&selectedState?true:false,
        FM10_M04_StateID:selectedState?Number(selectedState):undefined,
        page_size: 1000

    })


    const {
        zones,
        isLoading: isZoneLoading,
        isError: isErrorZone,
        isFetching: isFetchingZone
    } = useZoneAPI({
        trigger: props.show,
        page_size: 1000

    })


    useEffect(
        () => {
            console.log(props.show)
            if (props.show && props.mode === 'add') {
                reset({
                    FM11_DistributorCode: null,
                    FM11_DistributorName: '',
                    FM11_Status: 1,
                    FM11_StatusDate: '',
                    FM11_M04_StateName: '',
                    FM11_M09_LocationName: '',
                    FM11_M10_District: '',
                    FM11_M08_Zone: ''
                })
            }
            if (props.mode === 'edit' && props.distributorId && props.show) {
                readDistributorbyId({ distributorId: props.distributorId }).then((response) => {
                    if (response.success === true) {
                        reset({
                            FM11_DistributorCode: response.data.FM11_DistributorCode,
                            FM11_DistributorName: response.data.FM11_DistributorName,
                            FM11_Status: response.data.FM11_Status,
                            FM11_StatusDate: response.data.FM11_StatusDate!,
                            FM11_M04_StateName: response.data?.FM11_M04_StateName?.id?String(response.data?.FM11_M04_StateName?.id):'',
                            FM11_M09_LocationName: response.data?.FM11_M09_LocationName?.id?String(response.data?.FM11_M09_LocationName?.id):'',
                            FM11_M10_District: response.data?.FM11_M10_District?String(response.data?.FM11_M10_District?.id):'',
                            FM11_M08_Zone: response.data?.FM11_M08_Zone?.id?String(response.data.FM11_M08_Zone.id):'',
                        })
                    } else {
                        toast({
                            description: response.message,
                            title: "error",
                            // variant:''
                        })
                    }
                })
            }
        }, [props.show]
    )

    const onSubmit = async (data: DistributorData) => {

        setIsSubmitting(true)
        data.FM11_StatusDate = new Date().toISOString()
        if (props.mode === 'add') {
            const response = await createDistributor(data)
            if (response.success === true) {
                toast({
                    description: response.message,
                    // variant:''
                })
                props.setShow?.(false);
                props.refetchDistributors()
            } else {
                toast({
                    description: response.message,
                    title: "error",
                    // variant:''
                })
            }
        } else if (props.mode === 'edit' && props.distributorId) {
            const response = await updateDistributor({
                id: props.distributorId,
                Distributor: data
            })
            if (response.success === true) {
                toast({
                    description: response.message,
                    // variant:''
                })
                props.setShow?.(false);
                props.refetchDistributors()
            } else {
                toast({
                    description: response.message,
                    title: "error",
                    // variant:''
                })
            }
        }
        setIsSubmitting(false)
    };

    const state_name = watch('FM11_M04_StateName')
    const location_name = watch('FM11_M09_LocationName')
    const district = watch('FM11_M10_District')
    const zone = watch('FM11_M08_Zone')
    const isanythingLoading = isLoadingStates || isFetchingStates || isSubmitting || isReadingDistributor || isLoadingLocation || isFetchingLocation || isLoadingDistricts || isFetchingDistricts || isZoneLoading || isFetchingZone

    return (
        <ModalStandard
            open={props.show}
            onOpenChange={props.setShow}
        >
            <ModalStandardContent onOpenAutoFocus={(e) => e?.preventDefault()} className='bg-vstargray-100'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalStandardHeader >
                        <ModalStandardTitle >{props.mode === 'edit' ? 'Edit Distributor' : 'Add Distributor'}</ModalStandardTitle>
                        <ModalStandardDescription>
                            {/* loading indication */}
                            {isanythingLoading && <div className=' px-2 p-1 rounded-md bg-vstargray-800/30 whitespace-nowrap w-min absolute left-1/2 -translate-x-1/2'>
                                Updating...
                            </div>}
                            <form onSubmit={handleSubmit(onSubmit)} className=" pb-3">
                                <div className='grid gap-4 py-4 xs2:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 '>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <InputStandard
                                            placeholder='code'
                                            title='Distributor Code'
                                            {...register("FM11_DistributorCode", { required: "Distributor code is required" })}
                                            error={errors.FM11_DistributorCode?.message}
                                            disabled={isanythingLoading}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <InputStandard
                                            placeholder='name'
                                            title='Distributor Name'
                                            {...register("FM11_DistributorName", { required: "Distributor name is required" })}
                                            error={errors.FM11_DistributorName?.message}
                                            disabled={isanythingLoading}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <MultiselectStandard
                                            title='State'
                                            {...register("FM11_M04_StateName", { required: "State is required" })}
                                            error={errors.FM11_M04_StateName?.message}
                                            options={states?.map((state) => ({ value: String(state.FM04_StateID), label: state.FM04_StateName })) || []}
                                            placeholder='state'
                                            value={state_name ? [state_name] : []}
                                            disabled={isanythingLoading}
                                            singleSelect
                                            onValueChange={(e)=>{
                                                setValue('FM11_M04_StateName',e.target.value.length?e.target.value[0]:null)
                                                setValue('FM11_M10_District',null)
                                            }}
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <MultiselectStandard

                                            placeholder='district'
                                            title='District'
                                            {...register("FM11_M10_District")}
                                            options={districts?.map((state) => ({ value: String(state.FM10_DistrictID), label: state.FM10_DistrictName })) || []}
                                            error={errors.FM11_M10_District?.message}
                                            disabled={isanythingLoading||watch('FM11_M04_StateName')?false:true}
                                            value={district ? [district] : [] as any}
                                            onValueChange={(e)=>{
                                                setValue('FM11_M10_District',e.target.value.length?e.target.value[0]:null)
                                            }}
                                            singleSelect
                                        />
                                    </div>
                                    <div className=" lg:col-span-1 xl:col-span-1">

                                        <MultiselectStandard
                                            placeholder='location'
                                            title='Location'
                                            {...register("FM11_M09_LocationName", )}
                                            options={locations?.map((state) => ({ value: String(state.FM09_LocationID), label: state.FM09_LocationName })) || []}
                                            error={errors.FM11_M09_LocationName?.message}
                                            disabled={isanythingLoading}
                                            value={location_name ? [location_name] : []}
                                            singleSelect
                                            onValueChange={(e)=>{
                                                setValue('FM11_M09_LocationName',e.target.value.length?e.target.value[0]:null)
                                            }}
                                        />
                                    </div>
                                    
                                    <div className=" lg:col-span-1 xl:col-span-1">
                                        <MultiselectStandard
                                            placeholder='zone'
                                            title='Zone'
                                            {...register("FM11_M08_Zone", )}
                                            options={zones?.map((state) => ({ value: String(state.FM08_ZoneID), label: state.FM08_ZoneName })) || []}
                                            error={errors.FM11_M08_Zone?.message}
                                            disabled={isanythingLoading}
                                            value={zone ? [zone] : []}
                                            singleSelect
                                            onValueChange={(e)=>{
                                                setValue('FM11_M08_Zone',e.target.value.length?e.target.value[0]:null)
                                            }}
                                        />
                                    </div>

                                </div>
                                <div className=' flex flex-col items-center '>
                                    <div className=' flex flex-col gap-2 items-start'>
                                        <SwitchDemo
                                            {...register("FM11_Status")}
                                            title={'Status'}
                                            value={watch('FM11_Status')==1?true:false}
                                            disabled={isanythingLoading}
                                            onChange={(e)=>{
                                                setValue('FM11_Status',e.target.value?1:0)
                                            }}
                                        />


                                    </div>

                                </div>
                            </form>
                        </ModalStandardDescription>
                    </ModalStandardHeader>
                    <ModalStandardFooter>
                        <ButtonIconSquare
                            variant='white'
                            icon={<IconX />}
                            text='close'
                            onClick={(e) => { e?.preventDefault(); props.setShow?.(false) }}
                            disabled={isSubmitting}
                        />
                        <ButtonIconSquare
                            variant='secondary'
                            icon={isSubmitting ? <IconLoader className=' animate-spin ' /> : props.mode === 'add' ? <IconPlus /> : <IconEdit />}
                            text={props.mode === 'add' ? 'Add' : 'Edit'}
                            type="submit"
                            disabled={isSubmitting}
                        />
                    </ModalStandardFooter>
                </form>
            </ModalStandardContent>
        </ModalStandard>
    )
}

