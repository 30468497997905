import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import { useRestAPI } from "./axiosAPI"



export function useEBOForcast({
    retryCount,
}: {
    retryCount?: number,
}) {

    const api = useRestAPI()
    const [totalSubAttributeCount, setTotalSubAttributeCount] = useState(0)
    const [validateProgress, setValidateProgress] = useState(0)
    const [uploadProgress, setuploadProgress] = useState(0)
    const queryclient = useQueryClient()
    // const [deleteRetryCount,setDeleteRetryCount] = useState(0)


    const { mutateAsync: downloadTemplateEBOforecast, isPending: isDownloadingTEmplateExcelforecast, failureCount: downloadTemplateFailureCount, isError: isDownloadExcelForecastTemplateError } = useMutation({
        mutationFn: async ({
            date,
            stateId
        }:{
            date:string,
            stateId:string
        }) => {

            const response = await api.get({
                url: `${process.env.REACT_APP_BACKEND_URL}/transactions/ebo-forecast/v2/template/`,
                mode: 'protected',
                responseType: 'blob',
                params:{
                    date,
                    stateId
                }
            })


            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && downloadTemplateFailureCount < 4) {

                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }
            if (response.success !== false) {
                const blob = new Blob([response as unknown as BlobPart], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'EBO_template.xlsx'; // Set the filename

                // Append to the document and trigger the download
                document.body.appendChild(link);
                link.click();

                // Clean up
                document.body.removeChild(link);
                window.URL.revokeObjectURL(link.href);
            }

            return response
        },
        retry: 4,

    })
    const { mutateAsync: validateEBOforecastExel, isPending: isvalidateingEBOforecastExel, failureCount: validateEBOforecastExelFailureCount, isError: isvalidateEBOforecastExelError } = useMutation({
        mutationFn: async ({ file }: { file: File }) => {

            const formData = new FormData();
            formData.append('file', file);

            const response = await api.post<{
                invalid_product_codes: string[],
                invalid_data: any[],
                invalid_style_codes: string[]
            },{}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/transactions/ebo-forecast/v2/template/validate/`,
                mode: 'protected',
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total!
                    );
                    setValidateProgress(percentCompleted);
                },
                data: formData
            });


            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && validateEBOforecastExelFailureCount < 4) {

                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }


            return response
        },
        retry: 4,

    })

    const { mutateAsync: uploadEBOforecastExel, isPending: isuploadingEBOforecastExel, failureCount: uploadEBOforecastExelFailureCount, isError: isuploadEBOforecastExelError } = useMutation({
        mutationFn: async ({ file, date, state }: { file: File, date: string,state:number }) => {

            const formData = new FormData();
            formData.append('file', file);
            formData.append('date', date);
            formData.append('state', state.toString());
            const response = await api.post({
                url: `${process.env.REACT_APP_BACKEND_URL}/transactions/ebo-forecast/v2/template/upload/`,
                mode: 'protected',
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total!
                    );
                    setuploadProgress(percentCompleted);
                },
                data: formData
            })


            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && uploadEBOforecastExelFailureCount < 4) {

                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }


            return response
        },
        retry: 4,

    })



    return {

        downloadTemplateEBOforecast,
        isDownloadingTEmplateExcelforecast,
        isDownloadExcelForecastTemplateError,
        validateEBOforecastExel,
        isvalidateingEBOforecastExel,
        isvalidateEBOforecastExelError,
        validateProgress,
        uploadEBOforecastExel,
        isuploadingEBOforecastExel,
        uploadProgress
    }


}




