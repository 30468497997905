import { IconLoader, IconSearch, IconX } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useDistrictAPI } from 'src/API/rest.district'
import { useLocationAPI } from 'src/API/rest.locations'
import { useStatesAPI } from 'src/API/rest.states'
import { useZoneAPI } from 'src/API/rest.zone'
import { ButtonIconSquare } from 'src/components/buttons'
import { ModalStandard, ModalStandardContent, ModalStandardDescription, ModalStandardFooter, ModalStandardHeader, ModalStandardTitle } from 'src/components/standard.modal'
import { MultiselectStandard } from 'src/components/standard.multiselect'
import { SelectStandard } from 'src/components/standard.select'

type Props = {
    show?: boolean,
    setShow?: (show: boolean) => void,
    stateId: number[],
    locationName: number[],
    district: number[],
    zone: number[],
    status: 'All'|'1'|'0',
    // setStateId: (stateId: []) => void,
    search:(
        {
            stateId,
            locationName,
            district,
            zone,
            status
        }:
        {
            stateId: number[],
            locationName: number[],
            district: number[],
            zone: number[],
            status: 'All'|'1'|'0'
        }
    )=>void
}

export function FilterModal(props: Props) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDownloadingTemplate, setisDownloadingTemplate] = useState(false);
    const [uplaodProgress, setuplaodProgress] = useState(0);
    const [stateId, setStateId] = useState<number[]>([]);
    const [locationName, setLocationName] = useState<number[]>([]);
    const [district, setDistrict] = useState<number[]>([]);
    const [zone, setZone] = useState<number[]>([]);
    const [status, setStatus] = useState<'All'|'1'|'0'>('1');

    useEffect(() => {
        if (props.show) {
            setStateId(props.stateId);
            setLocationName(props.locationName);
            setDistrict(props.district);
            setZone(props.zone);
            setStatus(props.status);
        }
    }, [props.show]);

    const handleDownloadTemplate = async () => {
        setisDownloadingTemplate(true);
        // Implement your file download logic here
        // After download is complete:
        setisDownloadingTemplate(false);
    };

    const {
        states,
        isLoading: isLoadingStates,
        isError: isErrorStates,
        isFetching: isFetchingStates
    } = useStatesAPI({
        trigger: props.show,
        page_size: 200
    });
    const {
        zones,
        isLoading: isLoadingZones,
        isError: isErrorZones,
        isFetching: isFetchingZones
    } = useZoneAPI({
        trigger: props.show,
        page_size: 200
    });
    
    const {
        districts,
        isLoading: isLoadingDistricts,
        isError: isErrorDistricts,
        isFetching: isFetchingDistricts
    } = useDistrictAPI({
        trigger: props.show,
        page_size: 200
    });
    const {
        locations,
        isLoading: isLoadingLocation,
        isError: isErrorLocation,
        isFetching: isFetchingLocation
    } = useLocationAPI({
        trigger: props.show,
        page_size: 200
    });
    

    const isAnythingLoading = false;

    return (
        <ModalStandard
            open={props.show}
        >
            <ModalStandardContent hideCloseButton={true} onOpenAutoFocus={(e) => e?.preventDefault()} className='bg-vstargray-100 !max-w-[300px]'>
                <ModalStandardHeader>
                    <ModalStandardTitle>Filter</ModalStandardTitle>
                    <ModalStandardDescription>
                        {isAnythingLoading && (
                            <div className='px-2 p-1 rounded-md bg-vstargray-800/30 whitespace-nowrap w-min absolute left-1/2 -translate-x-1/2'>
                                Updating...
                            </div>
                        )}
                        <div className='w-full grid grid-cols-1  items-center justify-center'>
                            <MultiselectStandard
                                name='State'
                                title='State'
                                options={states?.length ? [ ...states?.map((state) => ({ value: String(state.FM04_StateID), label: state.FM04_StateName }))] : []}
                                placeholder='state'
                                value={stateId.map(String)}
                                // singleSelect
                                onValueChange={(e) => {
                                    setStateId(e.target.value.map(Number));
                                }}
                            />
                            <MultiselectStandard
                                name='Location'
                                title='Location'
                                options={locations?.length ? [...locations?.map((state) => ({ value: String(state.FM09_LocationID), label: state.FM09_LocationName }))] : []}
                                placeholder='Location'
                                value={locationName.map(String)}
                                onValueChange={(e) => {
                                    setLocationName(e.target.value.map(Number));
                                }}
                            />
                            <MultiselectStandard
                                name='Disctrict'
                                title='Disctrict'
                                options={districts?.length ? [...districts?.map((state) => ({ value: String(state.FM10_DistrictID), label: state.FM10_DistrictName }))] : []}
                                placeholder='Disctrict'
                                value={district.map(String)}
                                onValueChange={(e) => {
                                    setDistrict(e.target.value.map(Number));
                                }}
                            />
                            <MultiselectStandard
                                name='Zone'
                                title='Zone'
                                options={zones?.length ? [...zones?.map((state) => ({ value: String(state.FM08_ZoneID), label: state.FM08_ZoneName }))] : []}
                                placeholder='Zone'
                                value={zone.map(String)}
                                onValueChange={(e) => {
                                    setZone(e.target.value.map(Number));
                                }}
                            />
                            <SelectStandard
                                name='status'
                                title='Status'
                                options={[
                                    {value:'All',label:'All'},
                                    {value:'1',label:'Active'},
                                    {value:'0',label:'Inactive'}
                                ]}
                                onChange={(e) => {
                                    setStatus(e.target.value as any);
                                }}
                                value={status}
                            />
                        </div>
                    </ModalStandardDescription>
                </ModalStandardHeader>
                <ModalStandardFooter>
                    <ButtonIconSquare
                        variant='white'
                        icon={<IconX />}
                        text='Close'
                        onClick={(e) => { e?.preventDefault(); props.setShow?.(false); }}
                        disabled={isSubmitting}
                    />
                    <ButtonIconSquare
                        variant='secondary'
                        icon={isSubmitting ? <IconLoader className='animate-spin' /> : <IconSearch />}
                        text='Search'
                        onClick={() => {
                            props.search({
                                stateId,
                                locationName,
                                district,
                                zone,
                                status
                            });
                            props.setShow?.(false);
                        }}
                        disabled={isSubmitting}
                    />
                </ModalStandardFooter>
            </ModalStandardContent>
        </ModalStandard>
    );
}
