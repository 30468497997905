import { IconCloudUpload, IconLoader2 } from '@tabler/icons-react'
import { useState } from 'react'
import { MonthCode } from 'src/API/rest.forecast_regionwise'
import { useStatesAPI } from 'src/API/rest.states'
import { ButtonIconSquare } from 'src/components/buttons'
import { FileInputDragDropStandard } from 'src/components/standard.fileDroper'
import { InputStandard } from 'src/components/standard.input'
import { SelectStandard } from 'src/components/standard.select'
import { TypographyH2 } from 'src/components/typography'
import { RefMonthComponent } from './component.refMonth'
import { useGetLatestCalendarDate } from 'src/API/rest.forecast_calender'
import { ErrorBoxStandard } from 'src/components/standard.errorbox'
import { useRestAPI } from 'src/API/axiosAPI'
import { useStylewiseForcast } from 'src/API/rest.stylewiseForcast'
import { ProgressFullScreen } from 'src/components/progressLoading'
import { toast } from 'src/shadcn/ui/use-toast'
import { Input } from 'src/shadcn/ui/input'
import { format, parse } from 'date-fns'


type MonthType = {
    month: string;
    value: string;
}

export default function ForcastStyleWise() {
    const [isOpen, setIsOpen] = useState(false)

    const [stateSelected, setStateSelected] = useState<number | null>(null)
    const [seletedFile, setSelectedFile] = useState<File | null>(null)
    const api = useRestAPI()


    const {
        downloadTemplateStylewise,
        isDownloadTemplateError,
        isDownloadingTEmplateStyleswise,
        isvalidateingstylewiseExel,
        isvalidatestylewiseExelError,
        validatestylewiseExel,
        validateProgress,
        uploadstylewiseExel,
        isuploadingstylewiseExel,
        uploadProgress
    } = useStylewiseForcast({})


    const {
        isErrorlatestCalendarDate,
        isFetchedlatestCalendarDate,
        isFetchinglatestCalendarDate,
        isLoadinglatestCalendarDate,
        latestCalendarDate,
        refetchlatestCalendarDate
    } = useGetLatestCalendarDate({
        trigger: true
    })

    const {
        states,
        isLoading: isLoadingState,
        isError: isErrorState,
        isFetching: isFetchingState
    } = useStatesAPI({
        trigger: true
    });


    console.log(validateProgress)


    if (isErrorlatestCalendarDate && !isFetchinglatestCalendarDate) {
        return (
            <div className=' grow flex flex-col items-center '>
                <ErrorBoxStandard
                    onRetry={refetchlatestCalendarDate}
                />
            </div>
        )
    }

    return (
        <div className=' grow  flex flex-col overflow-auto items-center '>

            <ProgressFullScreen
                progress={validateProgress}
                show={isvalidateingstylewiseExel}
                subTitle='Validating file...'
            />
            <ProgressFullScreen
                progress={uploadProgress}
                show={isuploadingstylewiseExel}
                subTitle='uploading file...'
            />



            {/* Ref Month
            <RefMonthComponent
                forecastMonth={latestCalendarDate?.date || ''}
                isFetchingLatestForecastCalender={isFetchinglatestCalendarDate}
            /> */}
            <div className='  text-center flex text-vstargray-800 dark:text-vstargray-100 py-2'>Download Template {isDownloadingTEmplateStyleswise ? <IconLoader2 size={10} className=' inline animate-spin' /> : <span onClick={() => downloadTemplateStylewise()} className=' underline text-blue-500 cursor-pointer hover:text-blue-700'>here</span>}</div>

            {/* NP style wise */}
            <div className=' grow flex flex-col overflow-hidden bg-white dark:bg-vstargray-700 border rounded-md w-full text-center'>
                <div className=' grow flex flex-col overflow-auto p-3 '>
                    <TypographyH2>NP Style wise</TypographyH2>
                    <div className=' flex gap-2 '>
                        {/* <InputStandard
                            title='Date'
                            placeholder='Date'
                            type='date'
                            disabled
                            value={latestCalendarDate?.date || ''}
                        /> */}
                        <InputStandard
                        title='Date' 
                        type='month' 
                        className='w-min' 
                        value={latestCalendarDate?.date? format(parse(latestCalendarDate?.date, 'yyyy-mm-dd', new Date()), 'yyyy-mm'):''}
                        disabled
                         />
                        <SelectStandard
                            title='State'
                            onChange={(e) => setStateSelected(Number(e.target.value))}
                            value={stateSelected ? String(stateSelected) : ''}
                            placeHolder='Select State'
                            options={states?.map(state => ({ value: String(state.FM04_StateID), label: state.FM04_StateName })) || []}
                        />
                    </div>
                    <div className=' grow flex items-center  w-min mx-auto mt-8'>
                        <FileInputDragDropStandard
                            disabled={stateSelected === null || !latestCalendarDate?.date ? true : false}
                            onDrop={async (file) => {
                                const response = await validatestylewiseExel({
                                    file: file!
                                })
                                if (response.success === false) {
                                    toast({
                                        description: response.message
                                    })
                                } else {
                                    setSelectedFile(file);
                                }
                            }}
                            file={seletedFile}
                            onClear={() => setSelectedFile(null)}
                            accept={{ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] }}
                        />
                    </div>
                    <ButtonIconSquare
                        disabled={seletedFile === null}
                        className=' mx-auto mb-5'
                        icon={isuploadingstylewiseExel ? <IconLoader2 className='animate-spin' /> : <IconCloudUpload />}
                        onClick={async () => {
                            const response = await uploadstylewiseExel({
                                date: latestCalendarDate?.date!,
                                file: seletedFile!,
                                state: stateSelected!
                            })
                            if (response.success === false) {
                                toast({
                                    title: 'Error',
                                    description: response.message
                                })
                            } else {
                                toast({
                                    title: 'Success',
                                    description: response.message
                                })
                                setSelectedFile(null)
                            }
                        }}
                        variant='secondary' text='Submit' />
                </div>
            </div>

        </div>
    )
}

