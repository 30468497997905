import { IconCalendarFilled, IconEdit, IconPlus } from '@tabler/icons-react'
import { format, parse } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useForecastCalenderAPI, useGetLatestCalendarDate } from 'src/API/rest.forecast_calender'
import { ButtonIconSquare } from 'src/components/buttons'
import { ErrorBoxStandard } from 'src/components/standard.errorbox'
import { StandardTable, StandardTableHeader, StandardTableRow } from 'src/components/standard.table'
import { Input } from 'src/shadcn/ui/input'
import { ModalAddForecastModal } from './component.modalForecast'

export default function ForcastCalender() {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedDate, setSelectedDate] = useState(
        '' // Format: YYYY-MM
    );
    const [forcastCalenderRows, setforcastCalenderRows] = useState<StandardTableRow[]>([])
    const headers: StandardTableHeader[] = [
        { label: 'SINo', id: 'SINo' },
        { label: 'Action', id: 'Action' },
        { label: 'TargetDate', id: 'TargetDate' },
        { label: 'Responsibility', id: 'Responsiblity' },
    ]

    const [mode, setMode] = useState<'scheduled' | 'unScheduled'>('unScheduled')

    const {
        isErrorlatestCalendarDate,
        isFetchedlatestCalendarDate,
        isFetchinglatestCalendarDate,
        isLoadinglatestCalendarDate,
        latestCalendarDate,
        refetchlatestCalendarDate
    } = useGetLatestCalendarDate({
        trigger: true
    })

    const {
        isLoading: isLoadingForecastCalender,
        isError: isErrorForecastCalender,
        forcastCalendersList,
        isFetching: isFetchingForecastCalender,
        isFetched: isFetchedForcastCalender,
        refetchforcastCalendersList
    } = useForecastCalenderAPI({
        date: `${selectedDate}-01`,
        trigger: selectedDate ? true : false,
    })


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value)
        setSelectedDate(event.target.value);
        refetchforcastCalendersList()
    };

    const isAnythingLoading = isLoadingForecastCalender || isLoadinglatestCalendarDate
    const isAnythingFetching = isFetchingForecastCalender || isFetchinglatestCalendarDate
    const isAnyError = isErrorForecastCalender || isErrorlatestCalendarDate

    // after fetching data
    useEffect(
        () => {

            if (!isErrorlatestCalendarDate && latestCalendarDate) {
                let selectedDate1

                if (!latestCalendarDate.date) {
                    if (!selectedDate) {
                        setSelectedDate(latestCalendarDate?.date?.substring(0, 7));
                        selectedDate1 = latestCalendarDate?.date?.substring(0, 7)
                    } else {

                        const currentDate = new Date();
                        const formattedDate = currentDate.toISOString().substring(0, 10);
                        setSelectedDate(formattedDate?.substring(0, 7));
                        selectedDate1 = formattedDate?.substring(0, 7)

                    }

                } else {
                    if (!selectedDate) {
                        setSelectedDate(latestCalendarDate?.date?.substring(0, 7));
                        selectedDate1 = latestCalendarDate?.date?.substring(0, 7)
                    } else {

                        setSelectedDate(new Date(selectedDate).toISOString().substring(0, 7));

                    }
                }
            }
            if (!isErrorForecastCalender && isFetchedForcastCalender) {

                if (forcastCalendersList?.some(data => data.CalenderLines?.length > 0)) {
                    setMode('scheduled')
                } else {
                    setMode('unScheduled')
                }

                setforcastCalenderRows(forcastCalendersList?.map((forcastCalender) => {

                    return ({
                        id: forcastCalender.ForecastCalenderMasterID,
                        datas: {
                            SINo: { id: 'SINo', data: forcastCalender.SINo },
                            Action: { id: 'Action', data: forcastCalender.Action },
                            TargetDate: { id: 'TargetDate', data: <div className=' whitespace-nowrap'>{forcastCalender.CalenderLines?.[0]?.TargetDate?format(parse(forcastCalender.CalenderLines?.[0]?.TargetDate, 'yyyy-MM-dd', new Date()), 'dd-MMM-yyyy'):''}</div> },
                            Responsiblity: { id: 'Responsiblity', data: forcastCalender.CalenderLines?.[0]?.Responsibility?.map(person => person?.Employee?.Name).join(', ') }
                        }
                    })

                }) || [])
            }
        }, [isFetchingForecastCalender, isFetchinglatestCalendarDate]
    )

    if (isAnyError && !isAnythingLoading) {
        return <div className=' grow'>
            <ErrorBoxStandard
                onRetry={refetchlatestCalendarDate}
            />
        </div>
    }



    return (
        <div className=' grow relative  flex flex-col overflow-auto items-center'>
            {isAnythingFetching || isAnythingLoading &&
                <div className=' text-vstargray-400 px-2 py-1 border rounded-md bg-vstargray-700/10 absolute left-1/2 -translate-x-1/2'>updating</div>
            }
            <ModalAddForecastModal
                show={isOpen}
                // mode='add'
                refetchforcastCalendersList={refetchforcastCalendersList}
                setShow={(show) => setIsOpen(show)}
                selectedDate={selectedDate}

            />

            {mode === 'scheduled' ?
                <div className=' w-full'>
                    <div className=' gap-1 pb-2 dark:text-vstargray-400 flex  items-center justify-end '>

                        <Input type='month' className='w-min' onChange={handleChange} value={selectedDate} />
                        <ButtonIconSquare disabled={isAnythingLoading || isAnythingFetching} onClick={() => setIsOpen(true)} icon={<IconEdit />} text='Edit' variant='secondary' />

                    </div>
                    <StandardTable
                        headers={headers}
                        isLoading={isLoadingForecastCalender}
                        isUpdating={isAnythingLoading || isAnythingFetching}
                        rows={forcastCalenderRows}
                    />
                </div>
                :
                isAnythingLoading?
                <div className=' text-vstargray-400 px-2 py-1 border rounded-md bg-vstargray-700/10 absolute left-1/2 -translate-x-1/2'>updating</div>
                :
                <div className=' flex flex-col items-center justify-center h-min my-auto'>
                    <IconCalendarFilled className=' dark:text-vstargray-300' size={80} />
                    <p className=' font-bold text-lg dark:text-vstargray-300'>Select Date</p>
                    <div className=' gap-1 p-5 dark:text-vstargray-400 flex flex-col items-center '>
                        <Input disabled={isAnythingLoading || isAnythingFetching} type='month' onChange={handleChange} value={selectedDate} />
                    </div>
                    <div className='  flex flex-col items-center gap-2'>
                        <p className=' font-bold text-2xl !!! dark:text-vstargray-400'>No Forecasts Found</p>
                        <ButtonIconSquare disabled={isAnythingLoading || isAnythingFetching} onClick={() => setIsOpen(true)} variant='secondary' text=' Create Calender' icon={<IconPlus />} />
                    </div>
                </div>
            }
        </div>
    )
}

