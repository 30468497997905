import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthenticationHook } from 'src/API/rest.authentication';
import { ButtonIconSquare, ButtonRounded } from 'src/components/buttons';
import { InputOTPDemo } from 'src/components/inputotp';
import { ToastAction } from 'src/shadcn/ui/toast';
import { toast } from 'src/shadcn/ui/use-toast';

export default function Loginpage() {
  const navigate = useNavigate();
  const { requestOTP, verifyOTP } = useAuthenticationHook()

  const [inputUsername, setInputUsername] = useState(localStorage.getItem('email')||'');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [isLoading, setIsLoading] = useState(false);


  const otpInputRef = useRef<HTMLInputElement>(null); // Create reference for OTP input

  const handleSubmitUsername = async (e: React.FormEvent) => {
    e?.preventDefault();
   
    setIsLoading(true);
    const res = await requestOTP({ username: inputUsername })

    if (res.success === true) {
      
      const emailParts = res.email.split('@');
      const maskedEmail = `***${emailParts[0].slice(-5)}@${emailParts[1]}`;

      toast({
        title: "Success",
        description: `OTP sent to ${maskedEmail}. Please check your email.`,
      });
      setEmail(res.email);
      setIsOtpSent(true);
    } else {
      setIsLoading(false);
      toast({
        title: "Error",
        description: res.message || "User not found. Please try again.",
      });
    }
  };

  const handleSubmitOtp = async (e: React.FormEvent) => {
    e?.preventDefault();

    const response = await verifyOTP({ email, otp: Number(otp),userName:inputUsername });

    if (response.success === true) {

      navigate('/home/dashboard');
    } else {
      toast({
        title: "Invalid OTP",
        description: response.message || "The OTP is invalid. Please try again.",
        action: <ToastAction className="bg-white" altText="Try again" onClick={() => { setOtp('') }}>Try again</ToastAction>,
      });
    }

  };

  // Focus on the OTP input field when the OTP is sent
  useEffect(() => {
    if (isOtpSent && otpInputRef.current) {
      otpInputRef.current.focus();
    }
  }, [isOtpSent]);

  return (
    <div className="md:p-8 bg-gray-200 h-screen relative">
      <div
        className="h-full bg-cover bg-center flex items-center justify-center rounded-md"
        style={{ backgroundImage: `url(/assets/images/loginimg02.png)` }}>
        <div className="text-white text-4xl font-bold w-[90%] h-[90%] items-center rounded-md p-3 flex justify-end">
          <div className="bg-[#ffffff69] w-full md:w-[55%] h-1/2 md:h-full rounded-md pt-3">
            <div className="w-full flex justify-center">
              <img className="h-24" src="https://www.vstar.in/dist/logo-new-tcQ.svg" alt="Logo" />
            </div>
            <div className="flex items-center mt-6 h-[50%]">
              {!isOtpSent ? (
                <form
                  className="w-full text-center flex justify-center items-center flex-col gap-6"
                  onSubmit={handleSubmitUsername}
                >
                  <input
                    className="w-[90%] h-11 bg-[#ffffffb5] text-black p-2 rounded-md text-[20px] font-light tracking-[2px] outline-none placeholder:text-[#44444482]"
                    placeholder="Enter Username"
                    type="text"
                    value={inputUsername}
                    onChange={(e) => setInputUsername(e.target.value)} // Handle username input
                  />
                  <button
                    className="border bg-vstarPrimary-500 hover:bg-[#b03838] dark:text-vstabg-vstarPrimary-200 text-vstargray-100 text-sm transition-all duration-500 p-2 px-11 rounded-md"
                    type="submit"
                  >
                    {isLoading ? 'Loading...' : 'Send OTP'}
                  </button>
                  
                </form>
              ) : (
                <form
                  className="w-full text-center flex justify-center items-center flex-col gap-6"
                  onSubmit={handleSubmitOtp}
                >
                  {/* Using custom OTP input component with ref */}
                  <InputOTPDemo ref={otpInputRef} value={otp} onChange={setOtp} />
                  {/* <button
                    className="bg-red-950 text-[12px] w-32 p-0 rounded-lg text-[14px] hover:bg-[#300e0e] transition-all duration-500"
                    type="submit"
                  >
                    Submit OTP
                  </button> */}
                  <ButtonIconSquare variant='secondary' text='Submit OTP' />
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
