import { IconAlertCircleFilled, IconEdit, IconLoader2 } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { ButtonRounded } from 'src/components/buttons'
import { InputStandard } from 'src/components/standard.input'
import { ModalAddRefMonthModal } from './component.ModalrefMonthEdit'
import { useReferenceMonthAPI } from 'src/API/rest.referenceMonth'
import { toast } from 'src/shadcn/ui/use-toast'
import { useForecastCalenderAPI, useGetLatestCalendarDate } from 'src/API/rest.forecast_calender'
import { format, parse } from 'date-fns'



type MonthType = {
    month: string;
    value: string;
}

type props = {
    forecastMonth:string,
    isFetchingLatestForecastCalender:boolean,
    standardMonths:MonthType[],
    setStandardMonths:React.Dispatch<React.SetStateAction<MonthType[]>>
}

export function RefMonthComponent(props:props) {


    const [mode, setMode] = useState<'add' | 'edit'>('add');



    const {
        isLoading: isLoadingReferenceMonth,
        isError: isErrorReferenceMonth,
        isFetching: isFetchingReferenceMonth,
        isFetched: isFetchedReferenceMonth,
        referenceMonths,
        refetchReferenceMonths
    } = useReferenceMonthAPI({
        forcastMonth: props.forecastMonth || ''
    })



 


    const [editForecastMonthModal, setEditForecastMonthModal] = useState(false)

    const isAnyError =  isErrorReferenceMonth
    const isAnyLoading = isLoadingReferenceMonth 
    const isAnyFetching = isFetchingReferenceMonth 

    useEffect(() => {
        if (!isAnyError
            && !isLoadingReferenceMonth) {
            console.log(referenceMonths)
            if (referenceMonths?.length) {

                props.standardMonths.forEach(item => {

                    const value = referenceMonths?.find(month => month.FT17_month_name === item.month)
                    if (value) {
                        item.value = value.FT17_date
                    }

                })


                props.setStandardMonths([...props.standardMonths])

                setMode('edit')
            } else {
                setMode('add')
            }
            // reset(options)
        }

        if (isErrorReferenceMonth) {
            toast({
                description: 'Error in fetching data',
                title: 'Error',
            })
        }

    }, [isFetchingReferenceMonth]);



    return (
        <div className=' flex flex-col items-start w-full bg-white dark:bg-vstargray-900 p-2  rounded-md '>
            <ModalAddRefMonthModal
                show={editForecastMonthModal}
                setShow={setEditForecastMonthModal}
                refetchlatestCalenderDate={refetchReferenceMonths}
                selectedDate={props.forecastMonth || ''}
            />
            <div className=' flex gap-2 items-center'>
                <p className=' text-vstargray-800 dark:text-vstargray-100 font-bold text-xl pb-3 flex items-center'>NP Forcast Month</p>
            {
                isErrorReferenceMonth&&!isFetchingReferenceMonth?
                <ButtonRounded
                    icon={<IconAlertCircleFilled className=' text-red-500'/>}
                    onClick={refetchReferenceMonths}
                />
                :<ButtonRounded
                icon={
                    (isFetchingReferenceMonth||isLoadingReferenceMonth||props.isFetchingLatestForecastCalender) ?
                        <IconLoader2 className=' animate-spin' /> :
                        <IconEdit />
                }
                onClick={() => setEditForecastMonthModal(true)}
                disabled={isFetchingReferenceMonth||isLoadingReferenceMonth||props.isFetchingLatestForecastCalender}
            />}
            </div>
            <div className="grid grid-cols-3 lg:grid-cols-6 gap-1 text-vstargray-800 dark:text-vstargray-100 ">
                {props.standardMonths.map((title, index) => (
                    <div key={index} className="bg-vstardark-400 p-2 rounded-lg border border-vstargray-500 dark:border-vstargray-300">
                        <div className="text-vstargray-900 dark:text-vstargray-100 font-medium">{title.month}</div>
                        <div className="text-vstargray-800 dark:text-vstargray-300 w-full text-center text-sm">
                            {isLoadingReferenceMonth ? (
                                <div className="h-4 w-full bg-vstargray-300 dark:bg-vstargray-700 rounded-lg animate-pulse"></div>
                            ) : (
                                title.value ?
                                    format(parse(title.value, 'yyyy-MM-dd', new Date()), 'yyyy-MMM')
                                    : 'N/A'
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}


