import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { AxiosResponseCustom, useRestAPI } from "./axiosAPI"
import { useState } from "react"
import { backendPagination } from "src/types/pagination"

export type salesRoleType = 'RSM'|'ASM'|'SE'

export type EmployeeData = {
    FM03_EmployeeID?: number,
    FM03_EmployeeCode: string,
    FM03_EmployeeName: string,
    FM03_EmployeeType: string,
    FM03_M02_BranchID: number | null,
    FM03_M05_Department: number | null,
    FM03_M05_DesignationID: number | null,
    FM03_Mobile: string,
    FM03_MailID: string,
    FM03_Username: string,
    FM03_M03ReportingtoEmployeeID: number | null,
    FM03_AllowLogin: number,
    FM03_ReportSortOrder: number,
    FM03_Status: number,
    FM03_StatusDate: string|null,
    is_active: boolean,
    is_staff: boolean,
    is_superuser: boolean,
    mapping_state_id: number[],
    sales_role: salesRoleType
};
export type EmployeeDataReadresponse = {
    FM03_EmployeeID?: number,
    FM03_EmployeeCode: string,
    FM03_EmployeeName: string,
    FM03_EmployeeType: string,
    FM03_M02_BranchID: number | null,
    FM03_M05_Department: {
        id: number,
        name: string
    }|null,
    FM03_M05_DesignationID: {
        id: number,
        name: string
    }|null,
    FM03_Mobile: string,
    FM03_MailID: string,
    FM03_Username: string,
    FM03_M03ReportingtoEmployeeID: {
        id: number,
        name: string
    } | null,
    FM03_AllowLogin: number,
    FM03_ReportSortOrder: number,
    FM03_Status: number,
    FM03_StatusDate: string|null,
    is_active: boolean,
    is_staff: boolean,
    is_superuser: boolean,
    mapping_state_id: number[],
    sales_role: salesRoleType
};
type EmployeeDataListResponse = {
    FM03_EmployeeID?: number,
    FM03_EmployeeCode: string,
    FM03_EmployeeName: string,
    FM03_EmployeeType: string,
    FM03_M02_BranchID: {
        id: number,
        name: string
    }|null,
    FM03_M05_Department: {
        id: number,
        name: string
    }|null,
    FM03_M05_DesignationID: {
        id: number,
        name: string
    } | null,
    FM03_Mobile: string,
    FM03_MailID: string,
    FM03_Username: string,
    FM03_M03ReportingtoEmployeeID: {
        id: number,
        name: string
    } | null,
    FM03_AllowLogin: number,
    FM03_ReportSortOrder: number,
    FM03_Status: number,
    FM03_StatusDate: string|null,
    is_active: boolean,
    is_staff: boolean,
    is_superuser: boolean,
    mapping_state_id: number[],
    sales_role: salesRoleType
};





export type EmployeeDataResponse = {
    id: number,
    employee_code: string,
    employee_name: string,
    employee_type: string,
    branch: {
        id: number,
        name: string
    },
    department: {
        id: number,
        name: string
    },
    designation: {
        id: number,
        name: string
    },
    mobile: string,
    email: string,
    username: string,
    reporting_to: {
        id:number,
        name:string
    },
    allow_login: boolean,
    report_sort_order: number,
    status: number,
    status_date: string,
    is_active: boolean,
    is_staff: boolean,
    is_superuser: boolean,
    mapping_state_id:number[];
    sales_role:salesRoleType;
}


const QUERY_KEY = 'employees'
export function useEmployeeAPI({
    page_size,
    page,
    retryCount,
    trigger = true,
    search,
    FM03_M02_BranchID,
    FM03_M05_Department,
    FM03_M05_DesignationID
}: {
    page_size?: number,
    page?: number,
    retryCount?: number,
    trigger?: boolean,
    search?:string,
    FM03_M02_BranchID?: string[],
    FM03_M05_Department?: string[],
    FM03_M05_DesignationID?:string[]
}) {

    const api = useRestAPI()
    const [totalEmployeeCount, setTotalEmployeeCount] = useState(0)
    const queryclient = useQueryClient()
    // const [deleteRetryCount,setDeleteRetryCount] = useState(0)

    const {
        isLoading,
        isError,
        data: emloyees,
        refetch: refetchEmployees,
        isFetching,
        isFetched,
        error,
        isRefetchError,
        isFetchedAfterMount
    } = useQuery({
        queryKey: [QUERY_KEY, page_size, page,search,FM03_M02_BranchID,FM03_M05_Department,FM03_M05_DesignationID],
        queryFn: async () => {
            const response = await api.get<{ data: EmployeeDataListResponse[] } & backendPagination>({
                url: `${process.env.REACT_APP_BACKEND_URL}/account/employees/`,
                mode: 'protected',
                params: {
                    page_size,
                    page,
                    search,
                    FM03_M02_BranchID:FM03_M02_BranchID?.length?`[${FM03_M02_BranchID?.join(',')}]`:undefined,
                    FM03_M05_Department:FM03_M05_Department?.length?`[${FM03_M05_Department?.join(',')}]`:undefined,
                    FM03_M05_DesignationID:FM03_M05_DesignationID?.length?`[${FM03_M05_DesignationID?.join(',')}]`:undefined
                }
            })
            if (response.success === false) {
                throw response.message
            }

            setTotalEmployeeCount(response.count)
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger,


    })




    const { mutateAsync: createEmployee,isPending:isCreatingEmployee,failureCount:createfailureCount } = useMutation({
        mutationFn: async (Employee: EmployeeData) => {
            const response = await api.post<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/account/employees/`,
                data: Employee,
                mode: 'protected'
            })

            if(response.success === false && (response.message==='Network Error'|| response.message==='Server Error')&&createfailureCount<4){
                
                throw response
            }

            return response
        }
    })

    const { mutateAsync: updateEmployee,isPending:isUpdatingEmployee  ,failureCount:updateFailuirCount } = useMutation({
        mutationFn: async ({
            id, 
            Employee
        }:{
            id: string | number,
            Employee: EmployeeData
        }) => {
            const response = await api.patch<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/account/employees/${id}/`,
                data: Employee,
                mode: 'protected'
            })

            if(response.success === false && (response.message==='Network Error'|| response.message==='Server Error')&&updateFailuirCount<4){
                
                throw response
            }

            return response
        },retry:4
    })

    const { mutateAsync: deleteEmployeeById ,isPending:isDeleting,failureCount} = useMutation({
        mutationFn: async ({employeeId}: {employeeId:string|number}) => {
            
            const response = await api.delete<{}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/account/employees/${employeeId}/`,
                mode: 'protected'
            })

            if(response.success === false && (response.message==='Network Error'|| response.message==='Server Error')&&failureCount<4){
                
                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }

            return response 
        },
        retry:4,
        
    })
    const { mutateAsync: readEmployeebyId ,isPending:isReadingEmployee,failureCount:empReadFailureCount} = useMutation({
        mutationFn: async ({employeeId}: {employeeId:string|number}) => {
            
            const response = await api.get<{data:EmployeeDataReadresponse }>({
                url: `${process.env.REACT_APP_BACKEND_URL}/account/employees/${employeeId}/`,
                mode: 'protected'
            })

            if(response.success === false && (response.message==='Network Error'|| response.message==='Server Error')&&empReadFailureCount<4){
                
                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }

            return response 
        },
        retry:4,
        
    })
    const { mutateAsync: readASMunderRSM ,isPending:isReadingASMunderRSM,isError:isreadASMunderRSMError,failureCount:readASMunderRSMFailed} = useMutation({
        mutationFn: async ({RSMId,stateId}: {RSMId:string|number,stateId?:number}) => {
            
            const response = await api.get<{data:EmployeeData[] }>({
                url: `${process.env.REACT_APP_BACKEND_URL}/account/employees/`,
                mode: 'protected'
            })

            if(response.success === false && (response.message==='Network Error'|| response.message==='Server Error')&&readASMunderRSMFailed<4){
                
                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }

            return response 
        },
        retry:4,
        
    })
    const { mutateAsync: readSEunderASM ,isPending:isReadingSEunderASM,isError:isreadSEunderASMError,failureCount:readSEunderASMFailed} = useMutation({
        mutationFn: async ({ASMId,stateId}: {ASMId:string|number,stateId?:number}) => {
            
            const response = await api.get<{data:EmployeeData[] }>({
                url: `${process.env.REACT_APP_BACKEND_URL}/account/employees/`,
                mode: 'protected'
            })

            if(response.success === false && (response.message==='Network Error'|| response.message==='Server Error')&&readSEunderASMFailed<4){
                
                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }

            return response 
        },
        retry:4,
        
    })

   


    return {
        isLoading,
        isError,
        emloyees,
        refetchEmployees,
        totalEmployeeCount,
        isFetching,
        isFetched,
        error,
        isRefetchError,
        isFetchedAfterMount,
        isDeleting,
        createEmployee,
        deleteEmployeeById,
        readEmployeebyId,
        isReadingEmployee,
        updateEmployee,
        isUpdatingEmployee,
        readASMunderRSM,
        isReadingASMunderRSM,
        isreadASMunderRSMError,
        readSEunderASM,
        isReadingSEunderASM,
        isreadSEunderASMError
    }

}


export function useGetEmployeeDetailsByToken({
    retryCount,
    trigger = true,
}: {
    retryCount?: number,
    trigger?: boolean,
}) {

    const api = useRestAPI()
    const [totalEmployeeCount, setTotalEmployeeCount] = useState(0)
    const queryclient = useQueryClient()
    const token  = localStorage.getItem('accessTocken')
    // const [deleteRetryCount,setDeleteRetryCount] = useState(0)

    const {
        isLoading:isLoaingEmployeeDetails,
        isError:isErrorEmployeeDetails,
        data: employeeDetails,
        refetch: refetchEmployeeDetails,
        isFetching:isFetchingEmployeeDetails,
        isFetched,
        error,
        isRefetchError,
        isFetchedAfterMount
    } = useQuery({
        queryKey: ['employee-details',token],
        queryFn: async () => {
            const response = await api.get<{ data: EmployeeData[] }>({
                url: `${process.env.REACT_APP_BACKEND_URL}/account/get-employee/`,
                mode: 'protected',
            })
            if (response.success === false) {
                throw response
            }

            return response
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger,


    })

    return{
        employeeDetails,
        isLoaingEmployeeDetails,
        isFetchingEmployeeDetails,
        isErrorEmployeeDetails
    }

}
export function useEmployeeASMsUnderRSMsAPI({
    retryCount,
    trigger = true,
    rsmId,
    stateId
}: {
    retryCount?: number,
    trigger?: boolean,
    stateId?: number,
    rsmId:number
}) {

    const api = useRestAPI()
    const [totalEmployeeCount, setTotalEmployeeCount] = useState(0)
    const queryclient = useQueryClient()
    // const [deleteRetryCount,setDeleteRetryCount] = useState(0)

    const {
        isLoading,
        isError,
        data: asms,
        refetch: refetchEmployees,
        isFetching,
        isFetched,
        error,
        isRefetchError,
        isFetchedAfterMount
    } = useQuery({
        queryKey: ['employees-asms-under-rsm', stateId,rsmId],
        queryFn: async () => {
            const response = await api.get<{ data: EmployeeData[] }>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/statemapping-asm-under-rsm/`,
                mode: 'protected',
                params: {
                    stateId:stateId,
                    rsmId:rsmId
                }
            })
            if (response.success === false) {
                throw response.message
            }

            return response.data
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger,


    })

    return{
        asms,
        isLoading,
        isFetching,
        isError
    }

}



export function useEmployeeSEsUnderASMsAPI({
    retryCount,
    trigger = true,
    asmId,
    stateId
}: {
    retryCount?: number,
    trigger?: boolean,
    stateId?: number,
    asmId:number
}) {

    const api = useRestAPI()
    const [totalEmployeeCount, setTotalEmployeeCount] = useState(0)
    const queryclient = useQueryClient()
    // const [deleteRetryCount,setDeleteRetryCount] = useState(0)

    const {
        isLoading,
        isError,
        data: ses,
        refetch: refetchEmployees,
        isFetching,
        isFetched,
        error,
        isRefetchError,
        isFetchedAfterMount
    } = useQuery({
        queryKey: ['employees-ses-under-rsm', stateId,asmId],
        queryFn: async () => {
            const response = await api.get<{ data: EmployeeData[] }>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/statemapping-se-under-asm/`,
                mode: 'protected',
                params: {
                    stateId:stateId,
                    asmId:asmId
                }
            })
            if (response.success === false) {
                throw response.message
            }

            return response.data
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger,


    })

    return{
        ses,
        isLoading,
        isFetching,
        isError
    }

}