import { IconLoader, IconSearch, IconX } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { useProductCategoryAPI } from 'src/API/rest.product_category'
import { useProductSubCategoryAPI } from 'src/API/rest.product_sub_category'
import { ButtonIconSquare } from 'src/components/buttons'
import { ModalStandard, ModalStandardContent, ModalStandardDescription, ModalStandardFooter, ModalStandardHeader, ModalStandardTitle } from 'src/components/standard.modal'
import { MultiselectStandard } from 'src/components/standard.multiselect'
import { SelectStandard } from 'src/components/standard.select'

type Props = {
    show?: boolean,
    setShow?: (show: boolean) => void,
    ProductCategory: number[],
    productSubCategory: number[],
    status: 'All'|'1'|'0',
    search: (
        {
            productCategory,
            productSubCategory,
            status
        }:
            {
                productCategory: number[],
                productSubCategory: number[],
                status: 'All'|'1'|'0'
            }
    ) => void
}

export function FilterModal(props: Props) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDownloadingTemplate, setisDownloadingTemplate] = useState(false);
    const [uplaodProgress, setuplaodProgress] = useState(0);
    const [productCategory, setproductCategory] = useState<number[]>([]);
    const [productSubCategory, setproductSubCategory] = useState<number[]>([]);
    const [district, setDistrict] = useState<number | null>(null);
    const [zone, setZone] = useState<number | null>(null);
    const [status, setStatus] = useState<'All'|'1'|'0'>('1');

    useEffect(() => {
        if (props.show) {
            setproductCategory(props.ProductCategory);
            setproductSubCategory(props.productSubCategory);
            setStatus(props.status);

        }
    }, [props.show]);

    const handleDownloadTemplate = async () => {
        setisDownloadingTemplate(true);
        // Implement your file download logic here
        // After download is complete:
        setisDownloadingTemplate(false);
    };

    const {
        productCategories,
        isLoading: isLoadingProductCategories,
        isError: isErrorProductCategories,
        isFetching: isFetchingProductCategories
    } = useProductCategoryAPI({
        trigger: props.show,
        page_size: 200
    });
    const {
        productSubCategories,
        isLoading: isLoadingproductSubCategories,
        isError: isErrorproductSubCategories,
        isFetching: isFetchingproductSubCategories
    } = useProductSubCategoryAPI({
        trigger: props.show,
        page_size: 200
    });


    const isAnythingLoading = false;

    return (
        <ModalStandard
            open={props.show}
        >
            <ModalStandardContent hideCloseButton={true} onOpenAutoFocus={(e) => e?.preventDefault()} className='bg-vstargray-100 !max-w-[300px]'>
                <ModalStandardHeader>
                    <ModalStandardTitle>Filter</ModalStandardTitle>
                    <ModalStandardDescription>
                        {isAnythingLoading && (
                            <div className='px-2 p-1 rounded-md bg-vstargray-800/30 whitespace-nowrap w-min absolute left-1/2 -translate-x-1/2'>
                                Updating...
                            </div>
                        )}
                        <div className='w-full grid grid-cols-1  items-center justify-center'>
                            <MultiselectStandard
                                name='product category'
                                title='product category'
                                options={productCategories?.length ? [...productCategories?.map((state) => ({ value: String(state.FM12_ProductCategoryID), label: state.FM12_ProductCategory })).filter(category => category.label)] : []}
                                placeholder='product category'
                                value={productCategory ? productCategory.map(String) : []}

                                onValueChange={(e) => {
                                    setproductCategory(e.target.value.map(Number));
                                }}
                            />
                            <MultiselectStandard
                                name='product subcategory'
                                title='product subcategory'
                                options={productSubCategories?.length ? [...productSubCategories?.map((state) => ({ value: String(state.FM12_ProductCategoryID), label: state.FM12_ProductSubCategory })).filter(category => category.label)] : []}
                                placeholder='product subcategory'
                                value={productSubCategory ? productSubCategory.map(String) : []}

                                onValueChange={(e) => {
                                    setproductSubCategory(e.target.value.map(Number));
                                }}
                            />
                            <SelectStandard
                                name='status'
                                title='Status'
                                options={[
                                    { value: 'All', label: 'All' },
                                    { value: '1', label: 'Active' },
                                    { value: '0', label: 'Inactive' }
                                ]}
                                onChange={(e) => {
                                    setStatus(e.target.value as any);
                                }}
                                value={status}
                            />
                        </div>
                    </ModalStandardDescription>
                </ModalStandardHeader>
                <ModalStandardFooter>
                    <ButtonIconSquare
                        variant='white'
                        icon={<IconX />}
                        text='Close'
                        onClick={(e) => { e?.preventDefault(); props.setShow?.(false); }}
                        disabled={isSubmitting}
                    />
                    <ButtonIconSquare
                        variant='secondary'
                        icon={isSubmitting ? <IconLoader className='animate-spin' /> : <IconSearch />}
                        text='Search'
                        onClick={() => {
                            props.search({
                                productCategory,
                                productSubCategory,
                                status
                            });
                            props.setShow?.(false);
                        }}
                        disabled={isSubmitting}
                    />
                </ModalStandardFooter>
            </ModalStandardContent>
        </ModalStandard>
    );
}
