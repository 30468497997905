import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { AxiosResponseCustom, useRestAPI } from "./axiosAPI"
import { useState } from "react"
import { backendPagination } from "src/types/pagination"

export type ProductData = {
    FM13_ProductID?: number,
    FM13_M12_ProductSubCategory?: string,
    FM13_M12_ProductCategory?: string,
    FM13_ProductCode?: null | string,
    FM13_ProductDescription?: null | string,
    FM13_StockCategory?: null | string,
    FM13_NewProductFlag: number,
    FM13_Status: number,
    FM13_StatusDate: string | null
}

export type ProductDataExtended = {
    FM13_ProductID: number,
    FM13_M12_ProductSubCategory: {
        id: number,
        name: string
    },
    FM13_M12_ProductCategory: {
        id: number,
        name: string
    },
    FM13_ProductCode: string | null,
    FM13_ProductDescription: string | null,
    FM13_StockCategory: string | null,
    FM13_NewProductFlag: number,
    FM13_Status: number,
    FM13_StatusDate: string | null
}



const QUERY_KEY = 'products'
export function useProductAPI({
    page_size,
    page,
    retryCount,
    trigger = true,
    FM13_M12_ProductCategory,
    FM13_M12_ProductSubCategory,
    FM13_ProductDescription,
    FM13_ProductCode,
    search,
    FM13_Status
}: {
    page_size?: number,
    page?: number,
    retryCount?: number,
    trigger?: boolean,
    FM13_M12_ProductSubCategory?: string[],
    FM13_ProductDescription?: string,
    FM13_ProductCode?: string,
    FM13_M12_ProductCategory?: string[],
    search?: string,
    FM13_Status?: 0|1
}) {

    const api = useRestAPI()
    const [totalProductCount, setTotalProductCount] = useState(0)
    const queryclient = useQueryClient()
    // const [deleteRetryCount,setDeleteRetryCount] = useState(0)

    const {
        isLoading,
        isError,
        data: products,
        refetch: refetchProducts,
        isFetching,
        isFetched,
        error,
        isRefetchError,
        isFetchedAfterMount
    } = useQuery({
        queryKey: [QUERY_KEY, page_size, page,FM13_M12_ProductCategory, FM13_M12_ProductSubCategory, FM13_ProductDescription, FM13_ProductCode, FM13_Status, search],
        queryFn: async () => {
            const response = await api.get<{ data: ProductDataExtended[] } & backendPagination>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/products/`,
                mode: 'protected',
                params: {
                    page_size,
                    page,
                    FM13_M12_ProductSubCategory:FM13_M12_ProductSubCategory?.length?`[${FM13_M12_ProductSubCategory?.join(',')}]`:undefined,
                    FM13_ProductDescription,
                    FM13_ProductCode,
                    FM13_M12_ProductCategory:FM13_M12_ProductCategory?.length?`[${FM13_M12_ProductCategory?.join(',')}]`:undefined,
                    search,
                    FM13_Status
                }
            })
            if (response.success === false) {
                throw response.message
            }

            setTotalProductCount(response.count)
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger,


    })




    const { mutateAsync: createProduct, isPending: isCreatingProduct, failureCount: createfailureCount } = useMutation({
        mutationFn: async (Product: ProductData) => {
            const response = await api.post<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/products/`,
                data: Product,
                mode: 'protected'
            })

            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && createfailureCount < 4) {

                throw response
            }

            return response
        }
    })

    const { mutateAsync: updateProduct, isPending: isUpdatingProduct, failureCount: updateFailuirCount } = useMutation({
        mutationFn: async ({
            id,
            Product
        }: {
            id: string | number,
            Product: ProductData
        }) => {
            console.log(Product)
            const response = await api.patch<{}, {}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/products/${id}/`,
                data: Product,
                mode: 'protected'
            })

            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && updateFailuirCount < 4) {

                throw response
            }

            return response
        }, retry: 4
    })

    const { mutateAsync: deleteProductById, isPending: isDeleting, failureCount } = useMutation({
        mutationFn: async ({ productId }: { productId: string | number }) => {

            const response = await api.delete<{}>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/products/${productId}/`,
                mode: 'protected'
            })

            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && failureCount < 4) {

                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }

            return response
        },
        retry: 4,

    })
    const { mutateAsync: readProductbyId, isPending: isReadingProduct, failureCount: empReadFailureCount } = useMutation({
        mutationFn: async ({ productId }: { productId: string | number }) => {

            const response = await api.get<{ data: ProductDataExtended }>({
                url: `${process.env.REACT_APP_BACKEND_URL}/master/products/${productId}/`,
                mode: 'protected'
            })

            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && empReadFailureCount < 4) {

                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }

            return response
        },
        retry: 4,

    })




    return {
        isLoading,
        isError,
        products,
        refetchProducts,
        totalProductCount,
        isFetching,
        isFetched,
        error,
        isRefetchError,
        isFetchedAfterMount,
        isDeleting,
        createProduct,
        deleteProductById,
        readProductbyId,
        isReadingProduct,
        updateProduct,
        isUpdatingProduct
    }


}


export function useProductAPIs(){

    const api = useRestAPI()

    const { mutateAsync: downloadProductDatas, isPending: isDownloadingProductDatas, failureCount: failureProductDatas, isError: isErrorDownloadingProductDatas } = useMutation({
        mutationFn: async () => {

            const response = await api.get({
                url: `${process.env.REACT_APP_BACKEND_URL}/excel-upload/product-data-download/`,
                mode: 'protected',
                responseType: 'blob',
                
            })


            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && failureProductDatas < 4) {

                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }
            if (response.success !== false) {
                const blob = new Blob([response as unknown as BlobPart], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'Products.xlsx'; // Set the filename

                // Append to the document and trigger the download
                document.body.appendChild(link);
                link.click();

                // Clean up
                document.body.removeChild(link);
                window.URL.revokeObjectURL(link.href);
            }

            return response
        },
        retry: 4,

    })

    return {
        downloadProductDatas,
        isDownloadingProductDatas,
    }

}
