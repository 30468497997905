import { IconEdit, IconLoader, IconPlus, IconX } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { labelDayButton } from 'react-day-picker';
import { useForm } from 'react-hook-form';
import { useEmployeeAPI } from 'src/API/rest.employee';
import { CalenderActinPostType, CalenderLineupdateType, useForecastCalenderAPI } from 'src/API/rest.forecast_calender';
import { ButtonIconSquare } from 'src/components/buttons';
import { InputStandard } from 'src/components/standard.input';
import { ModalStandard, ModalStandardContent, ModalStandardDescription, ModalStandardFooter, ModalStandardHeader, ModalStandardTitle } from 'src/components/standard.modal';
import { MultiselectStandard } from 'src/components/standard.multiselect';
import { MultiselectStandardOnline } from 'src/components/standard.multiselectOnline';
import { StandardTableHeader } from 'src/components/standard.table';
import { cn } from 'src/lib/utils';
import { toast } from 'src/shadcn/ui/use-toast';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import { ReferenceMonthData, useReferenceMonthAPI } from 'src/API/rest.referenceMonth';

type Props = {
    show?: boolean,
    setShow?: (show: boolean) => void,
    refetchforcastCalendersList: () => void,
    employeeDistributorMappingId?: number | string | null,
    selectedDate: string
};

type MonthType = {
    month: string;
    value: string;
    status: boolean;
    id?: number
}

type OptionsType = {
    ForecastCalenderLineID?: number | null,
    SINo: number,
    FT06_forecastCalenderMasterID: number,
    Action: string,
    FT07_TargetDate: string | undefined,
    FT07_Status: number,
    FT07_Date: string,
    Responsibility: {
        ForecastCalenderResponsibilityID: number | null,
        ForecastCalenderline: number | null,
        FM03_EmployeeID: number
    }[]
};
type OptionsTypeForm = {
    SINo: number,
    FT06_forecastCalenderMasterID: number,
    Action: string,
    FT07_TargetDate: string | undefined,
    FT07_Status: number,
    FT07_Date: string,
    Responsibility: {
        FM03_EmployeeID: number
    }[],
};

export function ModalAddForecastModal(props: Props) {

    const { register, handleSubmit, setValue, reset, formState: { errors }, watch } = useForm<{ options: OptionsTypeForm[] }>({

    });

    const [standardMonths, setStandardMonths] = useState<MonthType[]>([
            { month: 'LYM1', value: '', status: true },
            { month: 'LYM2', value: '', status: true },
            { month: 'LYM3', value: '', status: true },
            { month: 'M-3', value: '', status: true },
            { month: 'M-2', value: '', status: true },
            { month: 'M-1', value: '', status: true },
        ]);

    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [options, setOptions] = useState<OptionsType[]>([]);
    const [mode, setMode] = useState<'add' | 'edit'>('add');
    const [searchOldStyles, setSearchSearchOldStyles] = useState('')
    const [unavailableEmployee, setUnavailableEmployee] = useState<{ label: string, value: string }[]>([])
    const [selectedItems, setSelectedItems] = useState<{
        label: string,
        value: string,
    }[]>([])
    const [loadedAllUnavailableEmployee, setLoadedAllUnavailableEmployee] = useState(false)
    // fetch actions in this date
    const {
        isLoading: isLoadingForecastCalender,
        isError: isErrorForecastCalender,
        forcastCalendersList,
        isFetching: isFetchingForecastCalender,
        isFetched: isFetchedForcastCalender,
        createCalendarLine,
        updateCalendarLine,
        isUpdatingCalendarLine,
        errorUpdatingCalendarLineCount
    } = useForecastCalenderAPI({
        date: `${props.selectedDate}-01`,
        trigger: props.show
    })

    const {
        isLoading: isLoadingReferenceMonth,
        isError: isErrorReferenceMonth,
        createReferenceMonth,
        isCreateReferenceMonthPending,
    } = useReferenceMonthAPI({
        trigger:  false,
        forcastMonth: props.selectedDate || ''
    })

    //fetch employees
    const {
        emloyees,
        isLoading: isLoadingEmployees,
        isFetching: isFetchingEmployees,
        isError: isErrorEmployees,
        readEmployeebyId,
    } = useEmployeeAPI({
        trigger: props.show,
        search: searchOldStyles
    })

    const optionsSelected = watch('options')

    //load unavailable employees to options
    useEffect(() => {

        const loadUnavailableEmployees = async (newStyles: {
            emlpyeeId: number,
        }[]) => {



            const unavailableStyles = await Promise.all(
                newStyles.map(
                    async (style) => {
                        if (style.emlpyeeId) {
                            const emplyeeFetched = await readEmployeebyId({
                                employeeId: style.emlpyeeId
                            })
                            if (emplyeeFetched.success === true) {
                                return {
                                    label: `(${emplyeeFetched.data?.FM03_EmployeeCode})-${emplyeeFetched.data?.FM03_EmployeeName}`,
                                    value: String(emplyeeFetched.data?.FM03_EmployeeID)
                                }
                            }
                        }

                        return null
                    }
                )
            )
            console.log(unavailableStyles)
            if (unavailableStyles?.length !== 0) {
                setUnavailableEmployee(unavailableStyles.filter(s => s) as { label: string, value: string }[])
                setLoadedAllUnavailableEmployee(true)
            }



        }

        if (optionsSelected?.length > 0 && props.show) {

            const employeesLoaded = optionsSelected.map(option => option.Responsibility.map(responsibility => responsibility.FM03_EmployeeID)).flat()

            loadUnavailableEmployees(employeesLoaded.map(empId => ({ emlpyeeId: empId })))


        }
        if (props.show === false) {
            setSelectedItems([])
        }

    }, [optionsSelected?.length, props.show])

    useEffect(() => {
        if (!isErrorForecastCalender && isFetchedForcastCalender && !isErrorEmployees) {
            const options: OptionsType[] = forcastCalendersList?.map((item) => ({
                ForecastCalenderLineID: item.CalenderLines?.[0]?.ForecastCalenderLineID,
                FT06_forecastCalenderMasterID: item.ForecastCalenderMasterID,
                Action: item.Action,
                Responsibility: item.CalenderLines?.[0]?.Responsibility?.map(emp => ({
                    FM03_EmployeeID: emp.EmployeeID,
                    ForecastCalenderResponsibilityID: emp.ForecastCalenderResponsibilityID,
                    ForecastCalenderline: emp.ForecastCalenderline
                })) || [],
                FT07_Status: 1,
                FT07_Date: item.CalenderLines?.[0]?.Date || '',
                FT07_TargetDate: item.CalenderLines?.[0]?.TargetDate || '',

                SINo: Number(item.SINo)
            })) || []
            console.log('reseting')

            reset({
                options: options
            });
            setOptions(options);

            if (forcastCalendersList?.some(data => data.CalenderLines?.length > 0)) {
                setMode('edit')
            } else {
                setMode('add')
            }
        }

        if (isErrorEmployees || isErrorForecastCalender) {
            toast({
                description: 'Error in fetching data',
                title: 'Error',
            })
        }

    }, [props.show, isFetchingForecastCalender]);

    const onSubmit = async (data: { options: OptionsTypeForm[] }) => {
        setIsSubmitting(true);
        const dataString = JSON.stringify(data.options);
        const optionNew = JSON.parse(dataString) as CalenderActinPostType[];

        let postData: ReferenceMonthData[]


        if (mode === 'add') {
            console.log(data.options)
            optionNew.map((option, index) => {
                option.FT07_TargetDate = option.FT07_TargetDate ? option.FT07_TargetDate : null;
                option.FT07_Date = new Date(props.selectedDate).toISOString().split('T')[0];
            })

            // postData = standardMonths.map(item => {
            //     return {
            //         FT17_forecast_month: `${props.selectedDate}-01`,
            //         FT17_date: item.value,
            //         FT17_month_name: item.month,
            //         FT17_status: item.status?1:0,
            //         FT17_type: '',
            //     }
            // })

            

            // createReferenceMonth({
            //     referenceMonth:postData
            // })

            const response = await createCalendarLine(optionNew);
            if (response.success) {
                toast({ description: response.message });
                props.setShow?.(false);
                props.refetchforcastCalendersList();
            } else {
                toast({ description: response.message, title: 'Error' });
            }
        } else if (mode === 'edit') {

            const submitOptionsString = JSON.stringify(data.options);
            const submitOptions: OptionsType[] = JSON.parse(submitOptionsString);
            const deletedResponsibilityIds: number[] = []
            // Assigning responsibility ids
            submitOptions.forEach((option, index) => {
                option.Responsibility.forEach(responsibility => {
                    const ogResponsibilities = options[index].Responsibility
                    const foundResponsibility = ogResponsibilities.find(ogResponsibility => ogResponsibility.FM03_EmployeeID == responsibility.FM03_EmployeeID)
                    if (foundResponsibility) {
                        responsibility.ForecastCalenderResponsibilityID = foundResponsibility.ForecastCalenderResponsibilityID
                        responsibility.ForecastCalenderline = foundResponsibility.ForecastCalenderline
                    } else {
                        responsibility.ForecastCalenderResponsibilityID = null
                        responsibility.ForecastCalenderline = null
                    }


                })
            })
            // finding deleted responsibility
            options.forEach((option, index) => {
                option.Responsibility.forEach(Ogresponsibility => {

                    const editedResponsibilities = submitOptions[index].Responsibility
                    const foundResponsibility = editedResponsibilities.find(editedResponsibily => Ogresponsibility.FM03_EmployeeID == editedResponsibily.FM03_EmployeeID)
                    if (!foundResponsibility) {
                        deletedResponsibilityIds.push(Ogresponsibility.ForecastCalenderResponsibilityID!)
                    }

                })
            })

            console.log(submitOptions)

            const finalSubmit: CalenderLineupdateType[] = submitOptions.map((option, index) => ({
                FT06_forecastCalenderMasterID: option.FT06_forecastCalenderMasterID,
                FT07_Date: new Date(props.selectedDate).toISOString().split('T')[0],
                FT07_ForecastCalenderLineID: option.ForecastCalenderLineID ? option.ForecastCalenderLineID! : null,
                Responsibility: option.Responsibility.map(responsibility => ({
                    FM03_EmployeeID: responsibility.FM03_EmployeeID,
                    FT08_T07_ForecastCalenderline: responsibility.ForecastCalenderline,
                    FT08_ForecastCalenderResponsibilityID: responsibility.ForecastCalenderResponsibilityID
                })),
                // SINo: option.SINo,
                FT07_TargetDate: option.FT07_TargetDate ? option.FT07_TargetDate : null,
                FT07_Status: option.FT07_Status,

            })
            )
            console.log('deleted responsibilities', deletedResponsibilityIds)

            const response = await updateCalendarLine({
                data: {
                    calenderLines: finalSubmit,
                    deletedResponsibility: deletedResponsibilityIds
                },
                date: new Date(props.selectedDate).toISOString().split('T')[0]
            })

            if (response.success) {
                toast({ description: response.message });
                props.setShow?.(false);
                props.refetchforcastCalendersList();
            } else {
                toast({ description: response.message, title: 'Error' });
            }

            // console.log(options)

            // const response = await updateEmployeeDistributorMapping({ id: props.employeeDistributorMappingId, data: data as EmployeeDistributorMapping });
            // if (response.success) {
            //     toast({ description: response.message });
            //     props.setShow?.(false);
            //     props.refetchforcastCalendersList();
            // } else {
            //     toast({ description: response.message, title: 'Error' });
            // }
        }
        setIsSubmitting(false);
    };

    console.log('selected items', optionsSelected)


    const isAnythingLoading = isLoadingForecastCalender || isFetchingForecastCalender ||
        isFetchingEmployees || isLoadingEmployees

    const isAnyError = isErrorForecastCalender || isErrorEmployees
    const realEmployees = [
        ...emloyees?.map(emp => ({ value: String(emp.FM03_EmployeeID), label: `(${emp.FM03_EmployeeCode})-${emp.FM03_EmployeeName}` })) || [],
        ...unavailableEmployee,
        ...selectedItems
    ]
    const realEmployeesaUnique = Array.from(new Set(realEmployees.map(s => s.value))).map(v => realEmployees.find(s => s.value === v)!);
    console.log(realEmployeesaUnique)
    return (
        <ModalStandard open={props.show} onOpenChange={props.setShow}>
            <ModalStandardContent className="bg-gray-100">
                <form onSubmit={handleSubmit(onSubmit)} className=' overflow-auto'>
                    <ModalStandardHeader>
                        <ModalStandardTitle>{mode === 'edit' ? 'Edit Forcast Calender' : 'Add Forcast Calender'}</ModalStandardTitle>
                        <ModalStandardDescription className=' overflow-auto'>
                            {isAnythingLoading && <div className=' px-2 p-1 rounded-md bg-vstargray-800/30 whitespace-nowrap w-min absolute left-1/2 -translate-x-1/2'>
                                Updating...
                            </div>}
                            <div className="overflow-x-auto">
                                <div className="inline-block min-w-full align-middle">
                                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table className="min-w-full">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white ">
                                                {
                                                    options.map((item, index) => {

                                                        const ResponsibilityIds = watch(`options.${index}.Responsibility`).map(item => String(item.FM03_EmployeeID))
                                                        const TargetDate = watch(`options.${index}.FT07_TargetDate`)

                                                        return (
                                                            <>
                                                                <tr key={index}>
                                                                    <td rowSpan={2} className="px-6 py-4 whitespace-nowrap">{item.SINo}</td>
                                                                    <td colSpan={2} className="px-6 py-4  text-sm font-medium">{item.Action}</td>

                                                                </tr>
                                                                <tr className={
                                                                    cn([
                                                                        index === options.length - 1 ? '' : ' border-b-4',
                                                                        'bg-white',
                                                                    ])
                                                                }>
                                                                    <td className="px-6 py-2 whitespace-nowrap">
                                                                        <DatePicker
                                                                            id="datepicker"
                                                                            selected={TargetDate ? new Date(TargetDate) : null}
                                                                            onChange={(event) => {
                                                                                if (event) {
                                                                                    console.log(event)
                                                                                    console.log(format(event, "yyyy-MM-dd"))
                                                                                    setValue(`options.${index}.FT07_TargetDate`, format(event, "yyyy-MM-dd"))
                                                                                }
                                                                                else {
                                                                                    setValue(`options.${index}.FT07_TargetDate`, '')
                                                                                }
                                                                            }}
                                                                            dateFormat="dd-MMM-yyyy"
                                                                            customInput={
                                                                                <input
                                                                                    type="text"
                                                                                    value={TargetDate ? format(TargetDate, "dd-MMM-yyyy") : ""}
                                                                                    // readOnly
                                                                                    disabled
                                                                                    style={{ padding: "5px", border: "1px solid #ccc", borderRadius: "4px" }}
                                                                                />
                                                                            }
                                                                        />
                                                                        {/* <InputStandard
                                                                            {...register(`options.${index}.FT07_TargetDate`, {
                                                                                validate: (value) => {
                                                                                    if (ResponsibilityIds.length) {
                                                                                        if (!value) {
                                                                                            return 'TargetDate is required'
                                                                                        }
                                                                                    } else {
                                                                                        return undefined
                                                                                    }
                                                                                }
                                                                            })}
                                                                            error={errors.options?.[index]?.FT07_TargetDate?.message}
                                                                            value={watch(`options.${index}.FT07_TargetDate`)}
                                                                            onChange={(event) => {
                                                                                console.log(event.target.value)
                                                                                setValue(`options.${index}.FT07_TargetDate`, event.target.value)
                                                                            }}
                                                                            type='date'
                                                                            disabled={isAnythingLoading}
                                                                        /> */}
                                                                    </td>
                                                                    <td className="px-6 whitespace-nowrap">
                                                                        <MultiselectStandardOnline
                                                                            {...register(`options.${index}.Responsibility`, {
                                                                                validate: (value) => {
                                                                                    if (TargetDate) {
                                                                                        if (!value?.length) {
                                                                                            return 'Responsibility is required'
                                                                                        }
                                                                                    } else {
                                                                                        return undefined
                                                                                    }
                                                                                }
                                                                            })}
                                                                            options={realEmployeesaUnique || []}
                                                                            error={errors.options?.[index]?.Responsibility?.message}
                                                                            value={ResponsibilityIds}
                                                                            onValueChange={(value) => {

                                                                                const employeesLoaded = optionsSelected.map(option => option.Responsibility.map(responsibility => {
                                                                                    const empReal = emloyees?.find(emp => emp.FM03_EmployeeID === responsibility.FM03_EmployeeID)
                                                                                    if (empReal) {
                                                                                        return {
                                                                                            value: responsibility.FM03_EmployeeID,
                                                                                            label: `(${empReal.FM03_EmployeeCode})-${empReal.FM03_EmployeeName}`
                                                                                        }
                                                                                    } else {
                                                                                        return null
                                                                                    }
                                                                                })).flat()

                                                                                setSelectedItems([...selectedItems, ...value.target.item.map(item => ({ label: item.label, value: item.value }))])

                                                                                setValue(`options.${index}.Responsibility`, value.target.item.map(
                                                                                    item1 => (
                                                                                        {
                                                                                            FM03_EmployeeID: Number(item1.value),
                                                                                            // ForecastCalenderResponsibilityID:item.
                                                                                        }
                                                                                    )
                                                                                ))
                                                                            }}
                                                                            onChangeSearch={(value) => setSearchSearchOldStyles(value)}
                                                                            disabled={isAnythingLoading}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </ModalStandardDescription>
                    </ModalStandardHeader>
                    <ModalStandardFooter>
                        <ButtonIconSquare variant="white" icon={<IconX />} text="Close" onClick={(e) => {
                            props.setShow?.(false);
                            e?.preventDefault();
                        }} disabled={isSubmitting} />
                        <ButtonIconSquare variant="secondary" icon={isSubmitting ? <IconLoader className="animate-spin" /> : mode === 'add' ? <IconPlus /> : <IconEdit />} text={mode === 'add' ? 'Add' : 'Edit'} type="submit" disabled={isSubmitting || isAnythingLoading || isAnyError} />
                    </ModalStandardFooter>
                </form>
            </ModalStandardContent>
        </ModalStandard>
    );
}
