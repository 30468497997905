import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import { useRestAPI } from "./axiosAPI"



export function useExecutiveWiseForcast({
    retryCount,
}: {
    retryCount?: number,
}) {

    const api = useRestAPI()
    const [totalSubAttributeCount, setTotalSubAttributeCount] = useState(0)
    const [validateProgress, setValidateProgress] = useState(0)
    const [uploadProgress, setuploadProgress] = useState(0)
    const queryclient = useQueryClient()
    // const [deleteRetryCount,setDeleteRetryCount] = useState(0)


    const { mutateAsync: downloadTemplateExecutivewiseforecast, isPending: isDownloadingTEmplateExcelforecast, failureCount: downloadTemplateFailureCount, isError: isDownloadExcelForecastTemplateError } = useMutation({
        mutationFn: async ({
            LYM1,
            LYM2,
            LYM3,
            M3,
            M2,
            M1,
            date,
            seId,
            asmId
        }:{
            LYM3:string,
            LYM2:string,
            LYM1:string,
            M3:string,
            M2:string,
            M1:string,
            date:string,
            seId:number[],
            asmId:number
        }) => {

            const seIdParams = seId.map(id=>`seId=${id}`).join('&')
            const response = await api.get({
                url: `${process.env.REACT_APP_BACKEND_URL}/transactions/se-forecast/template/?${seIdParams}`,
                mode: 'protected',
                responseType: 'blob',
                params:{
                    LYM1,
                    LYM2,
                    LYM3,
                    'M-3':M3,
                    'M-2':M2,
                    'M-1':M1,
                    date,
                    asmId
                }
            })


            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && downloadTemplateFailureCount < 4) {

                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }
            if (response.success !== false) {
                const blob = new Blob([response as unknown as BlobPart], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'executive_forecast.zip'; // Set the filename

                // Append to the document and trigger the download
                document.body.appendChild(link);
                link.click();

                // Clean up
                document.body.removeChild(link);
                window.URL.revokeObjectURL(link.href);
            }

            return response
        },
        retry: 4,

    })
    const { mutateAsync: validateExecutivewiseforecastExel, isPending: isvalidateingExecutivewiseforecastExel, failureCount: validateExecutivewiseforecastExelFailureCount, isError: isvalidateExecutivewiseforecastExelError } = useMutation({
        mutationFn: async ({ file,SEId }: { file: File,SEId:number }) => {

            const formData = new FormData();
            formData.append('file', file);
            formData.append('SEId', SEId.toString());

            const response = await api.post({
                url: `${process.env.REACT_APP_BACKEND_URL}/transactions/se-forecast/template/validate/`,
                mode: 'protected',
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total!
                    );
                    setValidateProgress(percentCompleted);
                },
                data: formData
            });


            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && validateExecutivewiseforecastExelFailureCount < 4) {

                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }


            return response
        },
        retry: 4,

    })

    const { mutateAsync: uploadExecutivewiseforecastExel, isPending: isuploadingExecutivewiseforecastExel, failureCount: uploadExecutivewiseforecastExelFailureCount, isError: isuploadExecutivewiseforecastExelError } = useMutation({
        mutationFn: async ({ file, date, SEId }: { file: File, date: string,SEId:number }) => {

            const formData = new FormData();
            formData.append('file', file);
            formData.append('date', date);
            formData.append('SEId', SEId.toString());
            const response = await api.post({
                url: `${process.env.REACT_APP_BACKEND_URL}/transactions/se-forecast/template/upload/`,
                mode: 'protected',
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total!
                    );
                    setuploadProgress(percentCompleted);
                },
                data: formData
            })


            if (response.success === false && (response.message === 'Network Error' || response.message === 'Server Error') && uploadExecutivewiseforecastExelFailureCount < 4) {

                // setDeleteRetryCount((prev)=>prev+1)
                throw response
            }


            return response
        },
        retry: 4,

    })



    return {

        downloadTemplateExecutivewiseforecast,
        isDownloadingTEmplateExcelforecast,
        isDownloadExcelForecastTemplateError,
        validateExecutivewiseforecastExel,
        isvalidateingExecutivewiseforecastExel,
        isvalidateExecutivewiseforecastExelError,
        validateProgress,
        uploadExecutivewiseforecastExel,
        isuploadingExecutivewiseforecastExel,
        uploadProgress
    }


}




