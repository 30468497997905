import { IconCloudUpload, IconLoader2 } from '@tabler/icons-react'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useEmployeeAPI } from 'src/API/rest.employee'
import { useReadTotalForcasts } from 'src/API/rest.forecast'
import { useGetLatestCalendarDate } from 'src/API/rest.forecast_calender'
import { MonthCode } from 'src/API/rest.forecast_regionwise'
import { useInstitutionalForcast } from 'src/API/rest.InstitutionalForcast'
import { useStatesAPI } from 'src/API/rest.states'
import { ButtonIconSquare } from 'src/components/buttons'
import { ErrorBoxStandard } from 'src/components/standard.errorbox'
import { FileInputDragDropStandard } from 'src/components/standard.fileDroper'
import { InputStandard } from 'src/components/standard.input'
import { SelectStandard } from 'src/components/standard.select'
import { StandardTable, StandardTableHeader, StandardTableRow } from 'src/components/standard.table'
import { TypographyH2 } from 'src/components/typography'
import { toast } from 'src/shadcn/ui/use-toast'


type MonthType = {
    month: string;
    value: string;
}

export function InstitutionalForecastPage() {
    const [isOpen, setIsOpen] = useState(false)
    const currentDate = new Date();
    const [selectedDate, setSelectedDate] = useState(
        currentDate.toISOString().substring(0, 7) // Format: YYYY-MM
    );
    const [isDownloadingTemplate, setisDownloadingTemplate] = useState(false);
    const [stateSelected, setStateSelected] = useState<number | null>(null)
    const [seletedFile, setSelectedFile] = useState<File | null>(null)


    const {
        isErrorlatestCalendarDate,
        isFetchedlatestCalendarDate,
        isFetchinglatestCalendarDate,
        isLoadinglatestCalendarDate,
        latestCalendarDate,
        refetchlatestCalendarDate
    } = useGetLatestCalendarDate({
        trigger: true
    })

    const {
        states,
        isError: isStateError,
        isFetching: isFetchingState,
        isLoading: isLoadingState
    } = useStatesAPI({
        trigger: true,
        // isState:1
    })

    const {
        downloadTemplateInstitutionalforecast,
        isDownloadingTEmplateExcelforecast,
        isDownloadExcelForecastTemplateError,
        validatInstitutionalforecastExel,
        isvalidateingInstitutionalforecastExel,
        isvalidatInstitutionalforecastExelError,
        validateProgress,
        uploadInstitutionalforecastExel,
        isuploadingInstitutionalforecastExel,
        uploadProgress
    } = useInstitutionalForcast({})

    const isAnyError = isStateError || isErrorlatestCalendarDate
    const isAnyFething = isFetchinglatestCalendarDate || isFetchingState
    const isLoading = isLoadingState || isLoadinglatestCalendarDate


    const headers: StandardTableHeader[] = [
        { label: 'Head', id: 'head', whiteSpace: 'nowrap' },
        { label: 'M1', id: 'M1', whiteSpace: 'nowrap' },
        { label: 'M2', id: 'M2', whiteSpace: 'nowrap' },
        { label: 'M3', id: 'M3', whiteSpace: 'nowrap' },
    ]

    const [employeesRow, setEmployeesRow] = useState<StandardTableRow[]>([
        {
            id: 'head',
            datas: {
                head: { id: 'head', data: 'Total' },
                M1: { id: 'M1', data: '' },
                M2: { id: 'M2', data: '' },
                M3: { id: 'M3', data: '' },
            }
        },
        {
            id: 'head',
            datas: {
                head: { id: 'head', data: 'NPD' },
                M1: { id: 'M1', data: '' },
                M2: { id: 'M2', data: '' },
                M3: { id: 'M3', data: '' },
            }
        },
    ])


    const {
        readtotalRegionForecast,
        isReadtotalRegionForecast,
        readtotalNPDForecast,
        isReadtotalNPDForecast
    } = useReadTotalForcasts()


    useEffect(
        () => {

            if (stateSelected) {
                readtotalRegionForecast({
                    stateId: stateSelected,
                    channel: 'Institution'
                }).then(
                    (res) => {

                        if (res.success) {
                            const data = res.data
                            const M1 = data.M1 || 0
                            const M2 = data.M2 || 0
                            const M3 = data.M3 || 0
                            readtotalNPDForecast({
                                stateId: stateSelected,
                                channel: 'Institution'
                            }).then(
                                (res2) => {
                                    if (res.success) {
                                        const data = res2.data
                                        const MNPB1 = data.M1 || 0
                                        const MNPB2 = data.M2 || 0
                                        const MNPB3 = data.M3 || 0
                                        setEmployeesRow([
                                            {
                                                id: 'head',
                                                datas: {
                                                    head: { id: 'head', data: 'Total' },
                                                    M1: { id: 'M1', data: M1 / 100000 },
                                                    M2: { id: 'M2', data: M2 / 100000 },
                                                    M3: { id: 'M3', data: M3 / 100000 },
                                                }
                                            },
                                            {
                                                id: 'head',
                                                datas: {
                                                    head: { id: 'head', data: 'NPD' },
                                                    M1: { id: 'M1', data: MNPB1 / 100000 },
                                                    M2: { id: 'M2', data: MNPB2 / 100000 },
                                                    M3: { id: 'M3', data: MNPB3 / 100000 },
                                                }
                                            },
                                        ])
                                    }
                                }
                            )

                        }

                    }
                )
            }

        }, [stateSelected]
    )



    if (isErrorlatestCalendarDate && !isFetchinglatestCalendarDate) {
        return (
            <div className=' grow flex flex-col items-center '>
                <ErrorBoxStandard
                    onRetry={refetchlatestCalendarDate}
                />
            </div>
        )
    }

    return (
        <div className=' grow  flex flex-col overflow-auto items-center '>


            {/* NP style wise */}
            <div className=' grow flex flex-col overflow-hidden bg-white dark:bg-vstargray-700 border rounded-md w-full text-center'>
                <div className=' grow flex flex-col overflow-auto p-2 '>
                    <TypographyH2>Institutional Forecast</TypographyH2>
                    <div className=' flex gap-2 '>
                        {/* <InputStandard
                            title='Date'
                            placeholder='Date'
                            type='date'
                            disabled
                            value={latestCalendarDate?.date || ''}
                        /> */}
                        <InputStandard
                            title='Date'
                            placeholder='Date'
                            disabled
                            value={latestCalendarDate?.date ? format(latestCalendarDate?.date, 'MMMM yyyy') : ''}
                        />
                        <SelectStandard
                            title='State'
                            placeHolder='Select State'
                            onChange={(e) => setStateSelected(Number(e.target.value))}
                            disabled={isAnyError || isAnyFething || isLoading}
                            options={states?.map(state => ({ value: String(state.FM04_StateID), label: state.FM04_StateName })) || []}
                        />
                    </div>
                    <div className=' py-2  flex justify-center'>
                        <div className=' border '>
                            <StandardTable
                                headers={headers}
                                rows={employeesRow}
                                isLoading={isReadtotalRegionForecast || isReadtotalNPDForecast}
                                rowsPerPrevousePage={2}
                                isUpdating={false}
                            />
                        </div>
                    </div>
                    <div className=' grow flex flex-col justify-center items-center  w-min mx-auto mt-8'>
                        <div className='  text-center flex text-vstargray-800 dark:text-vstargray-100 py-2'>Download Template {isDownloadingTEmplateExcelforecast ? <IconLoader2 size={10} className=' inline animate-spin' /> : <span onClick={() => downloadTemplateInstitutionalforecast({
                            date: latestCalendarDate?.date!,
                            stateId: stateSelected!
                        })} className=' underline text-blue-500 cursor-pointer hover:text-blue-700'>here</span>}</div>
                        <FileInputDragDropStandard
                            disabled={stateSelected === null || !latestCalendarDate?.date ? true : false}
                            onDrop={async (file) => {
                                const response = await validatInstitutionalforecastExel({
                                    file: file!
                                })
                                if (response.success === false) {
                                    if (response.invalid_product_codes.length > 0) {
                                        toast({
                                            title: 'Invalid product code',
                                            description: response.invalid_product_codes.join(', ')
                                        })
                                    } else if (response.invalid_style_codes.length > 0) {
                                        toast({
                                            title: 'Invalid style code',
                                            description: response.invalid_style_codes.join(', ')
                                        })
                                    }
                                } else {
                                    setSelectedFile(file);
                                }
                            }}
                            file={seletedFile}
                            onClear={() => setSelectedFile(null)}
                            accept={{ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] }}
                        />
                    </div>
                    <ButtonIconSquare
                        disabled={seletedFile === null}
                        className=' mx-auto mb-5'
                        icon={isuploadingInstitutionalforecastExel ? <IconLoader2 className='animate-spin' /> : <IconCloudUpload />}
                        onClick={async () => {
                            const response = await uploadInstitutionalforecastExel({
                                date: latestCalendarDate?.date!,
                                file: seletedFile!,
                                state: stateSelected!
                            })
                            if (response.success === false) {
                                toast({
                                    title: 'Error',
                                    description: response.message
                                })
                            } else {
                                toast({
                                    title: 'Success',
                                    description: response.message
                                })
                                setSelectedFile(null)
                            }
                        }}
                        variant='secondary'
                        text='Submit'
                    />
                </div>
            </div>

        </div>
    )
}

