import { useQuery } from "@tanstack/react-query"
import { useRestAPI } from "./axiosAPI"
import { MonthCode } from "./rest.forecast_regionwise"

export function useGetSEunderASMDbwiseTotals({
    retryCount,
    trigger = true,
    asmId,
    stateId,
    date
}: {
    retryCount?: number,
    trigger?: boolean,
    stateId:number,
    asmId:number,
    date:string
}) {

    const api = useRestAPI()
    // const [deleteRetryCount,setDeleteRetryCount] = useState(0)

    const {
        isLoading:isLoadingDbwiseTotalUnderASM_SE,
        isError: isErrorDBWiseTotals,
        data: DBwiseTotalUnderASM_SE,
        refetch: refetchDbwiseTotalUnderASM_SE,
        isFetching: isFetchingDBwiseTotalUnderASM_SE,
        isFetched: isFetchedDbwiseTotalUnderASM_SE,
        error: errorDBwiseTotalUnderASM_SE,
        isRefetchError: isErrorDBwiseTotalUnderASM_SE,
    } = useQuery({
        queryKey: ['np-dbwise-forecast-lines-se-totals', stateId,asmId,date],
        queryFn: async () => {
            const response = await api.get<{ data: Array<{
                    FT02_M03_EmployeeIDSE: number,
                    FT02_ForecastQty: number,
                    FT02_ForecastValue: number,
                    FT02_MonthCode: MonthCode
                }> }>({
                url: `${process.env.REACT_APP_BACKEND_URL}/transactions/np-dbwise-forecast-lines-se-totals`,
                mode: 'protected',
                params:{
                    stateId:stateId,
                    asmId:asmId,
                    date
                }
            })
            if (response.success === false) {
                
                throw response.message
            }

            response.data.map(item => {
                item.FT02_ForecastValue = item.FT02_ForecastValue/100000
            })

            return response
        },
        refetchOnWindowFocus: false,
        retry: retryCount,
        enabled: trigger,


    })







    return {
        isLoadingDbwiseTotalUnderASM_SE,
        isErrorDBWiseTotals,
        DBwiseTotalUnderASM_SE,
        refetchDbwiseTotalUnderASM_SE,
        isFetchingDBwiseTotalUnderASM_SE,
        isFetchedDbwiseTotalUnderASM_SE,
        errorDBwiseTotalUnderASM_SE,
        isErrorDBwiseTotalUnderASM_SE
    }


}