import React, { useEffect, useRef } from "react";
import { cn } from "src/lib/utils";
import { Label } from "src/shadcn/ui/label";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "src/shadcn/ui/select";


type onChangeParamType = {
  target: {
    name: string,
    value: string,
    label: string
  }
}

type SelectStandardProps = {
  onChange?: (e: onChangeParamType) => void,
  name?: string,
  value?: string,
  title?: string,
  error?: string,
  options: { value: string, label: string }[],
  placeHolder?: string,
  disabled?: boolean,
  defaultValue?: string
}

export const SelectStandard = React.forwardRef<HTMLSelectElement, SelectStandardProps>(
  (props, ref) => {

    const customRef = useRef<HTMLInputElement>(null)

    const onchangeSelect = (value: string) => {
      const selectedOption = props.options.find(option => option.value === value);
      const label = selectedOption ? selectedOption.label : '';
      const event: onChangeParamType = {
        target: {
          name: props.name!,
          value: value,
          label: label
        }
      }
      props.onChange?.(event)
    }




    return (
      <div className=" flex flex-col gap-1 ">
        <div className=" flex flex-col gap-2 items-start">
          {props.title ?
            <Label htmlFor={props.name} className="text-xs font-medium dark:text-vstargray-300" >
              {props.title}
            </Label>
            : null}
          <Select disabled={props.disabled} onValueChange={onchangeSelect} value={props.value} defaultValue={props.defaultValue} >
            <SelectTrigger
              // ref={ref as React.LegacyRef<HTMLButtonElement>}

              className={
                cn(
                  " bg-white border-gray-400  ",
                  props.error ? "border-red-500 data-[placeholder]:text-red-400 ring-vstarPrimary-200 focus:ring-vstarPrimary-200" : " ring-vstargray-200 data-[placeholder]:text-vstargray-500 ",
                )
              }
            >
              <SelectValue placeholder={props.placeHolder ?? 'Select a value'} />
            </SelectTrigger>
            <SelectContent className=" bg-vstargray-100 max-h-72">
              <SelectGroup>
                {
                  props.options.map((option) => (
                    <SelectItem className=" focus:bg-vstargray-200 cursor-pointer" key={option.value} value={option.value}>{option.label}</SelectItem>
                  ))
                }

              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        {props.error ? <p className="text-red-500 text-[10px]  leading-4 text-start">{props.error}</p> : null}
      </div>)
  }


)