import { IconCloudUp, IconLoader2, IconPlus, IconTrashFilled, IconX } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { PostSubAttributes, useSubAttributeAPI } from 'src/API/rest.subattribute'
import { ButtonIconSquare } from 'src/components/buttons'
import { StandardAlertDialog } from 'src/components/standard.alert-dialog'
import { StandardTable, StandardTableHeader, StandardTableRow, TablePlater } from 'src/components/standard.table'
import { toast } from 'src/shadcn/ui/use-toast'
import { Pagination, PaginationKeys } from 'src/types/pagination'
import { StandardTablePagination } from '../../../../../components/standard.pagination'
import { ModalAddSubAttribute } from './component.addModal'
import { DropdownMenuDemo, DropdownMenuMain } from './component.dropdownMenu'
import { ImportExcelModal } from './component.importExcelModal'
import { NPStylewiseRefUpload } from './component.assignStyleModal'
import { ImportExcelModalASPUpdate } from './component.importExcelModalASPUpdate'
import { ImportExcelModalActiveStyleUpload } from './component.importExcelModalActiveStyleUpload'
import { ImportExcelModalMRPUpdate } from './component.importExcelModalMRPUpdate copy'
import { useDebounce } from 'use-debounce'
import { InputStandard } from 'src/components/standard.input'
import { FilterModal } from './component.filterModal'
import { set } from 'date-fns'


export function SubAttributePage() {

    const searchParams = new URLSearchParams(window.location.search)
    const currentPageFromUrl = searchParams.get(PaginationKeys.CURRENT_PAGE)
    const dataPerPage = 15
    const [pagination, setPagination] = useState<Pagination>({
        currentPage: currentPageFromUrl ? parseInt(currentPageFromUrl) : 1,
        totalPages: Math.ceil(1 / dataPerPage) || 1,
        dataPerPage: dataPerPage
    })
    const [openImportModal, setOpenImportModal] = useState(false)
    const [openImportModalASPUpdate, setOpenImportModalASPUpdate] = useState(false)
    const [openImportModalMRPUpdate, setOpenImportModalMRPUpdate] = useState(false)
    const [openImportModalRefStyleUpload, setOpenImportModalRefStyleUpload] = useState(false)
    const [openNewStyleRefAssignModal, setopenNewStyleRefAssignModal] = useState(false)
    const [openActiveStylesUploadModal, setopenActiveStylesUploadModal] = useState(false)
    const [subAttributesRow, setSubAttributesRow] = useState<StandardTableRow[]>([])
    const [previousDataLength, setPreviousDataLength] = useState(0)
    const [openAddModal, setOpenAddModal] = useState(false)
    const [selectedSubAttributeId, setSelectedSubAttributeId] = useState<number | null | string>()
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [mode, setMode] = useState<'add' | 'edit'>('add')
    const [search, setSearch] = useState('')
    const [debouncedInput] = useDebounce(search, 500);
    const [openFilterModal, setOpenFilterModal] = useState(false)
    const [productCategory, setproductCategory] = useState<number[]>([]);
    const [productSubCategory, setproductSubCategory] = useState<number[]>([]);
    const [productId, setproductId] = useState<number[]>([]);
    const [status, setStatus] = useState<'All' | '1' | '0'>('1')
    const {
        subAttributes,
        error,
        isError,
        isFetched,
        isFetching,
        isLoading,
        totalSubAttributeCount,
        isDeletingSubAttribute,
        refetchSubAttributes,
        deleteSubAttributeById,

    } = useSubAttributeAPI({
        page_size: pagination.dataPerPage,
        page: pagination.currentPage,
        search: debouncedInput,
        FM14_M12_ProductCategory:productCategory.map(String),
        FM14_M12_ProductSubCategory:productSubCategory.map(String),
        FM14_M13_ProductID:productId.map(String),
        FM14_Status:status === 'All' ? undefined : Number(status) as 1 | 0,
    })

    const {
        downloadStyleDatas
    } = PostSubAttributes()



    const headers: StandardTableHeader[] = [
        { label: 'Code', id: 'sub_attribute_code' },
        { label: 'StyleName', id: 'sub_attribute_name' },
        { label: 'MRP', id: 'MRP' },
        { label: 'ABCCategory', id: 'ABCCategory' },
        { label: 'NoOfPC', id: 'NoOfPC' },
        { label: 'Status', id: 'status' },
        { label: '', id: 'actions', width: '10px' },
    ]



    const deleteSubAttribute = async () => {

        if (selectedSubAttributeId) {
            const response = await deleteSubAttributeById({
                subAttributeId: selectedSubAttributeId
            })
            if (response.success === true) {
                toast({
                    title: 'Success',
                    description: response.message,
                })
                refetchSubAttributes()
                setOpenDeleteModal(false)
            } else {
                toast({
                    title: 'Error',
                    description: response.message,
                })
            }
            setSelectedSubAttributeId(null)
        }

    }


    useEffect(
        () => {
            // setting prevouse data length for loading optimization
            if (isFetched && !isFetching) {
                console.log(subAttributes?.length)
                setPreviousDataLength(subAttributes?.length || 0)
            }

            //if successfully loaded
            if (isFetched && !isError) {
                setPagination({
                    currentPage: currentPageFromUrl ? parseInt(currentPageFromUrl) : 1,
                    totalPages: Math.ceil(totalSubAttributeCount / dataPerPage) || 1,
                    dataPerPage: dataPerPage
                })
                setSubAttributesRow(subAttributes?.map(
                    (subAttribute) => {
                        return ({
                            id: String(subAttribute.FM14_SubAttributeID),
                            datas: {
                                sub_attribute_code: { id: 'sub_attribute_code', data: subAttribute.FM14_SubAttributeCode },
                                sub_attribute_name: { id: 'sub_attribute_name', data: subAttribute.FM14_SubAttributeName },
                                MRP: { id: 'MRP', data: subAttribute.FM14_MRP },
                                ABCCategory: { id: 'ABCCategory', data: subAttribute.FM14_ABCCategory },
                                NoOfPC: { id: 'NoOfPC', data: subAttribute.FM14_NoOfPC },
                                status: { id: 'status', data: subAttribute.FM14_Status ? 'Active' : 'Inactive' },
                                actions: {
                                    id: 'actions', data: <DropdownMenuDemo
                                        rowId={String(subAttribute.FM14_SubAttributeID)}
                                        onDeleteAction={(rowid) => {
                                            setSelectedSubAttributeId(rowid);
                                            setOpenDeleteModal(true);
                                        }}
                                        onEditAction={(rowid) => {
                                            setSelectedSubAttributeId(rowid);
                                            setMode('edit');
                                            setOpenAddModal(true);
                                        }}
                                    />
                                }
                            }
                        })

                    }
                ) || [])
            }
            if (isError && isFetching === false) {
                toast({
                    description: String(error),
                })

            }
        }, [isFetching]
    )








    return (
        <div className=' grow  flex flex-col overflow-auto'>
            <StandardAlertDialog
                title="Are you absolutely sure?"
                description="This action cannot be undone. Are you sure you want to delete this sub attribute?"
                positiveLabel="Delete"
                negativeLabel="Cancel"
                positiveAction={deleteSubAttribute}
                negativeAction={() => setOpenDeleteModal(false)}
                positiveIcon={isDeletingSubAttribute ? <IconLoader2 size={16} className=" animate-spin" /> : <IconTrashFilled size={16} />}
                negativeIcon={<IconX size={16} />}
                disableNegativeButton={isDeletingSubAttribute}
                disablePositiveButton={isDeletingSubAttribute}
                show={openDeleteModal}
            />
            <ModalAddSubAttribute
                show={openAddModal}
                setShow={setOpenAddModal}
                refetchSubAttributes={refetchSubAttributes}
                mode={mode}
                subAttributeId={selectedSubAttributeId}
            />
            <ImportExcelModal
                show={openImportModal}
                setShow={setOpenImportModal}
            />
            <ImportExcelModalASPUpdate
                show={openImportModalASPUpdate}
                setShow={setOpenImportModalASPUpdate}
            />

            <ImportExcelModalMRPUpdate
                show={openImportModalMRPUpdate}
                setShow={setOpenImportModalMRPUpdate}
            />

            <NPStylewiseRefUpload
                show={openNewStyleRefAssignModal}
                setShow={setopenNewStyleRefAssignModal}
            />
            <ImportExcelModalActiveStyleUpload
                show={openActiveStylesUploadModal}
                setShow={setopenActiveStylesUploadModal}
            />
            <FilterModal
                ProductCategory={productCategory}
                productSubCategory={productSubCategory}
                productId={productId}
                show={openFilterModal}
                setShow={setOpenFilterModal}
                status={status}
                search={(filter) => {
                    setproductCategory(filter.productCategory);
                    setproductSubCategory(filter.productSubCategory);
                    setproductId(filter.productId);
                    setStatus(filter.status);
                }}
            />
            <TablePlater className=' flex flex-col gap-2'>
                {/* Buttons section */}
                <div className=' flex justify-end w-full gap-4'>
                    <InputStandard onChange={(e) => setSearch(e.target.value)} className=' lg:w-[400px] ' placeholder='Search' />

                    {/* <ButtonIconSquare variant="white" icon={<IconCloudUp />} text="Import" onClick={() => { setOpenImportModal(true) }} /> */}
                    <ButtonIconSquare variant="secondary" icon={<IconPlus />} text="Add Style" onClick={() => { setMode('add'); setOpenAddModal(true); }} />
                    <DropdownMenuMain
                        onImportExcel={() => { setOpenImportModal(true) }}
                        onImortExcelASPUpdate={() => { setOpenImportModalASPUpdate(true) }}
                        onImortExcelMRPUpdate={() => { setOpenImportModalMRPUpdate(true) }}
                        onImportExcelRefStyle={() => setopenNewStyleRefAssignModal(true)}
                        onImportActiveStyle={() => setopenActiveStylesUploadModal(true)}
                        onFilterButtonPressed={() => setOpenFilterModal(true)}
                        onIconDownloadPressed={() => downloadStyleDatas()}
                    />
                </div>
                <StandardTable
                    headers={headers}
                    rows={subAttributesRow}
                    isLoading={isLoading}
                    rowsPerPrevousePage={previousDataLength}
                    isUpdating={isFetching}
                    isError={isError && isFetching === false}
                    onRetry={() => refetchSubAttributes()}
                />
                <StandardTablePagination currentPage={pagination.currentPage} totalPages={pagination.totalPages} onChangePage={(page) => {
                    setPagination({ ...pagination, currentPage: page })
                }} />

            </TablePlater>
        </div>
    )

}


