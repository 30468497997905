import { IconBoxModel2, IconBrandSuperhuman, IconCalendarFilled, IconChartBar, IconChevronCompactDown, IconChevronLeft, IconChevronRight, IconChevronsLeft, IconChevronsRight, IconHome, IconRegistered, IconSettings, IconSquarePercentage, IconSquaresFilled, IconTir, IconUser, IconUsers } from '@tabler/icons-react'
import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useConfigurationRQ } from '../queryState/configuration.state'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import { useSideNavAPI } from '../API/sideNavAPI'
import { ScrollArea } from '../shadcn/ui/scroll-area'
import { ButtonIconSquare } from './buttons'
import { useGetAllowedMenus } from 'src/API/rest.role'
import { isAllowedPermission } from 'src/lib/utils'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from 'src/shadcn/ui/accordion'
import { ReactComponent as NewProductStyleIcon } from '../icons/np_stylewise.svg';
import { ReactComponent as NewProductDBwiseIcon } from '../icons/np_dbwise.svg';
import { ReactComponent as SEallotmentIcon } from '../icons/se_allotment.svg';
import { ReactComponent as ExecutiveWise } from '../icons/executive_wise.svg';
import { ReactComponent as EBOForcast } from '../icons/ebo_forecast.svg';
import { ReactComponent as InstitutionForcast } from '../icons/institutionForcast.svg';
import { ReactComponent as ExportForcast } from '../icons/export_forecast.svg';
import { ReactComponent as OnlineForecastIcon } from '../icons/online_forecast.svg';
export interface NavbarButtonProps {
    icon: React.ReactNode,
    text: string,
    isSelected: boolean,
    href: string,
    isCollapsed?: boolean,
    permissionId: string
}

export function NavbarButton({
    icon,
    text,
    isSelected,
    href,
    isCollapsed
}: NavbarButtonProps) {
    const [isActive, setActive] = React.useState(false)

    return (
        <NavLink to={href} className={(
            state
        ) => {
            setActive(state.isActive)
            return ` ${isCollapsed ? 'justify-center' : 'justify-start px-3'} ${state.isActive ? 'bg-white text-white  dark:bg-white/50 shadow-sm' : ''} flex gap-2 items-center  w-full py-3 px-1 hover:bg-white dark:hover:bg-white/50 rounded-xl text-xs`
        }}>
            <div className={`${isActive ? 'bg-vstarPrimary-500' : 'bg-white'} w-[35px] h-[35px]  rounded-[13px] flex items-center justify-center overflow-hidden`}>{icon}</div>
            <span className={`text-xs font-medium text-black whitespace-nowrap truncate ${isCollapsed ? 'hidden' : ''} ${isActive ? ' dark:text-vstargray-100' : 'dark:text-vstargray-300'} `}>{text}</span>
        </NavLink >
    )
}

export function NavbarOuter({
    text,
    sideNavCollapseState,
    children
}: {
    text: string,
    sideNavCollapseState: 'open' | 'close',
    children: React.ReactNode
}) {

    const [open, setOpen] = React.useState(false)

    const onClick = () => {
        setOpen(!open)
    }

    return (
        <Accordion  type="multiple" className=' flex flex-col w-full'>
            <AccordionItem  value={text}>
                <AccordionTrigger>
                    {sideNavCollapseState === 'open' ?
                        <p className=' uppercase text-sm font-semibold px-3  dark:text-vstargray-300 min-h-8 max-h-8 flex items-center'>{text}</p>
                        :
                        <p className=' uppercase text-xl font-semibold px-3  dark:text-vstargray-600 text-center min-h-8 max-h-8 flex items-center justify-center'>{text?.[0]}</p>}
                </AccordionTrigger>
                <AccordionContent>
                    {
                        children
                    }
                </AccordionContent>
            </AccordionItem>
        </Accordion>
    )
}

export default function SideNavbar() {

    const {
        hideSideNav,
        collapseSideNav,
        showSideNav,
        unCollapseSideNav,
        sideNavState,
        sideNavCollapseState
    } = useSideNavAPI()

    const commonNavBar: NavbarButtonProps[] = [
        {
            icon: <IconHome />,
            text: 'Dashboard',
            isSelected: true,
            href: '/home/dashboard',
            permissionId: ''
        }
    ]
    const MasterSideNavBars: NavbarButtonProps[] = [
        {
            icon: <IconTir />,
            text: 'Distributer',
            isSelected: false,
            href: 'master/distributor',
            permissionId: 'distributerID'
        },
        {
            icon: <IconBrandSuperhuman />,
            text: 'Product',
            isSelected: false,
            href: 'master/product',
            permissionId: 'productID'
        },

        {
            icon: <IconBoxModel2 />,
            text: 'Style',
            isSelected: false,
            href: 'master/subattribute',
            permissionId: 'subAttributeID'
        },
        {
            icon: <IconSquaresFilled />,
            text: 'SKU',
            isSelected: false,
            href: 'master/sku',
            permissionId: 'skuID'
        },
        {
            icon: <IconUsers />,
            text: 'Employees',
            isSelected: false,
            href: 'authentication/employees',
            permissionId: 'EmployeesID'
        },
        {
            icon: <IconCalendarFilled />,
            text: 'Role',
            isSelected: false,
            href: 'authentication/role',
            permissionId: 'RoleID'
        },
        // {
        //     icon: <IconUser />,
        //     text: 'Distributor mapping',
        //     isSelected: false,
        //     href: 'master/distributor-mapping',
        //     permissionId: 'distributorID'
        // },

    ]

    const ForcastSideTabs: NavbarButtonProps[] = [
        {
            icon: <IconCalendarFilled />,
            text: 'Calender',
            isSelected: false,
            href: 'transaction/forecast-calender',
            permissionId: 'CalenderID'
        },
        {
            icon: <IconRegistered />,
            text: 'RegionWise',
            isSelected: false,
            href: 'transaction/forecast-regirionwise',
            permissionId: 'RegionWiseID'
        },
        {
            icon: <NewProductStyleIcon />,
            text: 'NP Stylewise',
            isSelected: false,
            href: 'transaction/new-product-stylewise',
            permissionId: 'NPStyleWiseID'
        },
        {
            icon: <NewProductDBwiseIcon />,
            text: 'NP DBwise',
            isSelected: false,
            href: 'transaction/new-product-dbwise',
            permissionId: 'NPDBwiseID'
        },
        {
            icon: <SEallotmentIcon />,
            text: 'SE allotment',
            isSelected: false,
            href: 'transaction/se-allotment',
            permissionId: 'NPDBwiseID'
        },
        {
            icon: <ExecutiveWise />,
            text: 'ExecutiveWise',
            isSelected: false,
            href: 'transaction/executive-wise',
            permissionId: 'ExecutiveWiseID'
        },
        {
            icon: <EBOForcast />,
            text: 'EBO forecast',
            isSelected: false,
            href: 'transaction/ebo-forecast-page',
            permissionId: 'EBOForecastID'
        },
        {
            icon: <InstitutionForcast />,
            text: 'Institutional forecast',
            isSelected: false,
            href: 'transaction/institutional-forecast-page',
            permissionId: 'EBOForecastID'
        },
        {
            icon: <ExportForcast />,
            text: 'Export forecast',
            isSelected: false,
            href: 'transaction/export-forecast-page',
            permissionId: 'ExportForecastID'
        },
        {
            icon: <OnlineForecastIcon />,
            text: 'Online forecast',
            isSelected: false,
            href: 'transaction/online-forecast-page',
            permissionId: 'OnlineForecastcastID'
        },
    ]


    const {
        rolesAllowedMenus,
        isFetchedGetAllowdMenus,
        isFetchingGetAllowdMenus,
        refetchRolesAllowedMenus
    } = useGetAllowedMenus({
        menuIds: [
            "masterID",
            "distributerID",
            "productID",
            "subAttributeID",
            "skuID",
            "distributorID",
            "ForecastId",
            "CalenderID",
            "RegionWiseID",
            "NPStyleWiseID",
            "NPDBwiseID",
            "SEAllotmentID",
            "ExecutiveWiseID",
            "EBOForecastID",
            "InstitutionalID",
            "ExportForecastID",
            "OnlineForecastcastID",
            "AuthenticationID",
            "EmployeesID",
            "RoleID",
        ]
    })


    return (
        <>
            <nav style={{
                left: sideNavState === 'open' ? '0' : '-230px',
                top: 0,
                bottom: 0
            }} className={` transition-all overflow-hidden duration-100 z-50 min-h-screen ${sideNavCollapseState === 'open' ? 'w-[230px] max-w-[230px] min-w-[230px]  px-4' : 'w-[80px] max-w-[80px] min-w-[80px] px-1'} py-1 fixed bg-vstargray-200 sm:bg-transparent  sm:sticky border-r sm:border-none border-vstargray-400 shadow-lg sm:shadow-none`}>
                <ScrollArea type='always' className={`sample h-full w-full  ${sideNavCollapseState === 'open' ? 'pb-14' : ' pb-24'}`}>
                    <div className=' '>
                        <div className=' w-full flex justify-center py-5 '>
                            <img className=' w-[140px] h-[30px]' style={{ display: sideNavCollapseState === 'open' ? 'block' : 'none' }} src="/assets/logo/logo_big.png" alt="" />
                            <img className=' w-[40px] object-fill h-[30px]' style={{ display: sideNavCollapseState === 'close' ? 'block' : 'none' }} src="/assets/logo/logo_small.png" alt="" />
                        </div>
                        <div className='h-[1px] bg-gradient-to-r from-transparent via-white to-transparent rounded-full'></div>
                        <div className=' w-full flex flex-col gap-2 pt-5'>
                            {/* <NavbarButton isSelected={true} icon={<IconHome/>} text='Dashboard' /> */}
                            {
                                commonNavBar.map((item, index) => <NavbarButton  {...item} isCollapsed={sideNavCollapseState === 'close'} key={index} />)
                            }
                        </div>
                        <div className='h-2'></div>
                        
                        {
                            isAllowedPermission(rolesAllowedMenus?.data, 'masterID') ?
                                <>
                                    <NavbarOuter text='Master' sideNavCollapseState={sideNavCollapseState}>
                                        <div className=' w-full flex flex-col gap-2 px-1'>
                                            {/* <NavbarButton isSelected={true} icon={<IconHome/>} text='Dashboard' /> */}
                                            {
                                                MasterSideNavBars.filter(item => isAllowedPermission(rolesAllowedMenus?.data, item.permissionId)).map((item, index) => <NavbarButton {...item} isCollapsed={sideNavCollapseState === 'close'} key={index} />)
                                            }
                                        </div>

                                    </NavbarOuter>
                                </>
                                : null}

                                <div className='h-2'></div>

                        {
                            isAllowedPermission(rolesAllowedMenus?.data, 'ForecastId') ? <>
                                <NavbarOuter text='Forecast' sideNavCollapseState={sideNavCollapseState} >
                                    <div className=' w-full flex flex-col gap-2 px-1'>
                                        {/* <NavbarButton isSelected={true} icon={<IconHome/>} text='Dashboard' /> */}
                                        {
                                            ForcastSideTabs.filter(item => isAllowedPermission(rolesAllowedMenus?.data, item.permissionId)).map((item, index) => <NavbarButton {...item} isCollapsed={sideNavCollapseState === 'close'} key={index} />)
                                        }
                                    </div>

                                </NavbarOuter>
                            </> : null}
                        {/* {
                            isAllowedPermission(rolesAllowedMenus?.data, 'AuthenticationID') ?
                                <>
                                    {sideNavCollapseState === 'open' ?
                                        <p className=' uppercase text-sm font-semibold px-3  dark:text-vstargray-600 min-h-8 max-h-8 flex items-center'>Authentication</p>
                                        :
                                        <p className=' uppercase text-xl font-semibold px-3  dark:text-vstargray-600 text-center min-h-8 max-h-8 flex items-center justify-center'>A</p>}
                                    <div className=' w-full flex flex-col gap-2'>
                                        {
                                            AuthenticationSideTabs.filter(item => isAllowedPermission(rolesAllowedMenus?.data, item.permissionId)).map((item, index) => <NavbarButton {...item} isCollapsed={sideNavCollapseState === 'close'} key={index} />)
                                        }
                                    </div>
                                </>
                                : null} */}
                        <div className=' absolute bottom-0 py-2 pe-3 flex justify-between w-full'>
                            <div className={` ${sideNavCollapseState === 'open' ? 'flex-row' : ' flex-col'} flex gap-2 items-center w-full  `}>
                                <IconSettings className=' dark:text-white' />
                                {
                                    sideNavCollapseState === 'open'
                                        ?
                                        <div className=' text-xs grow dark:text-vstargray-100'>Settings</div>
                                        :
                                        null
                                }
                                {sideNavCollapseState === 'open'
                                    ?
                                    <ButtonIconSquare onClick={collapseSideNav} icon={<IconChevronsLeft />} />
                                    :
                                    <ButtonIconSquare onClick={unCollapseSideNav} icon={<IconChevronsRight />} />
                                }
                            </div>
                        </div>
                    </div>
                </ScrollArea>
                {/* bottom buttons */}
            </nav>
            {/* overlay */}
            <div style={{
                zIndex: 10,
                position: 'fixed',
            }} onClick={hideSideNav} className={`fixed inset-0 ${sideNavState === 'open' ? ' block sm:hidden' : ' hidden'}`}></div>
        </>
    )
}
