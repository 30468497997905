import { IconCalendarFilled, IconEdit, IconPlus } from '@tabler/icons-react'
import { format, parse } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useForecastAPI } from 'src/API/rest.forecast'
import { useGetLatestCalendarDate } from 'src/API/rest.forecast_calender'
import { MonthCode } from 'src/API/rest.forecast_regionwise'
import { useRegionAPI } from 'src/API/rest.region'
import { ButtonIconSquare } from 'src/components/buttons'
import { InputStandard } from 'src/components/standard.input'
import { SelectStandard } from 'src/components/standard.select'
import { StandardTable, StandardTableHeader, StandardTableRow } from 'src/components/standard.table'
import { Input } from 'src/shadcn/ui/input'
import { ModalAddForecastModal } from './component.modalForecast'

export default function ForcastRegionWisePage() {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedDate, setSelectedDate] = useState(
        ''
    );

    const [dates, setDates] = useState<{
        monthCode: MonthCode;
        month: string;
    }[]>([])




    const [monthCode, setMonthCode] = useState<{
        monthCode: MonthCode;
        month: string;
    }>({
        monthCode: dates?.[0]?.monthCode || '',
        month: dates?.[0]?.month || ''
    });


    const [RegionForcastRows, setRegionForcastRows] = useState<StandardTableRow[]>([])
    const headers: StandardTableHeader[] = [
        { label: 'SINo', id: 'SINo' },
        { label: 'Region', id: 'Region' },
        { label: 'Distributer', id: 'Distributer' },
        { label: 'EBO', id: 'EBO' },
        { label: 'Institution', id: 'Institution' },
        { label: 'Other', id: 'Other' },
        { label: 'Total', id: 'Total' },
    ]

    const [mode, setMode] = useState<'scheduled' | 'unScheduled'>('unScheduled')

    // const {
    //     isLoading: isLoadingForecastRegionWise,
    //     isError: isErrorForecastRegionWise,
    //     isFetching: isFetchingForecastRegionWise,
    //     isFetched: isFetchedForcastRegionWise,
    //     forcastRegionwise,
    //     refetchforcastRegionwise
    // } = useForecastRegionwiseAPI({
    //     date: `${selectedDate}-01`,
    //     MonthCode: monthCode?.monthCode
    // })

    const {
        forecastData: forecastLines,
        isErrorforcast: isErrorLoadingForecastLine,
        isFetchingForcastData: isFetchingForecastLine,
        isFetchedForcastData: isFethedForecastLine,
        isLoadingforcast: isLoadingForecastLine,
        refetchForcastData: refetchForecastLine
    } = useForecastAPI({
        trigger: selectedDate ? true : false,
        FT02_Date: `${selectedDate}-01`,
        FT02_MonthCode: monthCode?.monthCode,
        FT02_ForecastLevel: 'Region',
    })

    const {
        isLoading: isLoadingRegion,
        regions,
        isFetching: isFetchingRegion,
        refetchRegions,
        isError: isErrorRegion,
        isFetched: isFetchedRegion
    } = useRegionAPI({
        trigger: true
    })

    const {
        isErrorlatestCalendarDate,
        isFetchedlatestCalendarDate,
        isFetchinglatestCalendarDate,
        isLoadinglatestCalendarDate,
        latestCalendarDate,
        refetchlatestCalendarDate
    } = useGetLatestCalendarDate({
        trigger: true
    })


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value)
        setSelectedDate(event.target.value);
        // refetchforcastRegionwise()
    };

    const isAnythingLoading = isLoadingRegion || isLoadingForecastLine || isLoadinglatestCalendarDate
    const isAnythingFetching = isFetchingRegion || isFetchingForecastLine || isFetchinglatestCalendarDate
    const isAnyError = isErrorRegion || isErrorLoadingForecastLine || isErrorLoadingForecastLine


    // after fetching data
    useEffect(
        () => {
            if (!isErrorlatestCalendarDate && latestCalendarDate) {
                // setting month codes
                const dates: {
                    monthCode: MonthCode;
                    month: string;
                }[] = [];

                let selectedDateCurrent

                if (!latestCalendarDate.date) {
                    const currentDate = new Date();
                    const formattedDate = currentDate.toISOString().substring(0, 10);
                    if (selectedDate) {
                        selectedDateCurrent = new Date(selectedDate).toISOString().substring(0, 7)
                        setSelectedDate(new Date(selectedDate).toISOString().substring(0, 7));
                    } else {
                        selectedDateCurrent = formattedDate?.substring(0, 7)
                        setSelectedDate(formattedDate?.substring(0, 7));
                    }
                } else {
                    if (selectedDate) {
                        setSelectedDate(new Date(selectedDate).toISOString().substring(0, 7));
                        selectedDateCurrent = new Date(selectedDate).toISOString().substring(0, 7)

                    }
                    else {
                        setSelectedDate(latestCalendarDate?.date?.substring(0, 7));
                        selectedDateCurrent = latestCalendarDate?.date?.substring(0, 7)
                    }
                }
                const d = new Date(selectedDateCurrent);
                for (let i = 0; i < 3; i++) {
                    d.setMonth(d.getMonth() + 1);
                    dates.push({
                        monthCode: 'M' + (i + 1) as MonthCode,
                        month: d.toISOString().substring(0, 7)
                    });
                }
                setDates(dates)
                // console.log('dates',dates)
                // if (!monthCode?.monthCode)
                    const codeIndex = dates.findIndex(date => date.monthCode === monthCode.monthCode)
                    setMonthCode({
                        monthCode: dates?.[codeIndex<0?0:codeIndex]?.monthCode || '',
                        month: dates?.[codeIndex<0?0:codeIndex]?.month || ''
                    })
            }
            if (!isAnythingLoading && !isAnyError) {

                if (regions?.length && forecastLines?.ForecastLines?.length) {
                    setMode('scheduled')
                } else {
                    setMode('unScheduled')
                }

                console.log(forecastLines)

                const RegionForcastRows: StandardTableRow[] = regions?.map(
                    (region, index) => {

                        let DistributerData = forecastLines?.ForecastLines?.find((data) => data.FT02_FM07_RegionID === region.FM07_RegionID && data.FT02_ForecastChannel === 'Distributor')
                        let EBO = forecastLines?.ForecastLines?.find((data) => data.FT02_FM07_RegionID === region.FM07_RegionID && data.FT02_ForecastChannel === 'EBO')
                        let Institution = forecastLines?.ForecastLines?.find((data) => data.FT02_FM07_RegionID === region.FM07_RegionID && data.FT02_ForecastChannel === 'Institution')
                        let Other = forecastLines?.ForecastLines?.find((data) => data.FT02_FM07_RegionID === region.FM07_RegionID && data.FT02_ForecastChannel === 'Other')

                        DistributerData = DistributerData ? { ...DistributerData } : DistributerData
                        EBO = EBO ? { ...EBO } : EBO
                        Institution = Institution ? { ...Institution } : Institution
                        Other = Other ? { ...Other } : Other

                        const DistributerValue = DistributerData?.FT02_ForecastValue ? DistributerData.FT02_ForecastValue / 100000 : 0
                        const EBOValue = EBO?.FT02_ForecastValue ? EBO.FT02_ForecastValue / 100000 : 0
                        const InstitutionValue = Institution?.FT02_ForecastValue ? Institution.FT02_ForecastValue / 100000 : 0
                        const OtherValue = Other?.FT02_ForecastValue ? Other.FT02_ForecastValue / 100000 : 0


                        return {
                            id: region.FM07_RegionID,
                            datas: {
                                SINo: { id: 'SINo', data: index + 1 },
                                Region: { id: 'Region', data: region.FM07_RegionName },
                                Distributer: { id: 'Distributer', data: DistributerValue },
                                EBO: { id: 'EBO', data: EBOValue },
                                Institution: { id: 'Institution', data: InstitutionValue },
                                Other: { id: 'Other', data: OtherValue },
                                Total: { id: 'Total', data: DistributerValue + EBOValue + InstitutionValue + OtherValue }
                            }
                        }

                    }
                ) || []

                const distributerTotal = RegionForcastRows.map(row => row.datas.Distributer.data).reduce((a, b) => Number(a) + Number(b), 0)
                const EBOTotal = RegionForcastRows.map(row => row.datas.EBO.data).reduce((a, b) => Number(a) + Number(b), 0)
                const InstitutionTotal = RegionForcastRows.map(row => row.datas.Institution.data).reduce((a, b) => Number(a) + Number(b), 0)
                const OtherTotal = RegionForcastRows.map(row => row.datas.Other.data).reduce((a, b) => Number(a) + Number(b), 0)
                const totalTotal = RegionForcastRows.map(row => row.datas.Total.data).reduce((a, b) => Number(a) + Number(b), 0)
                RegionForcastRows.push({
                    id: 'Total',
                    datas: {
                        SINo: { id: 'SINo', data: '' },
                        Region: { id: 'Region', data: '' },
                        Distributer: { id: 'Distributer', data: distributerTotal },
                        EBO: { id: 'EBO', data: EBOTotal },
                        Institution: { id: 'Institution', data: InstitutionTotal },
                        Other: { id: 'Other', data: OtherTotal },
                        Total: { id: 'Total', data: totalTotal }

                    }
                })

                setRegionForcastRows(RegionForcastRows)



            }


        }, [isFetchingForecastLine, selectedDate, isFetchingRegion, isFetchinglatestCalendarDate]
    )

    console.log('this is date',monthCode)

    return (
        <div className=' grow  flex flex-col overflow-auto items-center'>
            <ModalAddForecastModal
                show={isOpen}
                refetchforcastRegionwise={refetchForecastLine}
                setShow={(show) => setIsOpen(show)}
                selectedDate={selectedDate}
                MonthCode={monthCode}

            />

            {mode === 'scheduled' ?
                <div className=' w-full'>
                    <div className=' gap-1 pb-2 dark:text-vstargray-400 flex  items-center justify-end '>

                        <InputStandard type='month' className='w-min' onChange={handleChange} value={selectedDate} />
                        <SelectStandard
                            options={dates.map(data => ({ label: `${data.monthCode} - ${data.month}`, value: data.monthCode }))}
                            placeHolder='Month Code'
                            onChange={(data) => {

                                const selectedMonth = dates.find(date => date.monthCode === data.target.value)
                                setMonthCode({
                                    monthCode: selectedMonth?.monthCode!,
                                    month: selectedMonth?.month as string
                                })
                            }}
                            value={monthCode?.monthCode}
                        />
                        <ButtonIconSquare disabled={isAnythingLoading} onClick={() => setIsOpen(true)} icon={<IconEdit />} text='Edit' variant='secondary' />

                    </div>
                    <StandardTable
                        headers={headers}
                        isLoading={isLoadingForecastLine}
                        isUpdating={isAnythingLoading}
                        rows={RegionForcastRows}
                    />
                </div>
                :
                <div className=' flex flex-col items-center justify-center h-min my-auto'>
                    <IconCalendarFilled className=' dark:text-vstargray-300' size={80} />
                    <p className=' font-bold text-lg dark:text-vstargray-300'>Select Date</p>
                    <div className=' gap-1 p-5 dark:text-vstargray-400 flex flex-col items-center justify-center '>
                        <Input type='month' onChange={handleChange} value={selectedDate} />

                        <div className=' flex gap-1 items-center'>
                            <SelectStandard
                                options={dates.map(data => ({ label: `${data.monthCode}`, value: data.monthCode }))}
                                placeHolder='Month Code'
                                onChange={(data) => {

                                    const selectedMonth = dates.find(date => date.monthCode === data.target.value)
                                    setMonthCode({
                                        monthCode: selectedMonth?.monthCode!,
                                        month: selectedMonth?.month as string
                                    })
                                }}
                                value={monthCode?.monthCode}
                            />
                            <div>{monthCode?.month ?
                                format(parse(`${monthCode.month}-01`, 'yyyy-MM-dd', new Date()), 'MMM-yyyy')
                                :
                                null
                            }</div>
                        </div>

                    </div>
                    <div className='  flex flex-col items-center gap-2'>
                        <p className=' font-bold text-2xl !!! dark:text-vstargray-400'>No RegionWise Forecasts Found</p>
                        <ButtonIconSquare disabled={isAnythingLoading} onClick={() => setIsOpen(true)} variant='secondary' text=' Create Forcast' icon={<IconPlus />} />
                    </div>
                </div>
            }
        </div>
    )
}

