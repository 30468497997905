import { IconCloudUpload, IconLoader2 } from '@tabler/icons-react'
import { format, parse } from 'date-fns'
import { useState } from 'react'
import { useDBwiseForcast } from 'src/API/rest.dbwiseForecast'
import { useGetLatestCalendarDate } from 'src/API/rest.forecast_calender'
import { ButtonIconSquare } from 'src/components/buttons'
import { ProgressFullScreen } from 'src/components/progressLoading'
import { ErrorBoxStandard } from 'src/components/standard.errorbox'
import { FileInputDragDropStandard } from 'src/components/standard.fileDroper'
import { InputStandard } from 'src/components/standard.input'
import { TypographyH2 } from 'src/components/typography'
import { Input } from 'src/shadcn/ui/input'
import { toast } from 'src/shadcn/ui/use-toast'
import { RefMonthComponent } from './component.refMonth'


type MonthType = {
    month: string;
    value: string;
}

export default function ForcastDBWise() {
    const [isOpen, setIsOpen] = useState(false)
    const [isDownloadingTemplate, setisDownloadingTemplate] = useState(false);
    const [seletedFile, setSelectedFile] = useState<File | null>(null)
    const [standardMonths, setStandardMonths] = useState<MonthType[]>([
        { month: 'LYM1', value: '' },
        { month: 'LYM2', value: '' },
        { month: 'LYM3', value: '' },
        { month: 'M-3', value: '' },
        { month: 'M-2', value: '' },
        { month: 'M-1', value: '' },
    ]);


    const {
        isErrorlatestCalendarDate,
        isFetchedlatestCalendarDate,
        isFetchinglatestCalendarDate,
        isLoadinglatestCalendarDate,
        latestCalendarDate,
        refetchlatestCalendarDate
    } = useGetLatestCalendarDate({
        trigger: true
    })

    const {
        downloadTemplateDBwise,
        isDownloadingTEmplateDBwise,
        isDownloadDBwiseTemplateError,
        validateDBwiseExel,
        isvalidateingDBwiseExel,
        isvalidateDBwiseExelError,
        validateProgress,
        uploadDBwiseExel,
        isuploadingDBwiseExel,
        uploadProgress
    } = useDBwiseForcast({})

    const isAnyError = isErrorlatestCalendarDate




    if (isErrorlatestCalendarDate && !isFetchinglatestCalendarDate) {
        return (
            <div className=' grow flex flex-col items-center '>
                <ErrorBoxStandard
                    onRetry={refetchlatestCalendarDate}
                />
            </div>
        )
    }

    return (
        <div className=' grow  flex flex-col overflow-auto items-center '>

            {/* NP style wise */}
            <div className=' grow flex flex-col overflow-auto w-full text-center'>
                <ProgressFullScreen
                    progress={validateProgress}
                    show={isvalidateingDBwiseExel}
                    subTitle='Validating file...'
                />
                <ProgressFullScreen
                    progress={uploadProgress}
                    show={isuploadingDBwiseExel}
                    subTitle='uploading file...'
                />
                {/* Ref Month */}
                <RefMonthComponent
                    forecastMonth={latestCalendarDate?.date || ''}
                    isFetchingLatestForecastCalender={isFetchinglatestCalendarDate}
                    setStandardMonths={setStandardMonths}
                    standardMonths={standardMonths}
                />
                <div className=' bg-white dark:bg-vstargray-900 rounded-md mt-2 grow flex flex-col  p-2 '>
                    <TypographyH2>NP DB wise</TypographyH2>
                    <div className=' flex gap-2'>
                        {/* <InputStandard
                            title='Date'
                            placeholder='Date'
                            type='date'
                            disabled
                            value={latestCalendarDate?.date || ''}
                        /> */}
                        <Input
                            type='month'
                            className='w-min'
                            value={latestCalendarDate?.date ? format(parse(latestCalendarDate?.date, 'yyyy-mm-dd', new Date()), 'yyyy-mm') : ''}
                            disabled
                        />

                    </div>
                    <div className=' grow flex flex-col items-center justify-center  w-min mx-auto mt-8'>
                        <div className='  text-center flex text-vstargray-800 dark:text-vstargray-100 py-2'>Download Template {isDownloadingTEmplateDBwise ? <IconLoader2 size={10} className=' inline animate-spin' /> : <span onClick={() => downloadTemplateDBwise({
                            date: latestCalendarDate?.date!,
                            LYM1: standardMonths[0].value,
                            LYM2: standardMonths[1].value,
                            LYM3: standardMonths[2].value,
                            'M3': standardMonths[3].value,
                            'M2': standardMonths[4].value,
                            'M1': standardMonths[5].value
                        })} className=' underline text-blue-500 cursor-pointer hover:text-blue-700'>here</span>}</div>
                        <FileInputDragDropStandard
                            disabled={!latestCalendarDate?.date ? true : false}
                            onDrop={async (file) => {
                                const response = await validateDBwiseExel({
                                    file: file!
                                })
                                if (response.success === false) {
                                    toast({
                                        description: response.message
                                    })
                                } else {
                                    setSelectedFile(file);
                                }
                            }}
                            file={seletedFile}
                            onClear={() => setSelectedFile(null)}
                            accept={{ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] }}
                        />
                    </div>
                    <ButtonIconSquare
                        onClick={async () => {
                            const response = await uploadDBwiseExel({
                                date: latestCalendarDate?.date!,
                                file: seletedFile!,
                            })
                            if (response.success === false) {
                                toast({
                                    title: 'Error',
                                    description: response.message
                                })
                            } else {
                                toast({
                                    title: 'Success',
                                    description: response.message
                                })
                                setSelectedFile(null)
                            }
                        }}
                        className=' mx-auto mb-5'
                        icon={<IconCloudUpload />}
                        variant='secondary' text='Submit' />
                </div>
            </div>

        </div>
    )
}

