
import React from "react"
import { Popover, PopoverContent, PopoverTrigger } from "src/shadcn/ui/popover"
import { StandardTableOptionButton } from "../../../../../components/standard.table"
import { IconCloudUp, IconDotsVertical, IconDownload, IconFilter, IconLetterASmall, IconPencil, IconRegistered, IconRoadSign, IconTag, IconTagsFilled, IconTools, IconTrash } from "@tabler/icons-react"

export interface DropdownMenuDemoProps {
    rowId: string | number,
    onDeleteAction: (rowId: string | number) => void;
    onEditAction: (rowId: string | number) => void;
}
export interface DropdownMenuMainProps {
    onImportExcel: () => void;
    onImortExcelASPUpdate: () => void;
    onImortExcelMRPUpdate: () => void;
    onImportExcelRefStyle: () => void;
    onImportActiveStyle: () => void;
    onFilterButtonPressed: () => void;
    onIconDownloadPressed: () => void,

}

function DropdownMenuItem({
    icon,
    text,
    onClick
}: {
    icon: React.ReactNode,  //recomended tabler icon size 20
    text: string,
    onClick?: () => any
}) {
    return (
        <button onClick={onClick} className=" text-vstargray-800 hover:text-vstargray-100 flex rounded-md bg-vstargray-100 hover:bg-vstarPrimary-500  items-center gap-2 px-3 py-1">
            {/* icon */}
            {icon}
            {/* text */}
            <p className=" text-xs">{text}</p>
        </button>
    )
}
function DropdownMenuMainItem({
    icon,
    text,
    onClick
}: {
    icon: React.ReactNode,  //recomended tabler icon size 20
    text: string,
    onClick?: () => any
}) {
    return (
        <button onClick={onClick} className=" text-vstargray-800 hover:text-vstargray-900 flex rounded-md bg-vstargray-100 hover:bg-vstargray-300  items-center gap-2 px-3 py-1">
            {/* icon */}
            {icon}
            {/* text */}
            <p className=" text-xs whitespace-nowrap">{text}</p>
        </button>
    )
}

export function DropdownMenuDemo({ rowId, onDeleteAction, onEditAction }: DropdownMenuDemoProps) {

    const [openDrowdownMenu, setOpenDropdownMenu] = React.useState(false)



    return (
        <Popover open={openDrowdownMenu} onOpenChange={setOpenDropdownMenu}  >
            <PopoverTrigger asChild>
                <div><StandardTableOptionButton onClick={() => setOpenDropdownMenu(!openDrowdownMenu)} /></div>
            </PopoverTrigger >
            <PopoverContent onOpenAutoFocus={(e) => e?.preventDefault()} className="w-[120px] bg-vstargray-100 p-1">
                <div className="w-full flex flex-col gap-1">
                    <DropdownMenuItem onClick={() => {
                        setOpenDropdownMenu(!openDrowdownMenu);
                        onEditAction(rowId);
                    }} icon={<IconTools size={20} />} text="Edit" />
                    {/* <DropdownMenuItem icon={<IconTrash size={20} />} text="Delete" onClick={() => onDeleteAction(rowId)} /> */}
                </div>
            </PopoverContent>
        </Popover>
    )
}


export function DropdownMenuMain({
    onImportExcel,
    onImortExcelASPUpdate,
    onImortExcelMRPUpdate,
    onImportExcelRefStyle,
    onImportActiveStyle,
    onFilterButtonPressed,
    onIconDownloadPressed
}: DropdownMenuMainProps) {

    const [openDrowdownMenu, setOpenDropdownMenu] = React.useState(false)



    return (
        <Popover open={openDrowdownMenu} onOpenChange={setOpenDropdownMenu}  >
            <PopoverTrigger asChild>
                <div onClick={() => setOpenDropdownMenu(!openDrowdownMenu)} className="flex dark:text-white items-center justify-center cursor-pointer hover:bg-vstargray-400/20 py-1 rounded-md">
                    <IconDotsVertical className="w-5 h-5" />
                </div>
            </PopoverTrigger >
            <PopoverContent onOpenAutoFocus={(e) => e?.preventDefault()} className="w-min bg-vstargray-100 p-1">
                <div className="w-full flex flex-col gap-1">
                    <DropdownMenuMainItem onClick={() => {
                        onFilterButtonPressed()
                        setOpenDropdownMenu(!openDrowdownMenu);
                    }} icon={<IconFilter size={20} />} text="filter " />
                    <DropdownMenuMainItem onClick={() => {
                        onIconDownloadPressed()
                        setOpenDropdownMenu(!openDrowdownMenu);
                    }} icon={<IconDownload size={20} />} text="download" />
                    <DropdownMenuMainItem onClick={() => {
                        onImportExcel()
                        setOpenDropdownMenu(!openDrowdownMenu);
                    }} icon={<IconCloudUp size={20} />} text="Import" />
                    <DropdownMenuMainItem onClick={() => {
                        onImportExcelRefStyle()
                        setOpenDropdownMenu(!openDrowdownMenu);
                    }} icon={<IconRoadSign size={20} />} text="Ref style upload" />
                    <DropdownMenuMainItem onClick={() => {
                        onImportActiveStyle()
                        setOpenDropdownMenu(!openDrowdownMenu);
                    }} icon={<IconRegistered size={20} />} text="Active style" />
                    {/* <DropdownMenuMainItem onClick={() => {
                        onassignStyle()
                        setOpenDropdownMenu(!openDrowdownMenu);
                    }} icon={<IconRoadSign size={20} />} text="Assign style" /> */}

                    <DropdownMenuMainItem onClick={() => {
                        onImortExcelMRPUpdate()
                        setOpenDropdownMenu(!openDrowdownMenu);
                    }} icon={<IconTag size={20} />} text="MRP Upload" />
                    <DropdownMenuMainItem onClick={() => {
                        onImortExcelASPUpdate()
                        setOpenDropdownMenu(!openDrowdownMenu);
                    }} icon={<IconTagsFilled size={20} />} text="ASP Upload" />
                </div>
            </PopoverContent>
        </Popover>
    )
}


